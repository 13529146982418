import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SxProps,
} from '@mui/material';
import React, { SyntheticEvent } from 'react';

import { FormStateRet } from '../BasicForm';
import { M3TextField } from '../M3/M3TextField';

import { MenuOption } from '../../types/app';

export type AutocompleteFormState = {
  [key: string]: any;
  checked: {
    [key: string]: boolean;
  };
  optionById: {
    [key: string]: MenuOption;
  };
};

export type AutocompleteSelectionPopoverProps = {
  title?: string;
  placeholder?: string;
  onEventClick?: () => void;
  options: MenuOption[];
  form: FormStateRet<AutocompleteFormState>;
  width?: number | string;
  sx?: SxProps;
};

const AutocompleteSelectionPopover = ({
  title,
  placeholder,
  onEventClick,
  options,
  form,
  width = 200,
  sx,
}: AutocompleteSelectionPopoverProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
    >
      <Autocomplete
        fullWidth
        options={options}
        disablePortal
        getOptionLabel={(option) => (option as unknown as MenuOption).value}
        renderInput={(params) => (
          <M3TextField
            {...params}
            label={title}
            placeholder={placeholder ?? 'Select...'}
          />
        )}
        renderOption={(props, option: MenuOption) => {
          return (
            <li
              {...props}
              key={props.key}
              style={{
                padding: 0,
              }}
              onClick={(evt) => {
                evt.stopPropagation();

                onEventClick?.();

                form.updateState((state: AutocompleteFormState) => {
                  const stateChecked = state.checked || {};
                  const stateOptionById = state.optionById || {};
                  const checked = !stateChecked[option.id];

                  return {
                    ...state,
                    checked: {
                      ...stateChecked,
                      [option.id]: checked,
                    },
                    optionById: {
                      ...stateOptionById,
                      [option.id]: option,
                    },
                  };
                });
              }}
            >
              <FormGroup
                sx={{
                  pl: 1.5,
                  '.MuiFormControlLabel-root': {
                    pointerEvents: 'none',
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={`checked`}
                      checked={form.formState.checked[option.id] ?? false}
                      onChange={(evt: SyntheticEvent) => null}
                    />
                  }
                  label={option.value}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      lineHeight: 1.1,
                    },
                  }}
                />
              </FormGroup>
            </li>
          );
        }}
        sx={{
          width: width,
        }}
      />
    </Box>
  );
};

export default AutocompleteSelectionPopover;
