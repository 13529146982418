import React, { useLayoutEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { removeFromSessionStorage } from '../utils/sessionstorage';
import { useAuthProvider } from '../providers/auth/auth';

type Props = {
  children?: any;
};

function Login({ children }: Props) {
  const { isAuthenticated } = useAuthProvider();

  useLayoutEffect(() => {
    removeFromSessionStorage('impersonation');
  }, []);

  if (isAuthenticated) {
    return <Navigate to='/' />;
  }

  return null;
}

export default Login;
