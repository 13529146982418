import { Box, Typography } from '@mui/material';
import { ReactRenderElement } from '../../types/types';

type MetaPanelProps = {
  icon?: ReactRenderElement;
  label: ReactRenderElement;
  value: ReactRenderElement;
};
const MetaPanel = ({ icon, label, value }: MetaPanelProps) => {
  return (
    <Typography
      component='div'
      display='flex'
      alignItems='stretch'
      minWidth={100}
    >
      <Box>
        <Box
          sx={{
            fontSize: 14,
            opacity: 0.5,
            fontWeight: 500,
          }}
        >
          {label}
        </Box>
        <Box
          display='flex'
          sx={{
            fontSize: 22,
            fontWeight: 500,
          }}
        >
          {icon && (
            <Box
              ml={-1}
              display='flex'
              alignItems='flex-end'
              justifyContent='center'
              sx={{
                paddingBottom: '4px',
              }}
            >
              {icon}
            </Box>
          )}
          {value}
        </Box>
      </Box>
    </Typography>
  );
};

export default MetaPanel;
