import { IterableObject } from '../types/types';

export function applyOpacityOnColor(color: string, opacity: number = 1) {
  if (typeof color !== 'string' || color.length !== 7) return color;

  if (opacity < 0) opacity = 0;
  else if (opacity > 1) opacity = 1;

  opacity = Math.round(opacity * 255);

  return color + opacity.toString(16).toUpperCase().padStart(2, '0');
}

/**
 * Extracted the m3 token to a palette
 */
type ExtractedPalette = IterableObject<string>;
export function extractTokenToPalette(token: string = ''): ExtractedPalette {
  /**
   * Matches:
   * --md-ref-palette-primary0: #000000;
   * --md-sys-typescale-display-medium-font-family-name: Roboto;
   * --md-sys-typescale-title-large-font-family-name: Sample Font;
   * --md-sys-typescale-title-large-font-family-name: 'Sample Font';
   */
  const singleWordRegex = /--\w*(-\w+)+:\s#*\w+;/gi;
  const compoundWordsRegex = /--\w*(-\w+)+:\s'*(\w+\s+\w*)+'*;/gi;
  const ep: ExtractedPalette = {};
  const mapper = (m: string) => {
    m = m.replace(';', '');
    const arr = m.split(' ');
    const id = arr.shift() as string;
    const value = arr.join(' ');
    /**
     * Matching the id / token for web
     * Converting --md-ref-palette-primary0 to md.ref.palette.primary0
     * https://m3.material.io/styles/color/the-color-system/tokens?preview=true#7fd4440e-986d-443f-8b3a-4933bff16646
     */
    ep[id.replace(/:|--/g, '').replace(/-+/g, '.')] = value;
  };
  (token.match(singleWordRegex) ?? []).forEach(mapper);
  (token.match(compoundWordsRegex) ?? []).forEach(mapper);
  return ep;
}

/**
 * Clean the token source since there are some users who saved their bad color scheme
 */
export function cleanPaletteSource(token: string = ''): string {
  /**
   * Split tokens with having multiple closing brackets
   */
  let tokens = token.split('}').filter((s) => !!s);
  token = tokens[0] || token;
  /**
   * Intended to remove closing brackets and append a last one
   */
  return `${token}`.replace(/\}+/gi, '') + '}';
}
