import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import moment from 'moment';
import {
  Box,
  Checkbox,
  CircularProgress,
  Slider,
  SliderProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import BasicForm, { useForm } from '../../BasicForm';
import { M3Button } from '../../M3/M3Button';
import M3SurfaceContainer from '../../M3/M3SurfaceContainer';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';

import { useSettingsProvider } from '../../../providers/settings/settings';
import { formatBitrate } from '../../../types/bitrate';
import { AgentMonitoringSettings } from '../../../types/settings';
import { IterableObject, ReactRenderElement } from '../../../types/types';
import { Go2AgentAppConfigItem } from '../../../hooks/cosmos/go2-agent/app-config.type';
import { useAuthProvider } from '../../../providers/auth/auth';
import { request as cosmosRequest } from '../../../services/cosmos';
import { getConfigWithAuthorization } from '../../../services/base';
import { useAppProvider } from '../../../providers/app/app';

type Go2AppSettingsProps = {
  isGlobal?: boolean;
  userId?: number | null;
  isFetchingAppConfigData?: boolean;
  globalAppConfigData?: Go2AgentAppConfigItem[];
  appConfigData?: Go2AgentAppConfigItem[];
  appConfigRefetch: () => void;
};

export type TabButtonParamItem = {
  name: string;
  icon?: ReactRenderElement;
  label: ReactRenderElement;
};

function cleanUpParamData(payload: Partial<Go2AgentAppConfigItem>) {
  const fields: (keyof Go2AgentAppConfigItem)[] = [
    'allow_activity',
    'allow_keystrokes',
    'allow_screen_capture',
    'screen_capture_quality',
    'allow_audio_capture',
    'allow_video_capture',
    'video_bitrate',
    'video_capture_interval',
  ];

  return fields.reduce((prev, key) => {
    if (key in payload) {
      prev[key] = payload[key];
    }
    return prev;
  }, {} as IterableObject);
}

function cleanUpPayloadData(payload: Partial<Go2AgentAppConfigItem>) {
  const fields: (keyof Go2AgentAppConfigItem)[] = ['owner', 'os'];

  return fields.reduce((prev, key) => {
    if (key in payload) {
      prev[key] = payload[key];
    }
    return prev;
  }, cleanUpParamData(payload));
}

const Go2AppSettings = ({
  isGlobal,
  userId,
  appConfigData = [],
  globalAppConfigData = [],
  appConfigRefetch,
}: Go2AppSettingsProps) => {
  const { isDarkMode } = useAppProvider();
  const { getTokenSilently } = useAuthProvider();

  const [isFormVisible, setIsFormVisible] = useState(isGlobal ? true : false);
  const [hasChanged, setHasChanged] = useState({
    windows: false,
    macOS: false,
  });
  const setOSHasChanged = useCallback(
    (platform: 'windows' | 'macOS', value: boolean) =>
      setHasChanged((state) => ({
        ...state,
        [platform]: value,
      })),
    [setHasChanged],
  );

  const [isProcessing, setIsProcessing] = useState({
    windows: false,
    macOS: false,
  });

  const [changedConfigFormData, setChangedConfigFormData] = useState<{
    windows: Partial<Go2AgentAppConfigItem> | null;
    macOS: Partial<Go2AgentAppConfigItem> | null;
  }>({
    windows: null,
    macOS: null,
  });
  const setOSChangedConfigFormData = useCallback(
    (platform: 'windows' | 'macOS', value: Partial<Go2AgentAppConfigItem>) =>
      setChangedConfigFormData((state) => ({
        ...state,
        [platform]: value,
      })),
    [setChangedConfigFormData],
  );

  const windowsAppConfig = appConfigData?.find((c) => c.os === 'windows');
  const macOSAppConfig = appConfigData?.find((c) => c.os === 'macOS');

  const globalWindowsAppConfig = globalAppConfigData?.find(
    (c) => c.os === 'windows',
  );
  const globalMacOSAppConfig = globalAppConfigData?.find(
    (c) => c.os === 'macOS',
  );

  const removeConfigConfirmationDialog = useConfirmationDialog();
  const lastModified = [windowsAppConfig, macOSAppConfig]
    .filter((v) => !!v)
    .map((v) => v!.modified)
    .sort()[0];

  /**
   * handle when submitting for changes on windows
   */
  const handleOnSubmitWindows = useCallback(async () => {
    try {
      setIsProcessing((state) => ({ ...state, windows: true }));

      const httpConfig = getConfigWithAuthorization(await getTokenSilently());
      let payload: Partial<Go2AgentAppConfigItem> = cleanUpPayloadData({
        ...changedConfigFormData.windows,
      });

      if (windowsAppConfig) {
        await cosmosRequest.patch(
          `/api/go2-agent/app-config/${windowsAppConfig.id}/`,
          payload,
          httpConfig,
        );
      } else {
        payload = cleanUpPayloadData({
          ...globalWindowsAppConfig,
          ...payload,
          owner: userId,
          os: 'windows',
        });
        await cosmosRequest.post(
          `/api/go2-agent/app-config/`,
          payload,
          httpConfig,
        );
      }

      setOSHasChanged('windows', false);
    } finally {
      setIsProcessing((state) => ({ ...state, windows: false }));
    }
  }, [
    userId,
    getTokenSilently,
    windowsAppConfig,
    changedConfigFormData.windows,
    setIsProcessing,
    setOSHasChanged,
    globalWindowsAppConfig,
  ]);

  /**
   * handle when submitting for changes on macOS
   */
  const handleOnSubmitMacOS = useCallback(async () => {
    try {
      setIsProcessing((state) => ({ ...state, macOS: true }));

      const httpConfig = getConfigWithAuthorization(await getTokenSilently());
      let payload: Partial<Go2AgentAppConfigItem> = cleanUpPayloadData({
        ...changedConfigFormData.macOS,
      });

      if (macOSAppConfig) {
        await cosmosRequest.patch(
          `/api/go2-agent/app-config/${macOSAppConfig.id}/`,
          payload,
          httpConfig,
        );
      } else {
        payload = cleanUpPayloadData({
          ...globalMacOSAppConfig,
          ...payload,
          owner: userId,
          os: 'macOS',
        });
        await cosmosRequest.post(
          `/api/go2-agent/app-config/`,
          payload,
          httpConfig,
        );
      }

      setOSHasChanged('macOS', false);
    } finally {
      setIsProcessing((state) => ({ ...state, macOS: false }));
    }
  }, [
    userId,
    getTokenSilently,
    macOSAppConfig,
    changedConfigFormData.macOS,
    setIsProcessing,
    setOSHasChanged,
    globalMacOSAppConfig,
  ]);

  const onSubmitConfig = useCallback(
    async (ignore?: any) => {
      try {
        let promise: any = [];

        if (hasChanged.windows || (typeof ignore === 'boolean' && ignore)) {
          promise.push(handleOnSubmitWindows());
        }
        if (hasChanged.macOS || (typeof ignore === 'boolean' && ignore)) {
          promise.push(handleOnSubmitMacOS());
        }

        if (promise.length) {
          await Promise.all(promise);
          appConfigRefetch();
        }
      } catch (e) {}
    },
    [hasChanged, handleOnSubmitWindows, handleOnSubmitMacOS, appConfigRefetch],
  );

  const handleOnDeleteUserConfig = useCallback(async () => {
    try {
      setIsProcessing((state) => ({ ...state, windows: true, macOS: true }));

      const httpConfig = getConfigWithAuthorization(await getTokenSilently());

      await Promise.all([
        cosmosRequest.delete(
          `/api/go2-agent/app-config/${windowsAppConfig!.id}/`,
          httpConfig,
        ),
        cosmosRequest.delete(
          `/api/go2-agent/app-config/${macOSAppConfig!.id}/`,
          httpConfig,
        ),
      ]);
    } finally {
      removeConfigConfirmationDialog.confirm.setIsOpen(false);
      setIsProcessing((state) => ({ ...state, windows: false, macOS: false }));
      appConfigRefetch();
    }
  }, [
    setIsProcessing,
    windowsAppConfig,
    macOSAppConfig,
    appConfigRefetch,
    getTokenSilently,
    removeConfigConfirmationDialog.confirm,
  ]);

  const renderDeleteForm = () => {
    return (
      !isGlobal &&
      !!(windowsAppConfig || macOSAppConfig) && (
        <>
          <Box
            display='flex'
            alignItems='center'
            style={{ position: 'absolute', right: -8, top: 0 }}
          >
            <Typography
              component='div'
              fontSize={12}
              style={{
                opacity: 0.5,
                paddingRight: 4,
              }}
            >
              Remove this user's Go2 app configuration?
            </Typography>
            <M3Button
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-error80)'
                  : 'var(--md-ref-palette-error40)',
              }}
              onClick={() =>
                removeConfigConfirmationDialog.confirm.setIsOpen(true)
              }
            >
              <DeleteOutlinedIcon
                style={{
                  color: 'inherit',
                  fontSize: 20,
                  marginRight: 4,
                }}
              />
              Remove
            </M3Button>
          </Box>
          <ConfirmationDialog
            {...removeConfigConfirmationDialog.confirm}
            title='Remove Go2 App Configuration'
            message={`Are you sure you want to remove this user's Go2 app configuration?`}
            isLoading={isProcessing.windows || isProcessing.macOS}
            onConfirm={handleOnDeleteUserConfig}
          />
        </>
      )
    );
  };

  let isSubmitDisabled =
    isProcessing.windows ||
    isProcessing.macOS ||
    !(hasChanged.windows || hasChanged.macOS);

  const renderForm = () => {
    return (
      <>
        <Stack gap={12} direction='row' mt={1}>
          <Box flex={1} width={0}>
            <SettingsForm
              key={`windows_${windowsAppConfig?.id}`}
              platform='windows'
              title='Windows'
              icon={
                <DesktopWindowsOutlinedIcon
                  style={{
                    fontSize: 20,
                  }}
                />
              }
              subtitle={`Configure global monitoring and recording settings for all Windows users. Adjust activity tracking, keystroke monitoring, screenshot capture, audio recording, and video capture parameters on Windows systems.`}
              appConfig={windowsAppConfig || globalWindowsAppConfig}
              setOSHasChanged={setOSHasChanged}
              setOSChangedConfigFormData={setOSChangedConfigFormData}
            />
          </Box>
          <Box flex={1} width={0}>
            <SettingsForm
              key={`macOS_${macOSAppConfig?.id}`}
              title='Mac OS'
              platform='macOS'
              icon={
                <LaptopMacOutlinedIcon
                  style={{
                    fontSize: 22,
                  }}
                />
              }
              subtitle={`Set up global monitoring and recording preferences for all macOS users. Customize activity observation, keystroke monitoring, screen capture, audio recording, and video recording settings on Mac devices.`}
              appConfig={macOSAppConfig || globalMacOSAppConfig}
              setOSHasChanged={setOSHasChanged}
              setOSChangedConfigFormData={setOSChangedConfigFormData}
            />
          </Box>
        </Stack>
        <Stack
          direction='row'
          justifyContent='center'
          mt={-1}
          mb={1}
          style={{ position: 'relative' }}
        >
          <M3Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={isSubmitDisabled}
            onClick={onSubmitConfig}
            style={{ width: 100 }}
          >
            Save
          </M3Button>
          {renderDeleteForm()}
        </Stack>
      </>
    );
  };

  const renderNoUserConfigForm = () => {
    return (
      <>
        <Stack direction='row' justifyContent='center'>
          <M3Button
            color='secondary'
            variant='outlined'
            onClick={
              isProcessing.windows || isProcessing.macOS
                ? undefined
                : () => onSubmitConfig(true)
            }
            disabled={isProcessing.windows || isProcessing.macOS}
            style={{ width: 206 }}
          >
            {isProcessing.windows || isProcessing.macOS ? (
              <CircularProgress size={18} />
            ) : (
              <>&nbsp;Customize Go2 App Settings&nbsp;</>
            )}
          </M3Button>
        </Stack>
      </>
    );
  };

  // Show the form immediately, if it has already a config, otherwise, hide it.
  useLayoutEffect(() => {
    if (!isGlobal && userId) {
      if (windowsAppConfig && macOSAppConfig) {
        setIsFormVisible(true);
      } else {
        setIsFormVisible(false);
      }
    }
  }, [isGlobal, userId, windowsAppConfig, macOSAppConfig, setIsFormVisible]);

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' position='relative'>
        <Box
          style={{
            marginLeft: -4,
          }}
        >
          <Typography
            gap={1}
            component='h5'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            Go2 App Settings
          </Typography>
          <Typography component='div' fontSize={14} style={{ opacity: 0.5 }}>
            Set the configurations of the Go2 app
          </Typography>
        </Box>
        {!!lastModified && (
          <Typography component='div' fontSize={12} mt={1} textAlign='right'>
            <div style={{ opacity: 0.5, paddingBottom: 2 }}>
              Last updated:
              <br />
            </div>
            {moment.utc(lastModified).format('MMMM D, YYYY')}
          </Typography>
        )}
      </Stack>
      <br />
      <M3SurfaceContainer elevation={1}>
        <Typography
          component='div'
          fontSize={14}
          textAlign='center'
          style={{ padding: '20px 20px', opacity: 0.8 }}
        >
          {isGlobal ? (
            <>
              These settings will apply to all users across the entire system,
              unless overridden by individual user settings.
            </>
          ) : isFormVisible ? (
            <>
              You are about to modify the Go2 app's settings of this user. These
              settings only affect this user.
            </>
          ) : (
            <>
              This user is currently using the global settings for the Go2 app.
              No personalized configuration has been set.
              <br />
              To customize monitoring and recording preferences for this user,
              click the button below:
            </>
          )}
        </Typography>
      </M3SurfaceContainer>
      <br />
      {isFormVisible ? renderForm() : renderNoUserConfigForm()}
    </Box>
  );
};

type SettingsFormProps = {
  title: ReactRenderElement;
  subtitle?: ReactRenderElement;
  icon: ReactRenderElement;
  platform: 'windows' | 'macOS';
  appConfig?: Go2AgentAppConfigItem;
  setOSHasChanged: (platform: 'windows' | 'macOS', value: boolean) => void;
  setOSChangedConfigFormData: (
    platform: 'windows' | 'macOS',
    value: Partial<Go2AgentAppConfigItem>,
  ) => void;
};
const SettingsForm = ({
  title,
  icon,
  subtitle,
  platform,
  appConfig,
  setOSHasChanged,
  setOSChangedConfigFormData,
}: SettingsFormProps) => {
  const { monitoring: defaultGlobalMonitoring } = useSettingsProvider();

  const { formState, handleChange, hasChanged, handleSubmit, resetState } =
    useForm<AgentMonitoringSettings>(
      () =>
        cleanUpParamData({
          ...defaultGlobalMonitoring,
          ...appConfig,
        }) as AgentMonitoringSettings,
      {
        onStateUpdated(state, changed) {
          setOSChangedConfigFormData(platform, changed);
        },
      },
    );
  const onSubmit = handleSubmit((data: AgentMonitoringSettings) => {});
  const setLocalMonitoring = useCallback(
    (key: string, value: boolean | number) => {
      handleChange({
        target: {
          name: key,
          value: value,
        },
      });
    },
    [handleChange],
  );

  const renderRow = (
    title: string,
    label: string,
    value: boolean,
    onChange: (_: any, checked: boolean) => void,
  ) => {
    return (
      <Stack
        direction='row'
        style={{
          marginLeft: -8,
          marginBottom: 24,
        }}
      >
        <Box
          style={{
            marginTop: -8,
          }}
        >
          <Checkbox size='small' checked={value} onChange={onChange} />
        </Box>
        <Box>
          <Typography component='div' fontSize={15} fontWeight={500}>
            {title}
          </Typography>
          <Typography
            component='div'
            fontSize={13}
            style={{
              opacity: 0.5,
            }}
          >
            {label}
          </Typography>
        </Box>
      </Stack>
    );
  };

  const renderRowWithSlider = ({
    enabled,
    label,
    value,
    formattedValue,
    prefix,
    onChange,
    sliderProps,
  }: {
    enabled: boolean;
    label: ReactRenderElement;
    value: number;
    prefix?: string;
    formattedValue?: any;
    sliderProps?: SliderProps;
    onChange: (evt: any, value: number | number[]) => void;
  }) => {
    return (
      <Box
        style={{
          marginTop: -14,
          marginBottom: 20,
          paddingLeft: 30,
          opacity: enabled ? 1 : 0.5,
        }}
      >
        <Typography
          component='div'
          fontSize={12}
          display='flex'
          justifyContent='space-between'
          style={{
            marginBottom: -4,
          }}
        >
          {label}
          <span>
            {formattedValue ?? value}
            {!!prefix && <span style={{ fontSize: 10 }}>{prefix}</span>}
          </span>
        </Typography>
        <Slider
          size='small'
          {...sliderProps}
          disabled={!enabled}
          value={value}
          style={{
            marginLeft: 2,
          }}
          onChange={onChange}
        />
      </Box>
    );
  };

  // Update the hasChanged status by OS
  useEffect(() => {
    setOSHasChanged(platform, hasChanged);
  }, [hasChanged, platform, setOSHasChanged]);

  // When the config in the server updates. Reset also our local.
  useEffect(() => {
    appConfig && resetState(appConfig);
    // eslint-disable-next-line
  }, [appConfig]);

  return (
    <Box>
      <Typography
        mb={1}
        gap={1}
        fontSize={16}
        display='flex'
        component='div'
        fontWeight={500}
        alignItems='center'
      >
        {icon}
        {title}
      </Typography>
      <Typography fontSize={13} component='div' style={{ opacity: 0.5 }}>
        {subtitle}
      </Typography>
      <br />
      <BasicForm onSubmit={onSubmit}>
        {renderRow(
          'Activity',
          'Allow the app to monitor the activities (Applications & Websites)',
          formState.allow_activity,
          (_, checked: boolean) =>
            setLocalMonitoring('allow_activity', checked),
        )}
        {renderRow(
          'Keystrokes',
          'Allow the app to monitor the keystrokes',
          formState.allow_keystrokes,
          (_, checked: boolean) =>
            setLocalMonitoring('allow_keystrokes', checked),
        )}
        {renderRow(
          'Screenshots',
          'Allow the app to capture the screens',
          formState.allow_screen_capture,
          (_, checked: boolean) =>
            setLocalMonitoring('allow_screen_capture', checked),
        )}
        {renderRowWithSlider({
          enabled: formState.allow_screen_capture,
          label: 'Quality',
          value: formState.screen_capture_quality,
          prefix: '%',
          onChange: (evt: any, value: number | number[]) =>
            setLocalMonitoring('screen_capture_quality', value as number),
          sliderProps: {
            step: 1,
            min: 1,
            max: 100,
          },
        })}
        {renderRow(
          'Audio',
          'Allow the app to record the microphone',
          formState.allow_audio_capture,
          (_, checked: boolean) =>
            setLocalMonitoring('allow_audio_capture', checked),
        )}
        {renderRow(
          'Video',
          'Allow the app to record the screens',
          formState.allow_video_capture,
          (_, checked: boolean) =>
            setLocalMonitoring('allow_video_capture', checked),
        )}
        {renderRowWithSlider({
          enabled: formState.allow_video_capture,
          label: (
            <Stack direction='row' alignItems='center'>
              Bitrate&nbsp;&nbsp;
              <Tooltip title='The amount of data processed per unit of time, typically measured in bits per second (bps). Higher bitrates generally result in better video quality but require more bandwidth and storage.'>
                <HelpOutlineOutlinedIcon
                  style={{ fontSize: 12, opacity: 0.8 }}
                />
              </Tooltip>
            </Stack>
          ),
          value: formState.video_bitrate,
          formattedValue: formatBitrate(formState.video_bitrate),
          onChange: (evt: any, value: number | number[]) =>
            setLocalMonitoring('video_bitrate', value as number),
          sliderProps: {
            step: 8,
            min: 8,
            max: 1200,
          },
        })}
        {renderRowWithSlider({
          enabled: formState.allow_video_capture,
          label: (
            <Stack direction='row' alignItems='center'>
              Frame Capture Interval&nbsp;&nbsp;
              <Tooltip title='The time gap between capturing individual frames from a video stream. A shorter interval results in more frequent frame captures, potentially providing more detailed information but requiring more processing power and storage.'>
                <HelpOutlineOutlinedIcon
                  style={{ fontSize: 12, opacity: 0.8 }}
                />
              </Tooltip>
            </Stack>
          ),
          value: formState.video_capture_interval / 1000,
          formattedValue: formState.video_capture_interval / 1000,
          prefix: 'S',
          onChange: (evt: any, value: number | number[]) =>
            setLocalMonitoring(
              'video_capture_interval',
              (value as number) * 1000,
            ),
          sliderProps: {
            step: 1,
            min: 1,
            max: 30,
          },
        })}
        {/* <Stack direction='row'>
          <M3Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={!hasChanged}
            style={{ width: 100 }}
          >
            Save
          </M3Button>
        </Stack> */}
        {/* <br /> */}
      </BasicForm>
    </Box>
  );
};

export default Go2AppSettings;
