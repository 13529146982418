import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { Box, Divider, Stack, Typography } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { M3IconButton } from '../../../components/M3/M3Button';
import ActivityStack from './ActivityStack';
import WorklogsStack from './WorklogsStack';
import IdleActivityStack from './IdleActivityStack';
import { IdleActivityApplicationItem } from '../../Activities/types';

import { useAppProvider } from '../../../providers/app/app';
import { useWindowDimension } from '../../../hooks/utils/window';
import { UserDetailFilterParams } from './_types';
import { TimeTrackerWorklogItem } from '../../../types/time-tracker-worklogs';
import { BiActivityIntervalItem } from '../../../types/activity';
import { ReactRenderElement } from '../../../types/types';
import { transformItemWithLocalDates } from '../../../utils/worklog';
import {
  getDurationSeconds,
  HHMMSSOptions,
  HHMMSSOptionsJSONRet,
  toHHMMSS,
} from '../../../utils/date';

type Props = {
  withNavigationButtons?: boolean;
  activityItems: any[];
  keystrokeItems: any[];
  worklogItems: TimeTrackerWorklogItem[];
  activityPercentageItems: BiActivityIntervalItem[];
  idleActivityItems: IdleActivityApplicationItem[];
  filterParams: UserDetailFilterParams;
  selectedFilteredDate: Moment;
  prevNextSelectedFilteredDate: (action: 'next' | 'previous') => void;
  isNearVisibleViewport?: boolean;
};

const TimelineBlock = ({
  activityItems,
  keystrokeItems,
  withNavigationButtons = true,
  worklogItems,
  activityPercentageItems,
  idleActivityItems,
  filterParams,
  selectedFilteredDate,
  prevNextSelectedFilteredDate,
  isNearVisibleViewport,
}: Props) => {
  const { isDarkMode } = useAppProvider();
  const [hours] = useState(() => new Array(25).fill(null).map((_, i) => i));
  let dividerStyle: CSSProperties = {
    width: 2,
    height: 10,
    background: isDarkMode
      ? 'var(--md-ref-palette-neutral20)'
      : 'var(--md-ref-palette-neutral90)',
  };
  const periodContainerRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useWindowDimension();

  const idleActivityItemsWithLocal = useMemo(() => {
    return transformItemWithLocalDates<IdleActivityApplicationItem>({
      items: idleActivityItems,
      date: selectedFilteredDate,
      itemKeys: { start: 'start', end: 'end' },
    });
  }, [idleActivityItems, selectedFilteredDate]);

  const activityPercentageItemsWithLocal = useMemo(() => {
    return transformItemWithLocalDates<BiActivityIntervalItem>({
      items: activityPercentageItems,
      date: selectedFilteredDate,
      itemKeys: { start: 'interval_start', end: 'interval_end' },
    });
  }, [activityPercentageItems, selectedFilteredDate]);

  const worklogItemsWithLocal = useMemo(() => {
    return transformItemWithLocalDates<TimeTrackerWorklogItem>({
      items: worklogItems,
      date: selectedFilteredDate,
      itemKeys: { start: 'start', end: 'end' },
    });
  }, [worklogItems, selectedFilteredDate]);

  const formattedMetadata = useMemo(() => {
    let calTotal = (a: number, b: number) => a + b;
    let hmsOpt: HHMMSSOptions = {
      minDigit: 1,
      hrDigit: 1,
      secDigit: 1,
      json: true,
    };

    let totalWorklogs = worklogItemsWithLocal
      .map((item) => getDurationSeconds(item.end_local, item.start_local))
      .reduce(calTotal, 0);

    let totalIdle = idleActivityItemsWithLocal
      .map((item) => getDurationSeconds(item.end_local, item.start_local))
      .reduce(calTotal, 0);

    let avg =
      activityPercentageItemsWithLocal
        .map((item) => item.activity_percentage)
        .reduce(calTotal, 0) / (activityPercentageItemsWithLocal.length || 1);

    const wlHms = toHHMMSS(totalWorklogs, hmsOpt) as HHMMSSOptionsJSONRet;
    const idleHms = toHHMMSS(totalIdle, hmsOpt) as HHMMSSOptionsJSONRet;

    return {
      totalWorklogs: `${wlHms.hours}h ${wlHms.minutes}m`.trim(),
      totalIdle: `${idleHms.hours}h ${idleHms.minutes}m`.trim(),
      avgPct: parseFloat((avg * 100).toFixed(2)) + '%',
    };
  }, [
    idleActivityItemsWithLocal,
    activityPercentageItemsWithLocal,
    worklogItemsWithLocal,
  ]);

  const renderDivider = () => {
    return (
      <Box style={{ position: 'relative' }}>
        <Divider style={{ opacity: 0.5 }} />
        <Divider
          style={{
            top: 0,
            left: -20,
            width: 20,
            opacity: 0.5,
            position: 'absolute',
          }}
        />
      </Box>
    );
  };

  const renderMetadata = () => {
    const renderColumn = (
      label: ReactRenderElement,
      value: ReactRenderElement,
    ) => {
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 200,
          }}
        >
          <span style={{ opacity: 0.5 }}>{label}</span>
          <span style={{ opacity: 0.8 }}>{value}</span>
        </Box>
      );
    };

    return (
      <Typography
        fontSize={12}
        component='div'
        display='flex'
        alignItems='center'
        style={{
          // marginTop: 16,
          marginBottom: -4,
        }}
      >
        {renderColumn('Total Worked', formattedMetadata.totalWorklogs)}
        {renderColumn('Activity Pct.', formattedMetadata.avgPct)}
        {renderColumn('Total Idle', formattedMetadata.totalIdle)}
      </Typography>
    );
  };

  return (
    <Box
      flex={1}
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 12,
        position: 'relative',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        style={{
          paddingBottom: 10,
          marginLeft: -10,
          marginRight: -10,
          userSelect: 'none',
        }}
      >
        <Typography
          gap={1}
          display='flex'
          alignItems='center'
          component='div'
          fontSize={withNavigationButtons ? 16 : 13}
          fontWeight={500}
          style={{
            paddingLeft: withNavigationButtons ? 0 : 50,
          }}
        >
          {withNavigationButtons && (
            <M3IconButton
              onClick={() => prevNextSelectedFilteredDate('previous')}
            >
              <KeyboardBackspaceOutlinedIcon />
            </M3IconButton>
          )}
          <span style={{ opacity: withNavigationButtons ? 1 : 0.5 }}>
            {selectedFilteredDate.clone().format('MMM D, YYYY')}
          </span>
          <span style={{ opacity: 0.5, fontSize: 12 }}>
            ({selectedFilteredDate.clone().format('ddd')})
          </span>
        </Typography>
        <Typography
          gap={1}
          display='flex'
          alignItems='center'
          component='div'
          fontSize={withNavigationButtons ? 16 : 13}
          fontWeight={500}
          style={{
            paddingRight: withNavigationButtons ? 0 : 32,
          }}
        >
          <span style={{ opacity: withNavigationButtons ? 1 : 0.5 }}>
            {selectedFilteredDate.clone().add(1, 'day').format('MMM D, YYYY')}
          </span>
          <span style={{ opacity: 0.5, fontSize: 12 }}>
            ({selectedFilteredDate.clone().add(1, 'day').format('ddd')})
          </span>
          {withNavigationButtons && (
            <M3IconButton
              disabled={selectedFilteredDate
                .clone()
                .startOf('day')
                .isSameOrAfter(moment().startOf('day'))}
              onClick={() => prevNextSelectedFilteredDate('next')}
            >
              <KeyboardBackspaceOutlinedIcon
                style={{
                  transform: 'rotateZ(180deg)',
                }}
              />
            </M3IconButton>
          )}
        </Typography>
      </Stack>
      <Box style={{ marginLeft: 40, marginRight: 24 }}>
        <Box style={{ position: 'relative' }}>
          <Box
            gap={1}
            display='flex'
            flexDirection='column'
            style={{
              left: 0,
              right: 0,
              bottom: 0,
              height: 120,
            }}
          >
            <>
              {renderMetadata()}
              {renderDivider()}
              {isNearVisibleViewport ? (
                <IdleActivityStack
                  dimensions={dimensions}
                  items={idleActivityItemsWithLocal}
                  selectedFilteredDate={selectedFilteredDate}
                />
              ) : (
                <Box
                  flex={1}
                  style={{
                    height: 5,
                    minHeight: 5,
                  }}
                />
              )}
              {isNearVisibleViewport ? (
                <ActivityStack
                  dimensions={dimensions}
                  items={activityPercentageItemsWithLocal}
                  selectedFilteredDate={selectedFilteredDate}
                />
              ) : (
                <Box flex={1} />
              )}
              {isNearVisibleViewport ? (
                <WorklogsStack
                  dimensions={dimensions}
                  items={worklogItemsWithLocal}
                  selectedFilteredDate={selectedFilteredDate}
                />
              ) : (
                <Box flex={1} />
              )}
            </>
          </Box>
          <Divider style={{ borderWidth: 1 }} />
          <Stack
            ref={periodContainerRef}
            direction='row'
            style={{
              height: 20,
              width: '100%',
              position: 'relative',
            }}
          >
            {hours.map((h, i, arr) => {
              let width = periodContainerRef.current?.clientWidth ?? 0;
              let per = width / (arr.length - 1);
              let posX = per * i;

              return (
                <Typography
                  key={h}
                  fontSize={11}
                  component='div'
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  style={{
                    top: 0,
                    left: posX,
                    position: 'absolute',
                  }}
                >
                  <Box style={dividerStyle}></Box>
                  <span
                    style={{
                      left: -19,
                      width: 40,
                      position: 'relative',
                      textAlign: 'center',
                    }}
                  >
                    {i % 2 !== 0
                      ? null
                      : moment().startOf('day').hour(h).format('h a')}
                  </span>
                </Typography>
              );
            })}
          </Stack>
        </Box>
        {/* <Typography
          fontSize={14}
          component='div'
          display='flex'
          alignItems='center'
          style={{
            marginTop: 16,
            marginBottom: -8,
          }}
        >
          <span style={{ display: 'flex', alignItems: 'center', width: 100 }}>
            <AccessTimeOutlinedIcon style={{ opacity: 0.3, fontSize: 14 }} />
            &nbsp;8h 0m
          </span>
          <span style={{ display: 'flex', alignItems: 'center', width: 100 }}>
            <MonitorOutlinedIcon style={{ opacity: 0.3, fontSize: 14 }} />
            &nbsp;
          </span>
          <span style={{ display: 'flex', alignItems: 'center', width: 100 }}>
            <HourglassEmptyOutlinedIcon
              style={{ opacity: 0.3, fontSize: 14 }}
            />
            &nbsp;0
          </span>
        </Typography> */}
      </Box>
    </Box>
  );
};

export default TimelineBlock;
