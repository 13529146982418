import React, { PropsWithChildren, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { M3Button } from '../../../components/M3/M3Button';

import { IterableObject, ReactRenderElement } from '../../../types/types';

type TimelineFiltersProps = PropsWithChildren & {
  dataTypeFilters: IterableObject<boolean>;
  onDataTypeFilterChange: (name: string, value: boolean) => void;
};

export type TabButtonParamItem = {
  name: string;
  icon?: ReactRenderElement;
  label?: ReactRenderElement;
  active?: boolean;
};

const TimelineFilters = ({
  children,
  dataTypeFilters,
  onDataTypeFilterChange,
}: TimelineFiltersProps) => {
  const tabButtonElements: TabButtonParamItem[] = useMemo(() => {
    return [
      {
        name: 'activity',
        icon: <MonitorOutlinedIcon />,
        label: 'Activity',
        active: dataTypeFilters['activity'],
      },
      /*
      {
        name: 'session-log',
        icon: <ComputerOutlinedIcon />,
        label: 'Session Log',
      },
      */
      /*
      {
        name: 'time-worked',
        icon: <WorkHistoryOutlinedIcon />,
        label: 'Time Worked',
      },
      */
      /*
      {
        name: 'alerts',
        icon: <WarningAmberOutlinedIcon />,
        label: 'Alerts',
      },
      */
      /*
      {
        name: 'snapshots',
        icon: <ScreenshotMonitorOutlinedIcon />,
        // label: 'Snapshots',
      },
      */
      {
        name: 'keystrokes',
        icon: <KeyboardAltOutlinedIcon />,
        label: 'Keystrokes',
        active: dataTypeFilters['keystrokes'],
      },
      /*
      {
        name: 'audio',
        icon: <RecordVoiceOverOutlinedIcon />,
        label: 'Audio',
        active: tabChecked['audio'],
      },
      */
      /*
      {
        name: 'emails',
        icon: <MailOutlineOutlinedIcon />,
        label: 'Emails',
      },
      */
      /*
      {
        name: 'file-transfers',
        icon: <DriveFileMoveOutlinedIcon />,
        label: 'File Transfers',
      },
      */
      /*
      {
        name: 'online-meetings',
        icon: <VideocamOutlinedIcon />,
        label: 'Online Meetings',
      },
      */
      /*
      {
        name: 'printing',
        icon: <PrintOutlinedIcon />,
        label: 'Printing',
      },
      */
      /*
      {
        name: 'network-monitoring',
        icon: <WifiOutlinedIcon />,
        label: 'Network Monitoring',
      },
      */
    ];
  }, [dataTypeFilters]);

  const renderCheckButton = ({
    name,
    icon,
    label,
    active,
  }: TabButtonParamItem) => {
    return (
      <Stack key={name} direction='row' alignItems='center'>
        {/* {active && (
          <CheckOutlinedIcon
            style={{
              fontSize: 18,
              opacity: 0.5,
            }}
          />
        )} */}
        <M3Button
          key={name}
          disableRipple
          onClick={() => onDataTypeFilterChange(name, !active)}
        >
          <Box
            display='flex'
            alignItems='center'
            style={{
              opacity: active ? 1 : 0.5,
            }}
          >
            {active ? (
              <CheckOutlinedIcon />
            ) : (
              <CheckBoxOutlineBlankIcon
                style={{
                  opacity: 0.5,
                }}
              />
            )}
            {label}
          </Box>
        </M3Button>
      </Stack>
    );
  };

  const renderCheckFilters = () => {
    return (
      <Stack direction='row' gap={2}>
        {tabButtonElements.map(renderCheckButton)}
      </Stack>
    );
  };

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' pl={0.3} pr={1}>
        {renderCheckButton({
          name: 'activity-tracker',
          label: 'Show only entries for the current video timestamp',
          active: dataTypeFilters['activity-tracker'],
        })}
        <Box />
        {/* {renderTabs()} */}
        {renderCheckFilters()}
      </Stack>
    </Box>
  );
};

export default TimelineFilters;
