import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Divider,
  PaperProps,
  Popover,
  PopoverProps,
  SxProps,
  Typography,
} from '@mui/material';

import { M3MenuItem } from '../M3/M3MenuItem';

import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';
import PerfectScrollbar, { PerfectScrollbarRef } from '../PerfectScrollbar';

type BasicPopoverWrapperProps = {
  width?: number;
  open: boolean;
  anchorEl: HTMLElement | null;
  items: PopoverItem[];
  selected?: PopoverItem | null;
  onClose?: () => void;
  onSelect?: (item: PopoverItem) => void;
  renderOption?: (item: PopoverItem, index: number) => ReactRenderElement;
  popoverProps?: {
    anchorOrigin?: PopoverProps['anchorOrigin'];
    transformOrigin?: PopoverProps['transformOrigin'];
  };
  paperProps?: PaperProps;
  maxRowVisible?: number;
  footer?: ReactRenderElement;
  itemHeight?: number;
  isNotFixed?: boolean;
  usePerfectScrollbar?: boolean;
  itemStyle?: CSSProperties;
  itemSx?: SxProps;
};
export type PopoverItem<T = any> = {
  id: number | string;
  label: ReactRenderElement;
  props?: T;
  active?: boolean;
  interactive?: boolean;
  divider?: boolean;
};

type BasicPopoverProps = {
  isOpen: boolean;
};
export function useBasicPopover<T = HTMLElement>() {
  const ref = useRef<T | null>(null);
  const [state, setState] = useState<BasicPopoverProps>({
    isOpen: false,
  });

  const openPopover = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: true,
    }));
  }, []);

  const closePopover = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: false,
    }));
  }, []);

  const ret = useMemo(() => {
    return {
      ...state,
      ref,
      open: openPopover,
      close: closePopover,
    };
  }, [state, openPopover, closePopover, ref]);

  return ret;
}

const BasicPopoverWrapper = ({
  open,
  width = 200,
  anchorEl,
  onClose,
  items,
  selected,
  renderOption,
  popoverProps,
  onSelect,
  paperProps,
  maxRowVisible = 5,
  itemHeight = 48,
  footer,
  isNotFixed,
  usePerfectScrollbar = true,
  itemStyle,
  itemSx,
}: BasicPopoverWrapperProps) => {
  const { isDarkMode } = useAppProvider();
  const [mounted, setMounted] = useState(false);

  const psRef = useRef<null | PerfectScrollbarRef>(null);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const innerRef = useRef<null | HTMLDivElement>(null);

  const maxListHeight = itemHeight * maxRowVisible;

  const totalHeight = items.reduce((accu, curr) => {
    return accu + (curr.divider ? 1 : itemHeight);
  }, 0);

  /**
   * NOTE: This will update always the height of inner according to its mounted
   */
  const wrapper = wrapperRef.current;
  const inner = innerRef.current;
  if (wrapper && inner) {
    inner.style.height = `${wrapper.clientHeight}px`;
  }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);

      if (open) {
        psRef.current?._ps.update?.();
      }
    }, 16);
  }, [setMounted, open]);

  const renderItemsElement = items.map((item, index) => {
    const renderDefaultOption = () => (
      <BasicItemOption
        item={item}
        height={isNotFixed ? 'initial' : itemHeight}
        active={item.active || item.id === selected?.id}
        onSelect={onSelect}
        style={itemStyle}
        sx={itemSx}
      />
    );

    if (item.divider) {
      return (
        <li key={item.id}>
          <Divider />
        </li>
      );
    }

    return (
      <Fragment key={item.id}>
        {renderOption
          ? renderOption(item, index) || renderDefaultOption()
          : renderDefaultOption()}
      </Fragment>
    );
  });

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      transitionDuration={1}
      onClose={onClose}
      anchorOrigin={{
        vertical: 0,
        horizontal: -8,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        ...paperProps,
        style: {
          width: width,
          overflow: 'hidden',
          border: `1px solid ${
            isDarkMode
              ? 'var(--md-ref-palette-neutral-variant20)'
              : 'var(--md-ref-palette-neutral-variant90)'
          }`,
          ...paperProps?.style,
        },
      }}
      {...popoverProps}
      style={{
        userSelect: 'none',
      }}
    >
      <Box
        ref={wrapperRef}
        sx={{
          margin: 0,
          padding: 0,
          listStyle: 'none',
          maxHeight: maxListHeight,
          ...(!isNotFixed
            ? {
                height: totalHeight,
              }
            : null),
        }}
      >
        <Box ref={innerRef}>
          {usePerfectScrollbar ? (
            <PerfectScrollbar onSetRef={psRef} key={`${mounted}`}>
              {renderItemsElement}
            </PerfectScrollbar>
          ) : (
            renderItemsElement
          )}
        </Box>
      </Box>
      {footer}
    </Popover>
  );
};

export default BasicPopoverWrapper;

type BasicItemOptionProps = {
  item: PopoverItem;
  active?: boolean;
  disabled?: boolean;
  onSelect?: BasicPopoverWrapperProps['onSelect'];
  sx?: SxProps;
  style?: CSSProperties;
  height?: number | string;
};
export function BasicItemOption({
  item,
  active,
  disabled,
  onSelect,
  sx,
  style,
  height,
}: BasicItemOptionProps) {
  const interactive = item.interactive ?? true;

  const content = (
    <Typography
      flex={1}
      width={0}
      fontSize={14}
      component='span'
      lineHeight={1.3}
      title={
        typeof item.label === 'string' ? (item.label as string) : undefined
      }
      whiteSpace='nowrap'
      className='text-truncate'
      style={{
        color: 'inherit',
      }}
    >
      {item.label}
    </Typography>
  );

  if (!interactive) {
    return (
      <Box
        data-id={item.id}
        display='flex'
        alignItems='center'
        sx={{
          height,
          minHeight: 'initial',
          ...sx,
        }}
        style={style}
      >
        {content}
      </Box>
    );
  }

  return (
    <M3MenuItem
      data-id={item.id}
      active={active}
      disabled={disabled}
      sx={{
        height,
        minHeight: 'initial',
        ...sx,
      }}
      style={style}
      onClick={() => onSelect?.(item)}
    >
      {content}
    </M3MenuItem>
  );
}

type BasicItemNotFoundProps = {
  label?: string;
};
export function BasicItemNotFound({ label }: BasicItemNotFoundProps) {
  return (
    <Typography
      top={-6}
      fontSize={14}
      component='span'
      lineHeight={1.3}
      position='relative'
    >
      {label ?? 'No Matches Found'}
    </Typography>
  );
}
