import Error from './Error';

const ErrorUnauthorizedAccess = () => {
  return (
    <Error
      title='Unauthorized Access'
      message="We're sorry, but you do not have the necessary permissions to access this page. Please log in with the appropriate credentials or contact the system administrator if you believe this to be an error. Thank you for your understanding."
    />
  );
};

export default ErrorUnauthorizedAccess;
