import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

import Metadata from './Metadata';
import FiltersBoard, { AgentUserFilterParams } from './FiltersBoard';
import DataTable from '../../components/DataTable/DataTable';
import MainContainer from '../../components/MainContainer';
import PageTitle from '../../components/Title/PageTitle';
import { M3Button } from '../../components/M3/M3Button';
import DocumentTitle from '../../components/DocumentTitle';

import { AgentUser } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import OnlineOffline from '../../components/OnlineOffline/OnlineOffline';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  useUserAgentStatus,
  UseUserAgentStatusProps,
} from '../../hooks/go2-agent/user-status';
import { useUserProvider } from '../../providers/user/user';
import { UserActivitySkeleton } from '../../components/User/UserSkeleton';
import InfiniteScrollListener from '../../components/InfiniteScrollListener';
import WindowScrollTop from '../../components/WindowScrollTop';
import { toURL } from '../../utils/url';
import { fetchStartDownloadToCSV } from '../../utils/download';
import { getConfigWithAuthorization } from '../../services/base';
import { guessTimezone } from '../../utils/timezone';
import { useAuthProvider } from '../../providers/auth/auth';

type Props = {};

const agentFieldLabelMap: { [K in keyof Partial<AgentUser>]: string } = {
  id: 'ID',
  user_id: 'Name',
  division_id: 'Division',
  os: 'OS',
  os_version: 'OS Version',
  task_time: 'Last Login',
  task_name: 'Focus',
};

const agentFieldStyleMap: { [K in keyof Partial<AgentUser>]: CSSProperties } = {
  id: {
    width: 100,
    minWidth: 100,
  },
  user_id: {
    width: 300,
    minWidth: 300,
  },
  division_id: {
    width: 300,
    minWidth: 300,
  },
  first_login: {
    width: 300,
    minWidth: 300,
  },
  task_time: {
    width: 300,
    minWidth: 300,
  },
  task_name: {
    width: 300,
    minWidth: 300,
  },
  os: {
    width: 100,
    minWidth: 100,
  },
  os_version: {
    width: 160,
    minWidth: 160,
  },
  go2_version: {
    width: 160,
    minWidth: 160,
  },
  status: {
    width: 160,
    minWidth: 160,
  },
};

const defaultAgentFields: (keyof AgentUser)[] = [
  'user_id',
  'division_id',
  'task_name',
  'task_time',
  'os',
  'os_version',
  'go2_version',
  // 'status',
  'first_login',
];
const defaultActiveField: keyof AgentUser = 'id';

const Users = (props: Props) => {
  const { getUser, setUniqueIdsToFetch } = useUserProvider();
  const { getTokenSilently } = useAuthProvider();
  const [filters, setFilters] = useState<{
    active: keyof AgentUser;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const [dataTableKey, setDataTableKey] = useState(0);

  const [selectedFields, setSelectedFields] = useState<(keyof AgentUser)[]>([]);

  const handleColumnItem = useCallback(
    (field: keyof AgentUser, item: AgentUser) => {
      if (field === 'first_login' || field === 'task_time') {
        let d = moment.utc(item[field]).local();
        return item[field] ? (
          d.format('MMM D, YYYY hh:mma')
        ) : (
          <span style={{ opacity: 0.2 }}>–</span>
        );
      }

      if (field === 'os') {
        return item[field] ? (
          item[field]?.toLowerCase() === 'windows' ? (
            'windows'
          ) : (
            'macOS'
          )
        ) : (
          <span style={{ opacity: 0.2 }}>–</span>
        );
      }

      if (field === 'user_id') {
        let user = getUser('user_id', item.user_id);

        return (
          <UserActivitySkeleton
            user={user}
            linkProps={{
              to: `/users/${user?.id}/activity`,
              state: {
                item,
                user,
              },
            }}
          />
        );
      }

      if (field === 'division_id') {
        let user = getUser('user_id', item.user_id);
        return user ? (
          user.division?.name || <span style={{ opacity: 0.2 }}>–</span>
        ) : (
          <Skeleton variant='text' />
        );
      }

      if (field === 'status') {
        return <OnlineOffline online={item[field] === 'online'} />;
      }

      return item[field] ?? <span style={{ opacity: 0.2 }}>–</span>;
    },
    [getUser],
  );

  const handleHeaderColumnItem = useCallback((field: keyof AgentUser) => {
    let value =
      agentFieldLabelMap[field] ?? toTitleCase(stripToFormatterWord(field));
    return value;
  }, []);

  const handleOnHeaderColumnClick = useCallback(
    (field: keyof AgentUser, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );

  const handleOnFieldsChange = useCallback(
    (newFields: (keyof AgentUser)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  /*
  const handleRowClick = useCallback(
    (item: AgentUser) => {
      navigate(`/users/${item.id}/activity`, {
        state: {
          item,
        },
      });
    },
    [navigate],
  );
  */

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<AgentUserFilterParams>(
    () => ({
      users: [],
      divisions: [],
      os: undefined,
      osVersions: [],
      go2Versions: [],
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<AgentUserFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // User Agent Status ----------------------------------------------------------------
  const limit = 100;
  const infiniteUserAgentStatus = useInfinite<
    AgentUser,
    UseUserAgentStatusProps
  >({
    keyFieldName: 'user_id',
    skipFetchOnInit: true,
    useQuery: useUserAgentStatus,
    queryParams: {
      user_ids: filterParams.users.map((u) => u.id).join(),
      division_ids: filterParams.divisions.map((u) => u.id).join(),
      os: filterParams.os,
      os_versions: filterParams.osVersions.map((u) => u.id).join(),
      go2_versions: filterParams.go2Versions.map((u) => u.id).join(),
      limit: limit,
    },
    transformData: (item: AgentUser) => {
      let taskTimeLocal = item.task_time && moment.utc(item.task_time).local();
      if (taskTimeLocal) {
        item.status =
          moment().diff(taskTimeLocal, 'minute') >= 2 ? 'online' : 'offline';
      }
      return item;
    },
  });
  const totalOnline = useMemo(
    () =>
      infiniteUserAgentStatus.data.filter((u) => u.status === 'online').length,
    [infiniteUserAgentStatus.data],
  );

  const [isDownloading, setIsDownloading] = useState(false);
  const handleOnDownloadUsers = useCallback(async () => {
    try {
      setIsDownloading(true);
      let url = toURL(
        `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/user-status/download`,
        {
          user_ids: filterParams.users.map((u) => u.id).join(),
          division_ids: filterParams.divisions.map((u) => u.id).join(),
          os: filterParams.os,
          os_versions: filterParams.osVersions.map((u) => u.id).join(),
          go2_versions: filterParams.go2Versions.map((u) => u.id).join(),
        },
      );
      await fetchStartDownloadToCSV(
        url,
        {
          headers: {
            ...getConfigWithAuthorization(await getTokenSilently()).headers,
            ['x-timezone']: guessTimezone(), // eslint-disable-line
          },
        },
        {
          filename: `users.csv`,
        },
      );
    } finally {
      setIsDownloading(false);
    }
  }, [filterParams, getTokenSilently, setIsDownloading]);

  const handleOnRefreshClick = useCallback(() => {
    !infiniteUserAgentStatus.isLoading &&
      infiniteUserAgentStatus.reset({
        emptyList: true,
      });
    // eslint-disable-next-line
  }, [infiniteUserAgentStatus.isLoading, infiniteUserAgentStatus.reset]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: infiniteUserAgentStatus.data.map((d) => d.user_id),
    });
  }, [infiniteUserAgentStatus.data, setUniqueIdsToFetch]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: filterParams.users.map((d) => d.id),
    });
  }, [filterParams.users, setUniqueIdsToFetch]);

  useEffect(() => {
    infiniteUserAgentStatus.reset({
      emptyList: true,
    });
    // eslint-disable-next-line
  }, [filterParams]);

  return (
    <>
      <DocumentTitle title='Users' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <PageTitle
            title='Users'
            icon={<PeopleOutlinedIcon style={{ fontSize: 34 }} />}
          />
          <Stack direction='row' gap={2}>
            {/* <M3Button color='secondary'>Use Saved Filters</M3Button> */}
            <M3Button
              active={isFiltersVisible}
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            >
              <FilterAltOutlinedIcon />
              {isFiltersVisible ? 'Hide' : 'Show'} Filters
            </M3Button>
          </Stack>
        </Box>
        <br />
        <Box display={isFiltersVisible ? undefined : 'none'}>
          <FiltersBoard
            filterParams={filterParams}
            selectedFields={selectedFields}
            allFields={defaultAgentFields}
            fieldLabelMap={agentFieldLabelMap}
            onFieldsChange={handleOnFieldsChange}
            updateFilterParams={updateFilterParams}
          />
        </Box>
        <Metadata
          isDownloading={isDownloading}
          isRefreshing={infiniteUserAgentStatus.isLoading}
          totalOnline={totalOnline}
          totalUsers={infiniteUserAgentStatus.count}
          handleOnDownloadClick={handleOnDownloadUsers}
          handleOnRefreshClick={handleOnRefreshClick}
        />
        <Typography component='div'>
          <DataTable
            key={dataTableKey}
            orderBy={filters.order}
            // activeField={filters.active}
            fields={selectedFields.length ? selectedFields : defaultAgentFields}
            data={infiniteUserAgentStatus.data}
            fieldColumnStyleMap={agentFieldStyleMap}
            handleColumnItem={handleColumnItem}
            handleHeaderColumnItem={handleHeaderColumnItem}
            // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
            // handleRowClick={handleRowClick}
          />
        </Typography>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => infiniteUserAgentStatus.loadNext()}
      />
      <WindowScrollTop />
    </>
  );
};

export default Users;
