import React from 'react';
import { Drawer, Box, DrawerProps } from '@mui/material';

import EdgeContainerCover from './EdgeContainerCover';

import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';
import { navRailWidth } from './NavRailMenuList';

type DrawerPermanentProps = {
  withNavRail?: boolean;
  children?: ReactRenderElement;
  withEdgeCover?: boolean;
  withDrawerBackground?: boolean;
  variant?: DrawerProps['variant'];
  open?: DrawerProps['open'];
  onClose?: DrawerProps['onClose'];
};

const DrawerPermanent = ({
  children,
  withNavRail = true,
  open,
  withEdgeCover = true,
  variant = 'permanent',
  onClose,
  withDrawerBackground = true,
}: DrawerPermanentProps) => {
  const { drawerWidth, isDarkMode } = useAppProvider();

  return (
    <Drawer
      open={open}
      variant={variant}
      sx={{
        zIndex: 20000,
        width: drawerWidth - (withNavRail ? 0 : navRailWidth),
        background: withDrawerBackground
          ? isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)'
          : 'transparent',
      }}
      PaperProps={{
        sx: {
          display: 'flex',
          overflow: 'visible',
          flexDirection: 'column',
          borderRight: 0,
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
        },
      }}
      onClose={onClose}
    >
      {withEdgeCover && <EdgeContainerCover />}
      <Box display='flex' sx={{ flex: 1, height: 0 }}>
        {children}
      </Box>
    </Drawer>
  );
};

export default DrawerPermanent;
