import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { useCurrentProfile } from '../../hooks/profile';
import {
  getUserProfileDisplayName,
  getUserInitials,
  getUserPhotoUrl,
} from '../../utils/user';

type Props = {};

const ProfileControl = (props: Props) => {
  const { data: currentProfile } = useCurrentProfile();

  return (
    <Box gap={1} display='flex' alignItems='center'>
      <Avatar
        src={getUserPhotoUrl(currentProfile?.photo_url)}
        sx={{
          width: 34,
          height: 34,
        }}
      >
        {getUserInitials(currentProfile?.first_name).initial}
      </Avatar>
      <Box display='flex' flexDirection='column'>
        <Typography component='div' fontSize={13} fontWeight={500}>
          {getUserProfileDisplayName(currentProfile ?? ({} as any)).fullName ||
            'User'}
        </Typography>
        <Typography
          component='span'
          fontSize={12}
          fontWeight={500}
          className='m3-button-link'
        >
          Log out
        </Typography>
        {/* <Typography component='div' fontSize={12}>
          {currentProfile?.email}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default ProfileControl;
