import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

import MainContainer from '../../components/MainContainer';
import PageTitle from '../../components/Title/PageTitle';
import DataTable from '../../components/DataTable/DataTable';
import FiltersBoard, { ActivityFilterParams } from './FiltersBoard';
import { M3Button, M3IconButton } from '../../components/M3/M3Button';
import { UserActivitySkeleton } from '../../components/User/UserSkeleton';
import DocumentTitle from '../../components/DocumentTitle';
import InfiniteScrollListener from '../../components/InfiniteScrollListener';
import WindowScrollTop from '../../components/WindowScrollTop';
import ActivityChart from './ActivityChart';

import { ActivityApplicationItem } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import {
  useActivities,
  UseActivitiesProps,
} from '../../hooks/go2-agent/activities';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  getDurationSeconds,
  getWeekRangeStartsInMonday,
  HHMMSSOptionsJSONRet,
  toHHMMSS,
} from '../../utils/date';
import { useUserProvider } from '../../providers/user/user';
import { toURL } from '../../utils/url';
import { useAuthProvider } from '../../providers/auth/auth';
import { getConfigWithAuthorization } from '../../services/base';
import { guessTimezone } from '../../utils/timezone';
import { fetchStartDownloadToCSV } from '../../utils/download';
import { useTimezone } from '../../components/Util/Timezone';
import { useMetadataProvider } from '../../providers/metadata/metadata';

type ActivitiesProps = {};

const activityAppFieldLabelMap: {
  [K in keyof Partial<ActivityApplicationItem>]: string;
} = {
  id: 'ID',
  division: 'Division',
  url: 'URL',
  duration: 'Duration',
  user_id: 'Name',
  exec: 'Application',
  os: 'OS',
  task_name: 'Focus',
};

const agentFieldStyleMap: {
  [K in keyof Partial<ActivityApplicationItem>]: CSSProperties;
} = {
  user_id: {
    width: 260,
    minWidth: 260,
  },
  division: {
    width: 300,
    minWidth: 300,
  },
  start: {
    width: 240,
    minWidth: 240,
  },
  end: {
    width: 240,
    minWidth: 240,
  },
  exec: {
    width: 300,
    minWidth: 300,
  },
  duration: {
    width: 200,
    minWidth: 200,
  },
  title: {
    width: 400,
    minWidth: 400,
  },
  url: {
    width: 600,
    minWidth: 600,
  },
  go2_version: {
    width: 180,
    minWidth: 180,
  },
  task_name: {
    width: 300,
    minWidth: 300,
  },
};

const defaultAgentFields: (keyof ActivityApplicationItem)[] = [
  'user_id',
  'division',
  'task_name',
  'go2_version',
  'start',
  'end',
  'duration',
  'exec',
  'url',
  'title',
];

const defaultActiveField: keyof ActivityApplicationItem = 'end';

const Activities = (props: ActivitiesProps) => {
  const { setUniqueIdsToFetch, getUser } = useUserProvider();
  const { getTokenSilently } = useAuthProvider();
  const { metabase } = useMetadataProvider();
  const timezone = useTimezone();

  const [filters, setFilters] = useState<{
    active: keyof ActivityApplicationItem;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const [dataTableKey, setDataTableKey] = useState(0);

  const [selectedFields, setSelectedFields] = useState<
    (keyof ActivityApplicationItem)[]
  >([]);

  const handleColumnItem = useCallback(
    (field: keyof ActivityApplicationItem, item: ActivityApplicationItem) => {
      if (field === 'duration') {
        let duration = getDurationSeconds(item.end, item.start);
        const hms = toHHMMSS(duration, {
          minDigit: 1,
          hrDigit: 1,
          secDigit: 1,
          msDigit: 1,
          json: true,
        }) as HHMMSSOptionsJSONRet;
        const { hours, minutes, seconds } = hms;

        return `${hours ? ` ${hours}h ` : ''}${minutes}m ${seconds}s`;
      }

      if (field === 'start' || field === 'end') {
        return `${moment
          .utc(item[field])
          .local()
          .format('MMM D, YYYY hh:mma')}`;
      }

      if (field === 'user_id') {
        let user = getUser('user_id', item.user_id);

        return (
          <UserActivitySkeleton
            user={user}
            linkProps={{
              to: `/users/${user?.id}/activity`,
              state: {
                item,
                user,
              },
            }}
          />
        );
      }

      if (field === 'division') {
        let user = getUser('user_id', item.user_id);
        return user ? user.division?.name : '...';
      }

      if (field === 'url' && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).href;
        } catch (e) {}
        return (
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className='m3-button-link'
          >
            {item[field]}
          </a>
        );
      }

      return item[field];
    },
    [getUser],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof ActivityApplicationItem) =>
      activityAppFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof ActivityApplicationItem, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof ActivityApplicationItem)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<ActivityFilterParams>(
    () => ({
      start_date: getWeekRangeStartsInMonday(moment()).start,
      end_date: getWeekRangeStartsInMonday(moment()).end,
      users: [],
      divisions: [],
      urlHosts: [],
      go2Versions: [],
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<ActivityFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // Activities ----------------------------------------------------------------
  const activityLimit = 100;
  const infiniteActivities = useInfinite<
    ActivityApplicationItem,
    UseActivitiesProps
  >({
    skipFetchOnInit: true,
    useQuery: useActivities,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: filterParams.users.map((u) => u.id).join(),
      url: filterParams.urlHosts.map((u) => u.id).join(),
      divisions: filterParams.divisions.map((u) => u.label).join(),
      go2_versions: filterParams.go2Versions.map((u) => u.id).join(),
      limit: activityLimit,
    },
  });

  // Charts --------------------------------------------------------------------
  let activityChartParams = useMemo(
    () => ({
      start_date: filterParams.start_date.format('YYYY-MM-DD'),
      end_date: filterParams.end_date.format('YYYY-MM-DD'),
      division: filterParams.divisions.map((d) => d.label),
      user_id: filterParams.users.map((u) => u.id.toString()),
      timezone,
    }),
    [
      timezone,
      filterParams.start_date,
      filterParams.end_date,
      filterParams.users,
      filterParams.divisions,
    ],
  );

  const [isDownloading, setIsDownloading] = useState(false);
  const handleOnDownloadActivity = useCallback(async () => {
    try {
      setIsDownloading(true);
      let url = toURL(
        `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/activity/download`,
        {
          start_date: filterParams.start_date.format(),
          end_date: filterParams.end_date.format(),
          user_ids: filterParams.users.map((u) => u.id).join(),
          url: filterParams.urlHosts.map((u) => u.id).join(),
          divisions: filterParams.divisions.map((u) => u.label).join(),
          go2_versions: filterParams.go2Versions.map((u) => u.id).join(),
        },
      );
      await fetchStartDownloadToCSV(
        url,
        {
          headers: {
            ...getConfigWithAuthorization(await getTokenSilently()).headers,
            ['x-timezone']: guessTimezone(), // eslint-disable-line
          },
        },
        {
          filename: `activity_${filterParams.start_date.format(
            'YYYY-MM-DD',
          )}_${filterParams.end_date.format('YYYY-MM-DD')}.csv`,
        },
      );
    } finally {
      setIsDownloading(false);
    }
  }, [filterParams, getTokenSilently, setIsDownloading]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: infiniteActivities.data.map((d) => d.user_id),
    });
  }, [infiniteActivities.data, setUniqueIdsToFetch]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: filterParams.users.map((d) => d.id),
    });
  }, [filterParams.users, setUniqueIdsToFetch]);

  useEffect(() => {
    infiniteActivities.reset({
      emptyList: true,
    });
    // eslint-disable-next-line
  }, [filterParams]);

  return (
    <>
      <DocumentTitle title='Applications & Websites' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <PageTitle
            title='Applications & Websites'
            icon={<LeaderboardOutlinedIcon style={{ fontSize: 34 }} />}
          />
          <Stack direction='row' gap={2}>
            {/* <M3Button color='secondary'>Use Saved Filters</M3Button> */}
            <M3Button
              active={isFiltersVisible}
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            >
              <FilterAltOutlinedIcon />
              {isFiltersVisible ? 'Hide' : 'Show'} Filters
            </M3Button>
          </Stack>
        </Box>
        <br />
        <Box display={isFiltersVisible ? undefined : 'none'}>
          <FiltersBoard
            filterParams={filterParams}
            selectedFields={selectedFields}
            allFields={defaultAgentFields}
            fieldLabelMap={activityAppFieldLabelMap}
            onFieldsChange={handleOnFieldsChange}
            updateFilterParams={updateFilterParams}
          />
        </Box>
        <Box
          gap={4}
          display='flex'
          flexWrap='wrap'
          style={{
            marginTop: 14,
          }}
        >
          <ActivityChart
            title='Apps/Domains'
            metabaseProps={{
              url: '/embed/question/',
              key: 'question',
              id: metabase.charts.activities.top_apps_website,
              params: activityChartParams,
            }}
          />
          <ActivityChart
            title='Members'
            metabaseProps={{
              url: '/embed/question/',
              key: 'question',
              id: metabase.charts.activities.top_members,
              params: activityChartParams,
            }}
          />
          <ActivityChart
            title='Login Sessions'
            metabaseProps={{
              url: '/embed/question/',
              key: 'question',
              id: metabase.charts.login_sessions.timeline,
              params: activityChartParams,
            }}
          />
        </Box>
        <br />
        <Stack direction='row'>
          <Typography component='div' style={{ marginTop: -6 }}></Typography>
          <Box display='flex' justifyContent='flex-end' gap={1} flex={1}>
            <Tooltip title='Refresh'>
              <Box>
                <M3IconButton
                  onClick={() => {
                    !infiniteActivities.isLoading &&
                      infiniteActivities.reset({
                        emptyList: true,
                      });
                  }}
                >
                  <RefreshOutlinedIcon
                    className={infiniteActivities.isLoading ? 'spinner' : ''}
                  />
                </M3IconButton>
              </Box>
            </Tooltip>
            <Tooltip title='Download'>
              <Box>
                <M3IconButton
                  disabled={isDownloading || infiniteActivities.isLoading}
                  onClick={handleOnDownloadActivity}
                >
                  {isDownloading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <DownloadOutlinedIcon />
                  )}
                </M3IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Stack>
        <Typography component='div'>
          <DataTable
            key={dataTableKey}
            orderBy={filters.order}
            loadingCount={10}
            // activeField={filters.active}
            isLoading={infiniteActivities.isLoading}
            fields={selectedFields.length ? selectedFields : defaultAgentFields}
            // data={activityDataTablePagination.items}
            data={infiniteActivities.data}
            fieldColumnStyleMap={agentFieldStyleMap}
            handleColumnItem={handleColumnItem}
            handleHeaderColumnItem={handleHeaderColumnItem}
            // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
          />
        </Typography>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => infiniteActivities.loadNext()}
      />
      <WindowScrollTop />
    </>
  );
};

export default Activities;
