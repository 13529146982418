import { CSSProperties } from 'react';

export interface AspectRatioContainerProps {
  ratio: '16:9' | '4:3' | '21:9' | '1:1' | '9:16';
  children: React.ReactNode;
  style?: CSSProperties;
}

const AspectRatioContainer: React.FC<AspectRatioContainerProps> = ({
  ratio,
  children,
  style,
}) => {
  const ratioMap = {
    '16:9': '56.25%',
    '4:3': '75%',
    '21:9': '42.85%',
    '1:1': '100%',
    '9:16': '177.78%',
  };

  return (
    <div
      style={{
        height: 0,
        position: 'relative',
        paddingBottom: ratioMap[ratio],
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default AspectRatioContainer;
