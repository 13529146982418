import React, { createContext, useCallback, useContext, useState } from 'react';
import { ReactRenderElement } from '../../types/types';

import { AgentMonitoringSettings } from '../../types/settings';

type SettingsProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type SettingsInitialState = {
  monitoring: AgentMonitoringSettings;
};
/**
 * Reducers that mutate the state
 */
type SettingsReducers = {
  setAllowMonitoring: (key: string, value: boolean | number) => void;
};
/**
 * Single store
 */
type SettingsStore = SettingsInitialState & SettingsReducers;
/**
 * Initial state / store
 */
const initialStore: SettingsStore = {
  /**
   * Default
   */
  monitoring: {
    allow_activity: true,
    allow_keystrokes: true,
    allow_screen_capture: true,
    screen_capture_quality: 30,
    allow_audio_capture: true,
    allow_video_capture: false,
    video_bitrate: 128,
    video_capture_interval: 1000,
  },
  setAllowMonitoring: (key: string, value: boolean | number) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const SettingsContext = createContext<SettingsStore>(initialStore);

export function useSettingsProvider(): SettingsStore {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [state, setState] = useState<SettingsStore>(initialStore);

  const setAllowMonitoring = useCallback(
    (key: string, value: boolean | number) => {
      let monitoring = {
        ...state.monitoring,
        [key]: value,
      };
      setState((state) => ({ ...state, monitoring }));
    },
    [state.monitoring, setState],
  );

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        setAllowMonitoring,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
