import React, { SyntheticEvent, useMemo, useRef, useState } from 'react';
import {
  Badge,
  PopoverProps,
  Tooltip,
  SxProps,
  Popover,
  Divider,
  FormLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import { M3IconButton } from './M3/M3Button';
import { M3MenuItem } from './M3/M3MenuItem';

import { MenuItem, ReactRenderElement } from '../types/types';
import { useCurrentProfile } from '../hooks/profile';

export type AppBarControlPanelProps = {
  icon?: ReactRenderElement;
  children?: ReactRenderElement;
  badgeCount?: number;
  redirectTo?: string;
  tooltip: {
    title: string;
    placement?: 'bottom' | 'right' | 'left' | 'top';
  };
  active?: boolean;
  popover?: Partial<PopoverProps>;
  withPopover?: boolean;
  iconButtonSx?: SxProps;
  menus?: MenuItem[];
  onClick?: () => void;
};

const AppBarControlPanel = ({
  icon,
  badgeCount,
  redirectTo,
  tooltip,
  active,
  iconButtonSx,
  popover,
  withPopover,
  onClick,
  menus: propsMenu,
  children,
}: AppBarControlPanelProps) => {
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const [open, setOpen] = useState(false);
  const { data: currentProfile } = useCurrentProfile();

  const menus = useMemo(() => {
    return (propsMenu ?? []).filter((menu) => {
      if (menu.restrict) {
        if (
          menu.restrict.some((role) => currentProfile!.roles.indexOf(role) > -1)
        ) {
          return false;
        }
      } else if (menu.roles) {
        return menu.roles.some(
          (role) => currentProfile!.roles.indexOf(role) > -1,
        );
      }
      return true;
    });
  }, [propsMenu, currentProfile]);

  function openPopover() {
    if (withPopover) {
      setOpen(true);
    }
  }

  function closePopover() {
    if (withPopover) {
      setOpen(false);
    }
  }

  function navigateToUrl(path?: string) {
    if (!!path) {
      navigate(path);
    }
  }

  return (
    <Box ref={wrapperRef} sx={{ position: 'relative' }}>
      <Tooltip title={tooltip.title} placement={tooltip.placement ?? 'bottom'}>
        <Box>
          <M3IconButton
            onClick={() => {
              openPopover();
              navigateToUrl(redirectTo);
              onClick?.();
            }}
            sx={{
              pointerEvents: 'auto',
              ...iconButtonSx,
            }}
            active={active || open}
          >
            {icon}
            {!!badgeCount && (
              <Badge
                badgeContent={badgeCount}
                color='primary'
                sx={{
                  top: -18,
                  right: -6,
                  '& .MuiBadge-badge': {
                    fontWeight: 'bold',
                    backgroundColor: '#3784d6',
                  },
                }}
              />
            )}
          </M3IconButton>
        </Box>
      </Tooltip>
      {withPopover && (
        <Popover
          open={open}
          anchorEl={wrapperRef.current}
          transitionDuration={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={closePopover}
          PaperProps={{
            sx: {
              mt: 5,
            },
          }}
        >
          {menus ? (
            <Box>
              {menus.map((menu: MenuItem) => {
                if (menu.isLabel) {
                  return (
                    <FormLabel
                      key={menu.id}
                      sx={{
                        p: 0.4,
                        pl: 2,
                        display: 'block',
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: 'inherit',
                      }}
                    >
                      {menu.icon}
                      {menu.name}
                    </FormLabel>
                  );
                }
                if (menu.divider) {
                  return (
                    <Divider
                      key={menu.id}
                      style={{
                        opacity: 0.5,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    />
                  );
                }
                return (
                  <M3MenuItem
                    key={menu.id}
                    active={menu.active}
                    onClick={(evt: SyntheticEvent) => {
                      evt.stopPropagation();
                      navigateToUrl(menu.path);
                      menu.onClick?.(evt, menu);
                      closePopover();
                    }}
                    sx={{
                      pl: 2,
                      pr: 2,
                      minWidth: 150,
                    }}
                    style={{
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {menu.name}
                  </M3MenuItem>
                );
              })}
            </Box>
          ) : (
            children
          )}
        </Popover>
      )}
    </Box>
  );
};

export default AppBarControlPanel;
