import { IterableObject } from './types';

export type ListResult<T, M = IterableObject> = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  meta?: M | null;
};

export type BasicResult<T> = {
  count: number;
  data: T[];
};

export const defaultListResult = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  meta: null,
};

export const defaultBasicResult = {
  count: 0,
  data: [],
};
