import moment, { Moment } from 'moment';
import { getDurationSeconds } from './date';

export type ItemWithLocalDates = {
  start_local: Moment;
  end_local: Moment;
};

export function transformItemWithLocalDates<T = any>({
  items = [],
  date,
  itemKeys,
}: {
  items: T[];
  date: Moment;
  itemKeys: { start: keyof T; end: keyof T };
}): (T & ItemWithLocalDates)[] {
  let itemsLocal: (T & ItemWithLocalDates)[] = [];
  let ymdSelected = date.format('YYYY-MM-DD');
  let { start, end } = itemKeys;

  items.forEach((item) => {
    let start_local = moment.utc(item[start] as string).local();
    let end_local = moment.utc(item[end] as string).local();
    let ymdStart = start_local.format('YYYY-MM-DD');
    let ymdEnd = end_local.format('YYYY-MM-DD');

    // If both start/end is on the same selected local date. We push it immediately.
    if (ymdStart === ymdSelected && ymdEnd === ymdSelected) {
      itemsLocal.push({
        ...item,
        start_local,
        end_local,
      });
    }
    // If the start date is on the previous day, and its end is on the the
    // same as selected. We will have the start to be at 12:00am
    else if (moment(ymdStart).isBefore(ymdSelected) && ymdEnd === ymdSelected) {
      itemsLocal.push({
        ...item,
        start_local: moment(ymdEnd).startOf('day'),
        end_local,
      });
    }
    // If the end date is on the next day, and its start is on the the
    // same as selected. We will have the end to be at 11:59:59pm.
    else if (moment(ymdEnd).isAfter(ymdSelected) && ymdStart === ymdSelected) {
      itemsLocal.push({
        ...item,
        start_local,
        end_local: moment(ymdStart).endOf('day'),
      });
    }
  });

  return itemsLocal;
}

const totalHoursToday = 24 * 60 * 60;
export function getDimensionPositionTimelineLocalDates({
  element,
  startDateLocal,
  endDateLocal,
}: {
  startDateLocal: Moment;
  endDateLocal: Moment;
  element?: HTMLElement | null;
}) {
  let width = element?.clientWidth ?? 0;

  let duration = getDurationSeconds(endDateLocal, startDateLocal);
  let durationStartOfDayToStartTime = getDurationSeconds(
    startDateLocal,
    startDateLocal.clone().startOf('day'),
  );
  let durationStartOfDayToEndOfTime = durationStartOfDayToStartTime + duration;

  let startPct = durationStartOfDayToStartTime / totalHoursToday;
  let endPct = durationStartOfDayToEndOfTime / totalHoursToday;
  let diffPct = endPct - startPct;
  let startPos = width * startPct;
  let endPos = width * diffPct;

  return {
    startPos,
    endPos,
    duration,
    startDateLocal,
    endDateLocal,
  };
}
