import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../../utils/request';
import { useIdentifier } from '../global/useIdentifier';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { useQueryPortalServiceApi } from '../global/usePortalServiceApi';
import {
  ActivityApplicationItem,
  IdleActivityApplicationItem,
} from '../../pages/Activities/types';
import { BiActivityIntervalItem } from '../../types/activity';

export type UseActivitiesProps = ListQuery & {
  start_date: string;
  end_date: string;
  user_ids?: string;
  execs?: string;
  url?: string;
  go2_member_ids?: string;
  member_names?: string;
  divisions?: string;
  go2_versions?: string;
  order_by?: string;
};
export function useActivities<T = ActivityApplicationItem>(
  params: UseActivitiesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseActivitiesProps>({
    params,
    baseIdentifier: 'useActivities',
    identifierKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
      'divisions',
      'go2_versions',
      'order_by',
    ],
    queryParamKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
      'divisions',
      'go2_versions',
      'order_by',
    ],
  });

  return useQueryPortalServiceApi<UseActivitiesProps, ListResult<T>>(
    identifiers,
    '/go2-agent/activity',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(data) {
        data.results = data.results.map((item: any) => ({
          ...item,
          id: item.id || `${item.go2_member_id}_${item.start_time}`,
          start: item.start || item.start_time,
          end: item.end || item.end_time,
          exec: item.exec || item.app,
        }));

        return data;
      },
    },
  );
}

export type UseSearchUrlHostsProps = ListQuery & {
  s: string;
};
export function useSearchUrlHosts<T = { host: string }>(
  params: UseSearchUrlHostsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseSearchUrlHostsProps>({
    params,
    baseIdentifier: 'useSearchUrlHosts',
    identifierKeys: ['s'],
    queryParamKeys: ['s'],
  });

  return useQueryPortalServiceApi<UseSearchUrlHostsProps, ListResult<T>>(
    identifiers,
    '/go2-agent/activity/search',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(data: ListResult<{ host: string }>) {
        data.results = data.results.map((item: any) => ({
          ...item,
          id: item.id || item.host,
        }));

        return data;
      },
    },
  );
}

export type UseUserActivityPercentageProps = ListQuery & {
  user_ids?: string;
  start_date?: string;
  end_date?: string;
  order_by?: string;
};
export function useUserActivityPercentage<T = BiActivityIntervalItem>(
  params: UseUserActivityPercentageProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseUserActivityPercentageProps>({
      params,
      baseIdentifier: 'useUserActivityPercentage',
      identifierKeys: ['user_ids', 'start_date', 'end_date', 'order_by'],
      queryParamKeys: ['user_ids', 'start_date', 'end_date', 'order_by'],
    });

  return useQueryPortalServiceApi<UseUserActivityPercentageProps, T[]>(
    identifiers,
    '/go2-agent/activity/percentage',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
    },
  );
}

export type UseUserIdleActivityProps = ListQuery & {
  start_date: string;
  end_date: string;
  user_ids?: string;
  execs?: string;
  url?: string;
  go2_member_ids?: string;
  member_names?: string;
  divisions?: string;
  order_by?: string;
};
export function useUserIdleActivity<T = IdleActivityApplicationItem>(
  params: UseUserIdleActivityProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseUserIdleActivityProps>({
    params,
    baseIdentifier: 'useUserIdleActivity',
    identifierKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
      'divisions',
      'order_by',
    ],
    queryParamKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
      'divisions',
      'order_by',
    ],
  });

  return useQueryPortalServiceApi<UseActivitiesProps, T[]>(
    identifiers,
    '/go2-agent/activity/user-idle',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(data: T[]) {
        data.forEach((item: any) => {
          Object.assign(item, {
            id: item.id || `${item.go2_member_id}_${item.start_time}`,
            start: item.start || item.start_time,
            end: item.end || item.end_time,
            exec: item.exec || item.app,
          });
        });

        return data;
      },
    },
  );
}
