import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../../utils/request';
import { useIdentifier } from '../global/useIdentifier';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { useQueryPortalServiceApi } from '../global/usePortalServiceApi';
import { UserSystemInfo } from '../../types/system-info';

function systemInfoParser(item: UserSystemInfo) {
  try {
    item.system_info = JSON.parse(item.system_info as unknown as string);
  } catch (e) {}
  return item;
}

export type UseSystemInfoProps = ListQuery & {
  user_ids?: string;
};
export function useSystemInfo<T = UserSystemInfo>(
  params: UseSystemInfoProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseSystemInfoProps>({
    params,
    baseIdentifier: 'useSystemInfo',
    identifierKeys: ['user_ids'],
    queryParamKeys: ['user_ids'],
  });

  return useQueryPortalServiceApi<UseSystemInfoProps, ListResult<T>>(
    identifiers,
    '/go2-agent/system-info',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select: (data: ListResult<UserSystemInfo>) => {
        data.results = data.results.map(systemInfoParser);
        return data;
      },
    },
  );
}

export type UseUserSystemInfoProps = ListQuery & {
  user_id: string | number;
};
export function useUserSystemInfo<T = UserSystemInfo>(
  params: UseUserSystemInfoProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseUserSystemInfoProps>({
    params,
    baseIdentifier: 'useUserSystemInfo',
    identifierKeys: ['user_id'],
    queryParamKeys: [],
  });

  return useQueryPortalServiceApi<UseUserSystemInfoProps, T>(
    identifiers,
    `/go2-agent/system-info/user/${params.user_id}`,
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select: systemInfoParser,
    },
  );
}

export type UseSearchOSVersionsProps = ListQuery & {
  order_by?: string;
};
export function useSearchOSVersions<T = any>(
  params: UseSearchOSVersionsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseSearchOSVersionsProps>({
    params,
    baseIdentifier: 'useSearchOSVersions',
    identifierKeys: ['order_by'],
    queryParamKeys: ['order_by'],
  });

  return useQueryPortalServiceApi<UseSearchOSVersionsProps, T>(
    identifiers,
    `/go2-agent/system-info/search/os-versions`,
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select: (data: ListResult<any>) => {
        data.results = data.results.map((os) => ({
          ...os,
          id: os.id || os.os_version,
        }));
        return data;
      },
    },
  );
}

export type UseSearchGo2VersionsProps = ListQuery & {};
export function useSearchGo2Versions<T = UserSystemInfo>(
  params: UseSearchGo2VersionsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseSearchGo2VersionsProps>(
    {
      params,
      baseIdentifier: 'useSearchGo2Versions',
      identifierKeys: [],
      queryParamKeys: [],
    },
  );

  return useQueryPortalServiceApi<UseSearchGo2VersionsProps, T>(
    identifiers,
    `/go2-agent/system-info/search/go2-versions`,
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select: (data: ListResult<any>) => {
        data.results = data.results.map((v) => ({
          ...v,
          id: v.id || v.go2_version,
        }));
        return data;
      },
    },
  );
}
