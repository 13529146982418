import { IterableObject } from '../types/types';

const cacheStorage: IterableObject = {};

export function getFromLocalStorage<T = any>(
  key: string,
  useCacheWithFallback: boolean = false,
  saveCacheResult: boolean = false,
) {
  let result: T | null | undefined = undefined;

  if (useCacheWithFallback) {
    /**
     * To avoid parsing every time the local storage (it's slow), need
     * to access memory for the result
     */
    const value = cacheStorage[key];

    if (typeof value !== 'undefined') {
      return value;
    }

    try {
      result = JSON.parse(
        (window.localStorage.getItem(key) || null) as string,
      ) as T;
    } catch (e) {}

    /**
     * Save also in memory the first result it find
     */
    if (saveCacheResult) {
      cacheStorage[key] = result;
    }
  } else {
    try {
      result = JSON.parse(
        (window.localStorage.getItem(key) || null) as string,
      ) as T;
    } catch (e) {}
  }

  return result;
}

export function setToLocalStorage<T = any>(
  key: string,
  value: T,
  setCacheAlso: boolean = false,
) {
  /**
   * Set cache also for faster access later, this will be saved in memory only
   */
  if (setCacheAlso) {
    cacheStorage[key] = value;
  }

  /**
   * Set it actual in local storage
   */
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: string) {
  /**
   * Delete in cache storage also
   */
  delete cacheStorage[key];

  /**
   * Actual deleting it in local storage
   */
  window.localStorage.removeItem(key);
}
