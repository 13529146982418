/** dependencies */
import { ReactElement, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'moment-timezone';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as Sentry from '@sentry/react';

/** components */
import AppLoader from './components/Loaders/AppLoader';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import HtmlBodyListener from './components/Util/HtmlBodyListener';
import M3ThemePalette from './components/M3/M3ThemePalette';

/** hooks */
import { useCurrentProfile } from './hooks/profile';

/** pages */
import Login from './pages/Login';
import Users from './pages/Users';
import UserDetail from './pages/UserDetail';
import Activities from './pages/Activities';
import Productivity from './pages/Productivity';

/** providers */
import { AppProvider } from './providers/app/app';
import { AuthProvider, useAuthProvider } from './providers/auth/auth';

/** services */

/** types */
import { ReactRenderElement } from './types/types';

/** utils */
import { setToLocalStorage } from './utils/localstorage';

/** app */
import App, { ProviderWrapper } from './App';
import Home from './Home';
import reportWebVitals from './reportWebVitals';

/** styles */
import './styles';
import theme from './assets/theme';

/**
 * Initialize the sentry the start of everything
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://92124a0cb635fc94bab7cfb211df42e3@o1008869.ingest.us.sentry.io/4507576675926016',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      // /^https:\/\/yourserver\.io\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

const RenderRoutes = () => {
  const { isAuthenticated, isDoneAuthenticating, getTokenSilently } =
    useAuthProvider();
  const { isLoading, data: userProfile } = useCurrentProfile(
    {},
    { enabled: isAuthenticated },
  );
  let content: ReactRenderElement = null;
  let loader: ReactRenderElement = null;

  if (!isDoneAuthenticating || (!userProfile && isLoading)) {
    loader = <AppLoader />;
  }

  if (!isDoneAuthenticating || (!userProfile && isLoading)) {
    content = null;
  } else {
    content = renderMainRoutes();
  }

  useEffect(() => {
    if (userProfile) {
      /**
       * Set the sentry to attach user on all calls
       */
      Sentry.getGlobalScope().setUser({
        id: `${userProfile.id}`,
        email: userProfile.email,
      });

      /**
       * Save the type(role) of user who last logged in the storage
       */
      setToLocalStorage(
        'last_user_login_role',
        userProfile.roles.join(','),
        true,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, getTokenSilently]);

  return (
    <>
      <M3ThemePalette />
      <HtmlBodyListener />
      {loader}
      {isDoneAuthenticating && (!isLoading || !!userProfile) && (
        <>
          <Routes>{content}</Routes>
        </>
      )}
    </>
  );
};

const renderUnauthorizedRoutes = () => {
  return (
    <>
      <Route path='/login' element={<Login />} />
    </>
  );
};

/**
 * List of routes that need authentication, should passed children
 * that requires authentication
 */
const renderAuthorizedRoutes = (children: ReactRenderElement) => {
  return (
    <>
      <Route path='/' element={<App />}>
        {children}
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </>
  );
};

const renderMainRoutes = () => {
  return (
    <>
      {renderUnauthorizedRoutes()}
      {renderAuthorizedRoutes(
        <Route path='' element={<Home />}>
          <Route path='/unauthorized' element={null} />
          <Route path='/users/:userId/:tabName' element={<UserDetail />} />
          <Route path='/users/:userId' element={<UserDetail />} />
          <Route path='/users' element={<Users />} />
          <Route path='/activities' element={<Activities />} />
          <Route path='/productivity' element={<Productivity />} />
          <Route path='/' element={<Navigate to='/users' />} />
        </Route>,
      )}
    </>
  );
};

/**
 * List of providers that doesn't need authorized user
 */
function RootDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  const providers: ProviderWrapper[] = [AppProvider];

  return providers.reduceRight(
    (children, Provider: ProviderWrapper) => <Provider>{children}</Provider>,
    children,
  ) as ReactElement;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <RootDataProviders>
                    <RenderRoutes />
                  </RootDataProviders>
                </SnackbarProvider>
              </LocalizationProvider>
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </StyledEngineProvider>
    </ThemeProvider>
    <ErrorBoundary>
      <Analytics />
    </ErrorBoundary>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
