import React, { PropsWithChildren, SyntheticEvent, useMemo } from 'react';
import { Box, Stack, Tabs } from '@mui/material';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';

import { M3TabButton } from '../../components/M3/M3TabButton';

import { AgentUser } from '../Users/types';
import { ReactRenderElement } from '../../types/types';

type UserTabsProps = PropsWithChildren & {
  item?: AgentUser | null;
  tabIndexName: TabButtonParamItemNames;
  handleOnTabChange: (item: TabButtonParamItem, index: number) => void;
};

export type TabButtonParamItemNames =
  | 'activity'
  | 'session-log'
  | 'time-worked'
  | 'alerts'
  | 'snapshots'
  | 'emails'
  | 'file-transfers'
  | 'online-meetings'
  | 'printing'
  | 'keystrokes'
  | 'network-monitoring'
  | 'audio'
  | 'computers';

export type TabButtonParamItem = {
  name: TabButtonParamItemNames;
  icon: ReactRenderElement;
  label: ReactRenderElement;
};

const UserTabs = ({
  children,
  item,
  tabIndexName,
  handleOnTabChange,
}: UserTabsProps) => {
  const tabButtonElements: TabButtonParamItem[] = useMemo(() => {
    return [
      {
        name: 'activity',
        icon: <MonitorOutlinedIcon />,
        label: 'Activity',
      },
      /*
      {
        name: 'session-log',
        icon: <ComputerOutlinedIcon />,
        label: 'Session Log',
      },
      */
      /*
      {
        name: 'time-worked',
        icon: <WorkHistoryOutlinedIcon />,
        label: 'Time Worked',
      },
      */
      /*
      {
        name: 'alerts',
        icon: <WarningAmberOutlinedIcon />,
        label: 'Alerts',
      },
      */
      /*
      {
        name: 'snapshots',
        icon: <ScreenshotMonitorOutlinedIcon />,
        label: 'Snapshots',
      },
      */
      {
        name: 'keystrokes',
        icon: <KeyboardAltOutlinedIcon />,
        label: 'Keystrokes',
      },
      /*
      {
        name: 'audio',
        icon: <RecordVoiceOverOutlinedIcon />,
        label: 'Audio',
      },
      */
      /*
      {
        name: 'emails',
        icon: <MailOutlineOutlinedIcon />,
        label: 'Emails',
      },
      */
      /*
      {
        name: 'file-transfers',
        icon: <DriveFileMoveOutlinedIcon />,
        label: 'File Transfers',
      },
      */
      /*
      {
        name: 'online-meetings',
        icon: <VideocamOutlinedIcon />,
        label: 'Online Meetings',
      },
      */
      /*
      {
        name: 'printing',
        icon: <PrintOutlinedIcon />,
        label: 'Printing',
      },
      */
      /*
      {
        name: 'network-monitoring',
        icon: <WifiOutlinedIcon />,
        label: 'Network Monitoring',
      },
      */
    ];
  }, []);
  const tabIndex = tabButtonElements.findIndex((t) => t.name === tabIndexName);

  const renderTabButton = ({ name, icon, label }: TabButtonParamItem) => {
    return (
      <M3TabButton
        key={name}
        disableRipple
        label={
          <>
            {icon}
            {label}
          </>
        }
      />
    );
  };

  const renderTabs = () => {
    return (
      <Box>
        <Tabs
          value={tabIndex}
          onChange={(evt: SyntheticEvent, index: number) =>
            handleOnTabChange(tabButtonElements[index], index)
          }
          sx={{ paddingTop: 0 }}
        >
          {tabButtonElements.map(renderTabButton)}
        </Tabs>
      </Box>
    );
  };

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' pl={1}>
        {renderTabs()}
      </Stack>
    </Box>
  );
};

export default UserTabs;
