import { Box } from '@mui/material';
import React from 'react';

type Props = {
  online?: boolean;
  uppercase?: boolean;
};

const OnlineOffline = ({ online, uppercase = false }: Props) => {
  return (
    <Box
      gap={1}
      height='100%'
      display='flex'
      alignItems='center'
      style={{
        textTransform: uppercase ? 'uppercase' : 'lowercase',
      }}
    >
      <Box
        sx={{
          width: 8,
          height: 8,
          minWidth: 8,
          minHeight: 8,
          borderRadius: '50%',
          background: online ? 'green' : 'gray',
          opacity: online ? 1 : 0.5,
        }}
      />
      <span
        style={{
          opacity: 0.8,
          marginTop: uppercase ? 0 : -3,
        }}
      >
        {online ? 'online' : 'offline'}
      </span>
    </Box>
  );
};

export default OnlineOffline;
