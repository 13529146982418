import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import M3AlertDialog from '../M3/M3Dialogs';

import { ReactRenderElement } from '../../types/types';

type DialogProps = {
  title?: ReactRenderElement;
  message?: ReactRenderElement;
  content?: ReactRenderElement;
  afterMessage?: ReactRenderElement;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose?: () => void;
  isOpen: OpenState['isOpen'];
  setIsOpen: OpenState['setIsOpen'];
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  dialogActions?: {
    cancel?: {
      hidden?: boolean;
      label?: ReactRenderElement;
    };
    confirm?: {
      hidden?: boolean;
      label?: ReactRenderElement;
    };
  };
};

type OpenState = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function useConfirmationDialog(): {
  cancel: OpenState;
  confirm: OpenState;
} {
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  return {
    cancel: {
      isOpen: isCancelOpen,
      setIsOpen: setIsCancelOpen,
    },
    confirm: {
      isOpen: isConfirmOpen,
      setIsOpen: setIsConfirmOpen,
    },
  };
}

function ConfirmationDialog({
  title,
  message,
  content,
  afterMessage,
  isOpen,
  isLoading,
  setIsOpen,
  onConfirm,
  onClose,
  cancelDisabled,
  confirmDisabled,
  dialogActions,
  ...restProps
}: DialogProps) {
  return (
    <M3AlertDialog
      open={isOpen}
      dialog={{
        title,
        message,
        content: (
          <Box pt={0.5} pb={0.5}>
            {content}
          </Box>
        ),
        afterMessage,
      }}
      actions={{
        cancel: dialogActions?.cancel?.hidden
          ? undefined
          : {
              label: dialogActions?.cancel?.label,
              disabled: cancelDisabled ?? isLoading,
              onClick: () => {
                setIsOpen(false);
                onClose?.();
              },
            },
        confirm: {
          disabled: confirmDisabled ?? isLoading,
          label: isLoading ? (
            <Box
              width={57}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress
                size={24}
                sx={{
                  color: '#fff',
                }}
              />
            </Box>
          ) : (
            dialogActions?.confirm?.label ?? 'Confirm'
          ),
          onClick: () => {
            onConfirm();
          },
        },
      }}
      {...restProps}
    />
  );
}

export default ConfirmationDialog;
