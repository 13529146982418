import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../utils/request';
import { useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';
import { ListQuery } from '../types/request';
import { ListResult } from '../types/response';
import { DivisionModelAdmin } from '../types/divisions';

export type UseAdminDivisionsProps = ListQuery & {
  ids?: string;
  name?: string;
  status?: 'active' | 'inactive';
  type?: 'internal' | 'partner';
};
export function useAdminDivisions<T = DivisionModelAdmin>(
  params: UseAdminDivisionsProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseAdminDivisionsProps>({
    params,
    baseIdentifier: 'useAdminDivisions',
    identifierKeys: ['ids', 'name', 'status', 'type'],
    queryParamKeys: ['ids', 'name', 'status', 'type'],
  });

  return useQueryApi<UseAdminDivisionsProps, ListResult<T>>(
    identifiers,
    '/admin/divisions/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
