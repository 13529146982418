import { UseQueryOptions } from '@tanstack/react-query';
import { useIdentifier } from '../../global/useIdentifier';
import { useQueryApi } from '../../global/useApi';
import { defaultReactQueryParams } from '../../../utils/request';
import { ListQuery } from '../../../types/request';
import { Go2AgentAppConfigItem } from './app-config.type';
import { ListResult } from '../../../types/response';

export type UseUserGo2AgentAppConfigProps = ListQuery & {
  os?: 'macOS' | 'windows';
  owner_id?: number;
};
export function useUserGo2AgentAppConfig<R = Go2AgentAppConfigItem>(
  params: UseUserGo2AgentAppConfigProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseUserGo2AgentAppConfigProps>({
      params,
      baseIdentifier: 'useUserGo2AgentAppConfig',
      identifierKeys: ['os', 'owner_id'],
      queryParamKeys: ['os', 'owner_id'],
    });

  return useQueryApi<UseUserGo2AgentAppConfigProps, ListResult<R>>(
    identifiers,
    '/go2-agent/app-config/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseGo2AgentAppConfigDefaultsProps = ListQuery & {};
export function useGo2AgentAppConfigDefaults<R = Go2AgentAppConfigItem>(
  params: UseGo2AgentAppConfigDefaultsProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseGo2AgentAppConfigDefaultsProps>({
      params,
      baseIdentifier: 'useGo2AgentAppConfigDefaults',
      identifierKeys: [],
      queryParamKeys: [],
    });

  return useQueryApi<UseGo2AgentAppConfigDefaultsProps, R[]>(
    identifiers,
    '/go2-agent/app-config/defaults/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
