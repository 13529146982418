import { useEffect, useMemo, useState } from 'react';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { ListQuery } from '../../types/request';
import { ListResult, defaultListResult } from '../../types/response';
import { IterableObject } from '../../types/types';
import { usePagination } from './usePagination';

type InfinitePropsParams<P> = ListQuery & {
  page: string;
} & P;
export type UseInfiniteProps<P, R = any> = {
  keyFieldName?: keyof R;
  useQuery: <R>(
    params: InfinitePropsParams<P>,
    queryOptions?: UseQueryOptions,
  ) => UseQueryResult<ListResult<R> | R[]>;
  searchKey?: string;
  queryParams?: P & { limit?: string | number };
  queryOptions?: UseQueryOptions;
  transformData?: (data: any) => any;
  isFlatResult?: boolean;
  skipFetchOnInit?: boolean;
  filterIterator?: (item: any, index: number) => boolean;
};

type ResetOptions = {
  emptyList?: boolean;
};
export type UseInfiniteResetOptions = ResetOptions;
export type UseInfiniteRet<R, M = IterableObject> = {
  isSuccess: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isLoadingMoreSuccess: boolean;
  hasReachEnd: boolean;
  page: number;
  data: R[];
  metadata?: M | null;
  count: number;
  savedCounter: number;
  savedMoreCounter: number;
  reset: (options?: ResetOptions) => void;
  search: (text: string) => void;
  loadNext: () => void;
  updateData: (transformData: (item: R) => R) => void;
  setPage: (page: number) => void;
};

type InfiniteItemResult = { id: string | number; [key: string]: any };
type InfiniteFlatListState<R> = {
  end: boolean;
  result: R[];
};

type IdsCache<R> = {
  ids: (number | string)[];
  cache: IterableObject<R>;
};

export function useInfinite<
  R extends InfiniteItemResult,
  P = any,
  M = IterableObject,
>({
  keyFieldName = 'id',
  useQuery,
  searchKey,
  queryParams,
  queryOptions,
  transformData,
  isFlatResult,
  skipFetchOnInit,
  filterIterator,
}: UseInfiniteProps<P, R>): UseInfiniteRet<R, M> {
  const [listResult, setListResult] =
    useState<ListResult<R, M>>(defaultListResult);
  const [listFlatResult, setListFlatResult] = useState<
    InfiniteFlatListState<R>
  >({
    end: false,
    result: [],
  });
  const [metadata, setMetadata] = useState<M | null>(listResult.meta ?? null);

  const [idsCache, setIdsCache] = useState<IdsCache<R>>({
    ids: [],
    cache: {},
  });
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [refetchCounter, setRefetchCounter] = useState(1);
  const [willSkipFetchOnInit, setWillSkipFetchOnInit] = useState(
    skipFetchOnInit || false,
  );
  const [totalCount, setTotalCount] = useState(listResult.count);
  const [savedCounter, setSavedCounter] = useState(0);
  const [savedMoreCounter, setSavedMoreCounter] = useState(0);

  const {
    params: { page, offset, limit, total, ...restPaginationParams },
    setPage,
  } = usePagination({
    ...listResult,
    limit: parseInt(queryParams?.limit as string) || 10,
  });
  const {
    isLoading,
    isSuccess,
    data: result,
    remove,
    refetch: refetchQuery,
  } = useQuery<R>(
    {
      ...restPaginationParams,
      ...queryParams,
      ...(searchKey ? { [searchKey]: searchValue } : null),
      page: `${page}`,
      offset,
      limit,
    } as InfinitePropsParams<P>,
    {
      enabled: false,
      keepPreviousData: false,
      cacheTime: 0,
      staleTime: 0,
      ...queryOptions,
    },
  );
  /**
   * List of items of type <R>
   */
  const data = useMemo(
    () => idsCache.ids.map((id) => idsCache.cache[id]),
    [idsCache],
  );

  const isLoadingMore = isLoading && page > 1;
  const isLoadingMoreSuccess = isSuccess && page > 1;
  const hasReachEnd =
    !isLoading &&
    page > 1 &&
    (isFlatResult ? listFlatResult.end : data.length >= listResult.count);

  function loadNext() {
    if (isFlatResult) {
      if (listFlatResult.end || isLoading || !data.length) return;
    } else {
      if (page >= total || isLoading || !data.length) return;
    }
    /**
     * NOTE: Calculating the next page to load, avoid negative or zero page
     */
    let nextPage = Math.ceil((data.length + limit!) / limit!);
    nextPage = nextPage <= 1 ? 1 : nextPage;
    setPage(nextPage);
    setRefetchCounter((state) => state + 1);
  }

  function setCurrentPage(page: number) {
    setPage(page);
    setRefetchCounter((state) => state + 1);
  }

  function reset({ emptyList }: ResetOptions = {} as ResetOptions) {
    if (emptyList) {
      setIdsCache((state) => ({ ...state, ids: [] }));
    }
    if (isFlatResult) {
      setListFlatResult({
        end: false,
        result: [],
      });
    }
    setWillSkipFetchOnInit(false);
    setPage(1);
    setRefetchCounter((state) => state + 1);
  }

  function search(value: string = '') {
    setSearchValue(value);
  }

  function updateData(transformData: (item: R) => R) {
    setIdsCache((state) => {
      let { ids, cache } = state;
      cache = { ...cache };
      ids
        .map((id) => cache[id])
        .forEach((item) => {
          cache[item[keyFieldName]] = transformData(item) ?? item;
        });
      ids = filterIterator
        ? ids.filter((id, index) => filterIterator(cache[id], index))
        : ids;

      return { ...state, ids, cache };
    });
  }

  useEffect(() => {
    if (!isLoading && isSuccess && result) {
      const newIds: (number | string)[] = [];
      const newData: { [key: string]: R } = {};
      const mapItem = (item: R) => {
        item = transformData?.(item) ?? item;
        newIds.push(item[keyFieldName]);
        newData[item[keyFieldName]] = item;
        return item;
      };

      if (isFlatResult) {
        const newResult: R[] = (result as unknown as R[]).map(mapItem);
        setListFlatResult({
          end: newResult.length < limit!,
          result: newResult,
        });
      } else {
        (result as ListResult<R>).results.map(mapItem);
        setListResult(result as ListResult<R, M>);
        setMetadata(((result as ListResult<R>).meta ?? null) as M | null);
        setTotalCount((result as ListResult<R>).count);
      }

      setIdsCache((state) => {
        let { ids, cache } = state;
        const index = page - 1;
        const position = limit! * index;

        cache = { ...cache, ...newData };

        ids = [...ids];
        ids.splice(position, 0, ...newIds);
        ids = Array.from(new Set(ids));
        ids = filterIterator
          ? ids.filter((id, index) => filterIterator(cache[id], index))
          : ids;

        return { ...state, ids, cache };
      });

      if (page === 1) {
        setSavedCounter((state) => state + 1);
      }
      if (page !== 1) {
        setSavedMoreCounter((state) => state + 1);
      }

      remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFlatResult, page, isSuccess, result]);

  useEffect(() => {
    if (!willSkipFetchOnInit) {
      refetchQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willSkipFetchOnInit, page, refetchCounter]);

  return {
    page,
    isSuccess,
    isLoading,
    isLoadingMore,
    isLoadingMoreSuccess,
    hasReachEnd,
    data,
    reset,
    search,
    loadNext,
    updateData,
    metadata,
    count: totalCount,
    savedCounter,
    savedMoreCounter,
    setPage: setCurrentPage,
  };
}
