import { CSSProperties, PropsWithChildren } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import { ReactRenderElement } from '../../types/types';

type FormPanelProps = PropsWithChildren & {
  sx?: SxProps;
  label?: ReactRenderElement;
  style?: CSSProperties;
};
const FormPanel = ({ sx, style, label, children }: FormPanelProps) => {
  return (
    <Box sx={sx} style={style}>
      {!!label && (
        <Typography
          component='div'
          fontWeight={500}
          fontSize={14}
          mb={0.4}
          pl={1}
        >
          {label}
        </Typography>
      )}
      <Typography component='div'>{children}</Typography>
    </Box>
  );
};

export default FormPanel;
