import React, { createContext, useCallback, useContext, useState } from 'react';

import { IterableObject, ReactRenderElement } from '../../types/types';
import mainPalette from '../../assets/palettes/main';
import { cleanPaletteSource, extractTokenToPalette } from '../../utils/color';
import { getFromLocalStorage } from '../../utils/localstorage';
import { parseURL } from '../../utils/url';

type AppProviderProps = {
  children?: ReactRenderElement;
};
type ExtractedPalette = IterableObject<string>;

/**
 * State that we can mutate
 */
type AppInitialState = {
  withNavRail: boolean;
  drawerWidth: number;
  toolbarHeight: number;
  subToolbarHeight: number;
  actionTabsHeight: number;
  isDarkMode: boolean;
  /**
   * List all the actions you want to listen for changes.
   * This is useful when you wan to listen for global action that is outside
   * of current screen
   */
  actionKeyCounter: IterableObject;
  /**
   * Container for palette
   */
  paletteSource: string;
  palettes: IterableObject<ExtractedPalette> & {
    main: ExtractedPalette;
  };
  isDrawerOpen: boolean;
};
/**
 * List here should match the "actionKeyCounter" object
 */
type actionKeysCounter = string;
/**
 * Reducers that mutate the state
 */
type AppReducers = {
  updateActionKeyCounter: (key: actionKeysCounter) => void;
  getActionKeyCounter: (key: actionKeysCounter) => number;
  updatePalette: (palette?: string | null) => void;
  setDarkMode: (isDarkMode: boolean) => boolean | void;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  setWithNavRail: (withNavRail: boolean) => void;
};
/**
 * Single store
 */
type AppStore = AppInitialState & AppReducers;
let localUserColorScheme = getFromLocalStorage('user_color_scheme');
let parsedURL = parseURL(window.location.search);
/**
 * Initial state / store
 */
const initialStore: AppStore = {
  withNavRail: false,
  drawerWidth: 300,
  toolbarHeight: 64,
  subToolbarHeight: 64,
  actionTabsHeight: 100,
  isDarkMode:
    typeof parsedURL.is_dark_mode !== 'undefined'
      ? parsedURL.is_dark_mode
      : getFromLocalStorage('is-dark-mode') ?? false,
  actionKeyCounter: {},
  paletteSource: cleanPaletteSource(localUserColorScheme || mainPalette),
  palettes: {
    main: extractTokenToPalette(
      cleanPaletteSource(localUserColorScheme || mainPalette),
    ),
  },
  isDrawerOpen: false,
  updateActionKeyCounter: () => {
    throw new Error('Implementation required');
  },
  getActionKeyCounter: () => {
    throw new Error('Implementation required');
  },
  updatePalette: (palette?: string | null) => {
    throw new Error('Implementation required');
  },
  setDarkMode: (isDarkMode: boolean) => {
    throw new Error('Implementation required');
  },
  setIsDrawerOpen: (isDrawerOpen: boolean) => {
    throw new Error('Implementation required');
  },
  setWithNavRail: (withNavRail: boolean) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const AppContext = createContext<AppStore>(initialStore);

export function useAppProvider(): AppStore {
  return useContext(AppContext);
}

export function AppProvider({ children }: AppProviderProps) {
  const [state, setState] = useState<AppStore>(initialStore);

  /**
   * Define all the handlers here how you want to mutate the state
   */
  const updateActionKeyCounter = useCallback(
    (key: actionKeysCounter) => {
      setState((state) => ({
        ...state,
        actionKeyCounter: {
          ...state.actionKeyCounter,
          [key]: (state.actionKeyCounter[key] ?? 0) + 1,
        },
      }));
    },
    [setState],
  );

  const getActionKeyCounter = useCallback(
    (key: actionKeysCounter) => {
      return state.actionKeyCounter[key] ?? 0;
    },
    [state],
  );

  const updatePalette = useCallback(
    (palette?: string | null) => {
      let source = cleanPaletteSource(palette || mainPalette);
      setState((state) => ({
        ...state,
        paletteSource: source,
        palettes: {
          ...state.palettes,
          main: extractTokenToPalette(source),
        },
      }));
    },
    [setState],
  );

  const setDarkMode = useCallback(
    (isDarkMode: boolean) => setState((state) => ({ ...state, isDarkMode })),
    [setState],
  );

  const setIsDrawerOpen = useCallback(
    (isDrawerOpen: boolean) =>
      setState((state) => ({ ...state, isDrawerOpen })),
    [setState],
  );

  const setWithNavRail = useCallback(
    (withNavRail: boolean) => setState((state) => ({ ...state, withNavRail })),
    [setState],
  );

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateActionKeyCounter,
        getActionKeyCounter,
        updatePalette,
        setDarkMode,
        setIsDrawerOpen,
        setWithNavRail,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
