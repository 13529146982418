import { jsonToCsv } from './csv';

type ExportLinkToCSVProps<T> = {
  data: T[];
  filename: string;
  csvHeaders: string[];
  csvHeaderKeys: string[];
};
export function exportLinkToCSV<T = any>({
  data,
  filename,
  csvHeaders,
  csvHeaderKeys,
}: ExportLinkToCSVProps<T>) {
  const csvRaw = jsonToCsv<T>(data, csvHeaders, csvHeaderKeys);
  const url = window.URL.createObjectURL(new Blob([csvRaw]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${filename}.csv`);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export async function fetchStartDownloadToCSV(
  endpoint: string,
  config?: RequestInit,
  opt?: {
    filename?: string;
  },
) {
  try {
    const { filename } = opt || {};
    const response = await fetch(endpoint, config);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the filename from the Content-Disposition header
    const fname =
      response.headers.get('Content-Disposition')?.split('filename=')[1] ||
      filename ||
      'download.csv';

    // Get the blob from the response
    const blob = await response.blob();

    // Create a temporary URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fname;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (e) {
    // Sentry here...
  }
}
