export function preloadAudio(url: string) {
  const audio = document.createElement('audio');
  let isCancelled = false;
  let isResolved = false;

  const promise = new Promise<void>((resolve, reject) => {
    audio.oncanplaythrough = () => {
      if (!isCancelled) {
        isResolved = true;
        audio.src = '';
        audio.load();
        resolve();
      }
    };
    audio.onerror = (error) => {
      if (!isCancelled) {
        reject(error);
      }
    };
  });

  const cancel = () => {
    if (isResolved) return;

    isCancelled = true;
    audio.src = '';
    audio.load();
  };

  audio.muted = true;
  audio.preload = 'auto';
  audio.src = url;

  return { promise, cancel };
}
