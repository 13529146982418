import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Chip, Stack, SxProps, Typography } from '@mui/material';

import M3SurfaceContainer from '../../components/M3/M3SurfaceContainer';
import { M3Dropdown } from '../../components/M3/M3Select';
import { M3OptionItem } from '../../components/Popover/BasicPopoverWithSearch';
import FilterColumnPanel from '../../components/Filters/FilterColumnPanel';
import AutocompleteSelectionPopover, {
  AutocompleteFormState,
} from '../../components/Filters/AutocompleteSelectionPopover';
import { FormStateRet, useForm } from '../../components/BasicForm';
import FormPanel from '../../components/Panels/FormPanel';
import { useAutocompleteUserSearch } from '../../components/Filters/AutocompleteUserSearch';

import { ReactRenderElement } from '../../types/types';
import { AgentUser } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import AutocompleteSearch, {
  AutocompleteSearchOptionItem,
} from '../../components/Filters/AutocompleteSearch';
import {
  useAdminDivisions,
  UseAdminDivisionsProps,
} from '../../hooks/divisions';
import { useUserSearch } from '../../hooks/profile';
import {
  useSearchGo2Versions,
  useSearchOSVersions,
} from '../../hooks/go2-agent/system-info';

type FiltersBoardProps = {
  filterParams: AgentUserFilterParams;
  fieldLabelMap: { [K in keyof Partial<AgentUser>]: string };
  allFields: (keyof AgentUser)[];
  selectedFields: (keyof AgentUser)[];
  onFieldSelect?: (field: keyof AgentUser, remove?: boolean) => void;
  onFieldsChange?: (fields: (keyof AgentUser)[]) => void;
  updateFilterParams: (params: Partial<AgentUserFilterParams>) => void;
};

export type DateFormState = {
  start_date: string | null;
  end_Date: string | null;
};

export type AgentUserFilterParams = {
  users: M3OptionItem[];
  divisions: M3OptionItem[];
  os?: string | null;
  osVersions: M3OptionItem[];
  go2Versions: M3OptionItem[];
};

const FiltersBoard = ({
  filterParams,
  fieldLabelMap,
  allFields,
  selectedFields,
  onFieldSelect,
  onFieldsChange,
  updateFilterParams,
}: FiltersBoardProps) => {
  // Users ---------------------------------------------------------------------
  const autocompleteUserSearch = useAutocompleteUserSearch();
  const [userSearchKeyCounter, setUserSearchKeyCounter] = useState(0);
  const handleOnUserSearchSelected = useCallback(
    (users: any) => {
      autocompleteUserSearch.handleOnUserSearchSelected(users);
      updateFilterParams({
        users: users as unknown as AgentUserFilterParams['users'],
      });
    },
    // eslint-disable-next-line
    [updateFilterParams, autocompleteUserSearch.handleOnUserSearchSelected],
  );

  // Divisions -----------------------------------------------------------------
  const [divisionsSelected, setDivisionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [divisionKeyCounter, setDivisionKeyCounter] = useState(0);
  const getDivisionsOptionFormatter = useCallback(
    (div: any) =>
      ({
        id: div.id,
        label: div.name,
        props: div,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnDivisionSearchSelected = useCallback(
    (divisions: any) => {
      setDivisionsSelected(divisions as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        divisions: divisions as unknown as AgentUserFilterParams['divisions'],
      });
    },
    [setDivisionsSelected, updateFilterParams],
  );

  // Platform ------------------------------------------------------------------
  const dropdownSx: SxProps = {
    left: 0,
    top: -41,
    width: '100%',
    position: 'absolute',
  };
  const dropdownContentSx: SxProps = {
    height: 50,
    opacity: 0,
    width: '100%',
  };
  const OSOptions: M3OptionItem[] = useMemo(() => {
    return [
      {
        id: 0,
        label: 'All',
      },
      {
        id: 'Windows',
        label: 'windows',
      },
      {
        id: 'macOS',
        label: 'macOS',
      },
      {
        id: 'NULL',
        label: '–',
      },
    ];
  }, []);
  const [selectedOS, setSelectedOS] = useState(OSOptions[0]);
  const handleOnSetSelectedOS = useCallback(
    (opt: M3OptionItem) => {
      setSelectedOS(opt);
      updateFilterParams({
        os: opt.id ? (opt.id as string) : undefined,
      });
    },
    [setSelectedOS, updateFilterParams],
  );

  // OS Versions ---------------------------------------------------------------
  const [osVersionsSelected, setOSVersionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [osVersionKeyCounter, setOSVersionKeyCounter] = useState(0);
  const getOSVersionsOptionFormatter = useCallback(
    (os: any) =>
      ({
        id: os.os_version,
        label: os.os_version,
        props: os,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnOSVersionSearchSelected = useCallback(
    (osVersions: any) => {
      setOSVersionsSelected(osVersions as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        osVersions:
          osVersions as unknown as AgentUserFilterParams['osVersions'],
      });
    },
    [setOSVersionsSelected, updateFilterParams],
  );

  // Go2 Versions ---------------------------------------------------------------
  const [go2VersionsSelected, setGo2VersionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [go2VersionKeyCounter, setGo2VersionKeyCounter] = useState(0);
  const getGo2VersionsOptionFormatter = useCallback(
    (v: any) =>
      ({
        id: v.go2_version,
        label: v.go2_version,
        props: v,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnGo2VersionSearchSelected = useCallback(
    (go2Versions: any) => {
      setGo2VersionsSelected(go2Versions as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        go2Versions:
          go2Versions as unknown as AgentUserFilterParams['go2Versions'],
      });
    },
    [setGo2VersionsSelected, updateFilterParams],
  );

  // Status --------------------------------------------------------------------
  /*
  const statusOptions: M3OptionItem[] = useMemo(() => {
    return [
      {
        id: 0,
        label: 'All',
      },
      {
        id: 'online',
        label: 'ONLINE',
      },
      {
        id: 'offline',
        label: 'OFFLINE',
      },
    ];
  }, []);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const statusRenderOption = useCallback(
    ({
      key,
      option,
      onSelect,
      displayOnly,
      active,
    }: Partial<CustomRenderOptionProps & { displayOnly?: boolean }>) => {
      return (
        <BasicItemOption
          key={key}
          active={active}
          option={option!}
          onSelect={onSelect}
          height={displayOnly ? 48 : undefined}
        >
          {
            (option!.id === 0 ? (
              option?.label
            ) : (
              <OnlineOffline online={option!.id === 'online'} />
            )) as any
          }
        </BasicItemOption>
      );
    },
    [],
  );
  */

  // Fields --------------------------------------------------------------------
  const fieldsAutocompleteForm = useForm<
    AutocompleteFormState & { search: string }
  >({
    search: '',
    checked: {},
    optionById: {},
  });
  const totalFieldsSelected = useMemo(() => {
    return Object.values(fieldsAutocompleteForm.formState.checked).filter(
      (checked) => checked,
    ).length;
  }, [fieldsAutocompleteForm]);

  const renderSectionTitle = (title: ReactRenderElement) => (
    <Typography
      component='div'
      pt={1.5}
      fontWeight={500}
      fontSize={14}
      sx={{
        opacity: 0.5,
      }}
    >
      {title}
    </Typography>
  );

  const renderFilterBy = () => {
    return (
      <Box flex={1} mb={2}>
        {renderSectionTitle('Filter By:')}
        <Box
          gap={2}
          display='flex'
          flexWrap='wrap'
          sx={{
            pt: 1,
            zIndex: 1,
            position: 'relative',
          }}
        >
          {/* <FormPanel label='Date' sx={{ position: 'relative', zIndex: 2 }}>
            <FilterColumnPanel
              name='date'
              displayValue={`${moment(formState.start_date).format(
                'MMM D, YYYY',
              )} - ${moment(formState.end_Date).format('MMM D, YYYY')}`}
              textFieldSx={{ width: 240 }}
            >
              <DateRangeSelectionPopover
                startDate={formState.start_date}
                endDate={formState.end_Date}
                onChange={({ start, end }) => {}}
              />
            </FilterColumnPanel>
          </FormPanel> */}
          <FormPanel label='Name' sx={{ minWidth: 300, flex: 1 }}>
            <AutocompleteSearch
              key={userSearchKeyCounter}
              multiple
              withSearchIcon
              searchKey='s'
              placeholder='Search name...'
              onSelect={handleOnUserSearchSelected}
              defaultOptionsSelected={autocompleteUserSearch.usersSelected}
              optionFormatter={autocompleteUserSearch.getUserOptionFormatter}
              onSearchTextOptions={
                autocompleteUserSearch.onUserSearchTextOptions
              }
              renderOptionItem={autocompleteUserSearch.getRenderUserOptionItem}
              useInfiniteProps={{
                isFlatResult: true,
                useQuery: useUserSearch as any,
              }}
            />
          </FormPanel>
          <FormPanel label='Division' sx={{ minWidth: 300 }}>
            <AutocompleteSearch
              key={divisionKeyCounter}
              multiple
              searchKey='name'
              placeholder='Search division...'
              defaultOptionsSelected={divisionsSelected}
              onSelect={handleOnDivisionSearchSelected}
              optionFormatter={getDivisionsOptionFormatter}
              useInfiniteProps={{
                useQuery: useAdminDivisions,
                queryParams: {
                  status: 'active',
                } as UseAdminDivisionsProps,
              }}
            />
          </FormPanel>
          <FormPanel label='OS'>
            <FilterColumnPanel
              alwaysOpen
              name='os'
              displayValue={selectedOS.label}
              textFieldSx={{ width: 100 }}
            >
              <M3Dropdown
                selected={selectedOS}
                options={OSOptions}
                sx={dropdownSx}
                contentSx={dropdownContentSx}
                onSelect={handleOnSetSelectedOS}
                paperProps={{
                  style: {
                    width: 120,
                  },
                }}
              />
            </FilterColumnPanel>
          </FormPanel>
          <FormPanel label='OS Version' sx={{ minWidth: 210 }}>
            <AutocompleteSearch
              key={osVersionKeyCounter}
              multiple
              searchKey='s'
              orderGroupBy='DESC'
              placeholder='Search OS versions...'
              defaultOptionsSelected={osVersionsSelected}
              onSelect={handleOnOSVersionSearchSelected}
              optionFormatter={getOSVersionsOptionFormatter}
              useInfiniteProps={{
                useQuery: useSearchOSVersions as any,
                queryParams: {
                  limit: 30,
                },
              }}
            />
          </FormPanel>
          <FormPanel label='Go2 Version' sx={{ minWidth: 250 }}>
            <AutocompleteSearch
              key={go2VersionKeyCounter}
              multiple
              searchKey='s'
              orderGroupBy='DESC'
              placeholder='Search Go2 versions...'
              defaultOptionsSelected={go2VersionsSelected}
              onSelect={handleOnGo2VersionSearchSelected}
              optionFormatter={getGo2VersionsOptionFormatter}
              useInfiniteProps={{
                useQuery: useSearchGo2Versions as any,
                queryParams: {
                  limit: 30,
                },
              }}
            />
          </FormPanel>

          {/* <FormPanel label='Go2 Version'>
            <AutocompleteSelectionPopover
              width={210}
              placeholder='Select Go2 version...'
              options={
                []
                // current!.id === 'my-squad'
                //   ? current!.members!.map((member) => ({
                //       id: member.id,
                //       value: member.name,
                //     }))
                //   : (filterMember.data || []).map((m) => ({
                //       id: m.employee_id,
                //       value: m.member_name,
                //     }))
              }
              form={
                go2VersionAutocompleteFormAutocompleteForm as unknown as FormStateRet<AutocompleteFormState>
              }
            />
          </FormPanel> */}
          {/* <FormPanel label='Status'>
            <FilterColumnPanel
              alwaysOpen
              useTextField={false}
              name='status'
              displayValue={statusRenderOption({
                key: selectedStatus.id,
                option: selectedStatus,
                displayOnly: true,
              })}
              boxFieldSx={{ width: 100 }}
            >
              <M3Dropdown
                selected={selectedStatus}
                options={statusOptions}
                sx={dropdownSx}
                contentSx={dropdownContentSx}
                onSelect={setSelectedStatus}
                customRenderOption={statusRenderOption}
                paperProps={{
                  style: {
                    width: 120,
                  },
                }}
              />
            </FilterColumnPanel>
          </FormPanel> */}
          <FormPanel
            label='Fields'
            sx={{ minWidth: 300, position: 'relative', flex: 1 }}
          >
            <span
              style={{
                top: 2,
                right: 0,
                opacity: 0.5,
                fontSize: 12,
                position: 'absolute',
              }}
            >
              (
              {!totalFieldsSelected || totalFieldsSelected === allFields.length
                ? `All selected`
                : `${totalFieldsSelected} selected`}
              )
            </span>
            <AutocompleteSelectionPopover
              width='100%'
              placeholder={`Select${
                totalFieldsSelected ? `ed (${totalFieldsSelected})` : ''
              } field${
                !totalFieldsSelected || totalFieldsSelected > 1 ? 's' : ''
              }...`}
              options={allFields.map((field) => ({
                id: field,
                value:
                  fieldLabelMap[field] ??
                  toTitleCase(stripToFormatterWord(field)),
              }))}
              form={
                fieldsAutocompleteForm as unknown as FormStateRet<AutocompleteFormState>
              }
            />
          </FormPanel>
        </Box>
      </Box>
    );
  };

  const renderTag = ({ id, tag, label, onDelete }: any) => {
    return (
      <Chip
        size='small'
        key={id}
        label={
          <span style={{ fontWeight: 500 }}>
            <span
              style={{
                opacity: 0.39,
                paddingRight: 4,
                fontWeight: 400,
              }}
            >
              {tag}:
            </span>
            {label}
          </span>
        }
        onDelete={onDelete}
      />
    );
  };

  const renderTags = () => {
    return (
      <Stack direction='row' gap={1} flexWrap='wrap' mb={1}>
        {autocompleteUserSearch.usersSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Name',
            label: opt.label,
            onDelete: () => {
              setUserSearchKeyCounter((c) => ++c);
              handleOnUserSearchSelected(
                autocompleteUserSearch.usersSelected.filter(
                  (u) => u.id !== opt.id,
                ),
              );
            },
          });
        })}
        {divisionsSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Division',
            label: opt.label,
            onDelete: () => {
              setDivisionKeyCounter((c) => ++c);
              handleOnDivisionSearchSelected(
                divisionsSelected.filter((div) => div.id !== opt.id),
              );
            },
          });
        })}
        {!!(selectedOS && selectedOS.id) &&
          renderTag({
            id: selectedOS.id,
            tag: 'OS',
            label: selectedOS.label,
            onDelete: () => handleOnSetSelectedOS(OSOptions[0]),
          })}
        {osVersionsSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'OS Version',
            label: opt.label,
            onDelete: () => {
              setOSVersionKeyCounter((c) => ++c);
              handleOnOSVersionSearchSelected(
                osVersionsSelected.filter((osv) => osv.id !== opt.id),
              );
            },
          });
        })}
        {go2VersionsSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Go2 Version',
            label: opt.label,
            onDelete: () => {
              setGo2VersionKeyCounter((c) => ++c);
              handleOnGo2VersionSearchSelected(
                go2VersionsSelected.filter((go2v) => go2v.id !== opt.id),
              );
            },
          });
        })}
      </Stack>
    );
  };

  useEffect(() => {
    const fields = Object.keys(fieldsAutocompleteForm.formState.checked).filter(
      (key) => fieldsAutocompleteForm.formState.checked[key],
    );
    onFieldsChange?.(fields as (keyof AgentUser)[]);
  }, [onFieldsChange, fieldsAutocompleteForm.formState]);

  return (
    <Box mt={-2}>
      <M3SurfaceContainer
        elevation={1}
        sx={{
          mb: 1,
          padding: '12px 30px 18px',
        }}
      >
        {/* {renderSearchBy()} */}
        {renderFilterBy()}
        {renderTags()}
        {/* <Box
          gap={2}
          display='flex'
          justifyContent='space-between'
          sx={{
            pb: 1,
            pt: 4,
          }}
        >
          <M3Button
            type='submit'
            color='primary'
            variant='contained'
            sx={{
              width: 100,
            }}
          >
            Submit
          </M3Button>
          <M3Button
            disabled
            color='secondary'
            variant='outlined'
            sx={{
              width: 110,
            }}
          >
            Save Filters
          </M3Button>
        </Box> */}
      </M3SurfaceContainer>
    </Box>
  );
};

export default FiltersBoard;
