import React, { memo } from 'react';
import { Box } from '@mui/material';

import { TimelineMediaSource } from './TimelinePlayer';
import moment from 'moment';

type Props = {
  sources?: TimelineMediaSource[];
};

const getPositionPercentageByTimestamp = (timestamp: string): number => {
  const date = moment(timestamp).local(); // Convert to local time
  const startOfDay = date.clone().startOf('day');
  const endOfDay = date.clone().endOf('day');

  const totalDayTime = endOfDay.diff(startOfDay);
  const timeElapsed = date.diff(startOfDay);

  return (timeElapsed / totalDayTime) * 100;
};

const TimelineVideoSource = ({ sources = [] }: Props) => {
  const totalDuration = 24 * 60 * 50;

  return (
    <Box
      style={{
        height: 5,
        position: 'relative',
      }}
    >
      {sources.map((s) => {
        let spanWidthPct = `${(s.duration / totalDuration) * 100}%`;
        let leftPos = `${getPositionPercentageByTimestamp(s.timestamp)}%`;

        return (
          <Box
            key={s.id}
            style={{
              top: -3,
              left: leftPos,
              position: 'absolute',
              width: spanWidthPct,
              height: 8,
              backgroundColor: s.color,
            }}
          />
        );
      })}
    </Box>
  );
};

export default memo(TimelineVideoSource);
