import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemButton,
  styled,
  ListItemProps,
  ListItemButtonProps,
} from '@mui/material';
import clsx from 'clsx';

import { useAppProvider } from '../../providers/app/app';
import { PaletteTypeButtonProps } from '../../types/app';

export type M3ListItemProps = ListItemProps;
export const M3ListItem = styled(ListItem)``;

/**
 * TODO: need to change to var(...)
 */
export const M3ListItemButtonBase = styled(ListItemButton)`
  height: 44px;
  font-size: 14px;
  transition: none;
  border-radius: 32px;

  .MuiListItemText-root,
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
  }

  &:hover {
    color: var(--md-ref-palette-secondary10);
    background-color: var(--md-ref-palette-secondary90);
  }

  &.Mui-selected {
    background-color: transparent;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.04),
          rgba(0, 0, 0, 0.04)
        ),
        var(--md-ref-palette-secondary90);
    }

    &:not(.collapsable) {
      background-color: var(--md-ref-palette-secondary90);

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.08),
            rgba(0, 0, 0, 0.08)
          ),
          var(--md-ref-palette-secondary90);
      }

      &:active {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.16),
            rgba(0, 0, 0, 0.16)
          ),
          var(--md-ref-palette-secondary90);
      }
    }
  }

  &.dark-mode-button {
    &:hover {
      color: var(--md-ref-palette-secondary80);
      background-color: var(--md-ref-palette-secondary30);
    }

    &.Mui-selected {
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.04),
            rgba(255, 255, 255, 0.04)
          ),
          var(--md-ref-palette-secondary30);
      }

      &:not(.collapsable) {
        background-color: var(--md-ref-palette-secondary30);

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.08),
              rgba(255, 255, 255, 0.08)
            ),
            var(--md-ref-palette-secondary30);
        }

        &:active {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.16),
              rgba(255, 255, 255, 0.16)
            ),
            var(--md-ref-palette-secondary30);
        }
      }
    }
  }
`;

type M3ListItemButtonBaseProps = ListItemButtonProps &
  PaletteTypeButtonProps & {
    collapsable?: boolean;
  };

export const M3ListItemButton = ({
  sx,
  palette: paletteKey = 'main',
  collapsable,
  className,
  ...restProps
}: M3ListItemButtonBaseProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <M3ListItemButtonBase
      sx={{
        ...sx,
      }}
      className={clsx(className, {
        collapsable,
        'dark-mode-button': isDarkMode,
      })}
      {...restProps}
    />
  );
};

export const M3ListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }
`;
