import { Box, SxProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
  title?: ReactNode | string | null;
  message?: ReactNode | string | null;
  sx?: SxProps;
};

const Error = ({
  title = '404 Not Found',
  message = (
    <>
      Maybe this page used to exist or you just spelled something wrong. Chances
      are you spelled something wrong, so you can double check the URL?
      <br />
      <br />
      Mind checking your internet connect as well?
    </>
  ),
  sx,
}: Props) => {
  return (
    <Box
      sx={{
        padding: '20vh 0 10vh',
        ...sx,
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          flex: 1,
          maxWidth: 600,
          margin: '0 auto',
        }}
      >
        <Typography
          component='div'
          fontSize={22}
          fontWeight={500}
          textAlign='center'
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
        <Typography
          component='div'
          fontSize={16}
          fontWeight={400}
          textAlign='center'
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default Error;
