// eslint-disable-next-line import/no-anonymous-default-export
export default `
:root {
  --md-source: #424242;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #001f24;
  --md-ref-palette-primary20: #00363d;
  --md-ref-palette-primary25: #00424a;
  --md-ref-palette-primary30: #004f58;
  --md-ref-palette-primary35: #005b66;
  --md-ref-palette-primary40: #006874;
  --md-ref-palette-primary50: #008391;
  --md-ref-palette-primary60: #00a0b0;
  --md-ref-palette-primary70: #22bccf;
  --md-ref-palette-primary80: #4fd8eb;
  --md-ref-palette-primary90: #97f0ff;
  --md-ref-palette-primary95: #d0f8ff;
  --md-ref-palette-primary98: #edfcff;
  --md-ref-palette-primary99: #f6feff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #051f23;
  --md-ref-palette-secondary20: #1c3438;
  --md-ref-palette-secondary25: #273f43;
  --md-ref-palette-secondary30: #334b4f;
  --md-ref-palette-secondary35: #3e565b;
  --md-ref-palette-secondary40: #4a6267;
  --md-ref-palette-secondary50: #637b80;
  --md-ref-palette-secondary60: #7c959a;
  --md-ref-palette-secondary70: #96b0b4;
  --md-ref-palette-secondary80: #b1cbd0;
  --md-ref-palette-secondary90: #cde7ec;
  --md-ref-palette-secondary95: #dbf6fa;
  --md-ref-palette-secondary98: #edfcff;
  --md-ref-palette-secondary99: #f6feff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #0e1b37;
  --md-ref-palette-tertiary20: #24304d;
  --md-ref-palette-tertiary25: #2f3b58;
  --md-ref-palette-tertiary30: #3b4664;
  --md-ref-palette-tertiary35: #465271;
  --md-ref-palette-tertiary40: #525e7d;
  --md-ref-palette-tertiary50: #6b7697;
  --md-ref-palette-tertiary60: #8490b2;
  --md-ref-palette-tertiary70: #9faace;
  --md-ref-palette-tertiary80: #bac6ea;
  --md-ref-palette-tertiary90: #dae2ff;
  --md-ref-palette-tertiary95: #eef0ff;
  --md-ref-palette-tertiary98: #faf8ff;
  --md-ref-palette-tertiary99: #fefbff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #191c1d;
  --md-ref-palette-neutral20: #2e3132;
  --md-ref-palette-neutral25: #393c3d;
  --md-ref-palette-neutral30: #444748;
  --md-ref-palette-neutral35: #505354;
  --md-ref-palette-neutral40: #5c5f5f;
  --md-ref-palette-neutral50: #747878;
  --md-ref-palette-neutral60: #8e9192;
  --md-ref-palette-neutral70: #a9acac;
  --md-ref-palette-neutral80: #c4c7c7;
  --md-ref-palette-neutral90: #e1e3e3;
  --md-ref-palette-neutral95: #eff1f1;
  --md-ref-palette-neutral98: #f8fafa;
  --md-ref-palette-neutral99: #fafdfd;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #141d1f;
  --md-ref-palette-neutral-variant20: #293234;
  --md-ref-palette-neutral-variant25: #343d3f;
  --md-ref-palette-neutral-variant30: #3f484a;
  --md-ref-palette-neutral-variant35: #4b5456;
  --md-ref-palette-neutral-variant40: #576062;
  --md-ref-palette-neutral-variant50: #6f797a;
  --md-ref-palette-neutral-variant60: #899294;
  --md-ref-palette-neutral-variant70: #a3adaf;
  --md-ref-palette-neutral-variant80: #bfc8ca;
  --md-ref-palette-neutral-variant90: #dbe4e6;
  --md-ref-palette-neutral-variant95: #e9f2f4;
  --md-ref-palette-neutral-variant98: #f2fbfd;
  --md-ref-palette-neutral-variant99: #f6feff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #006874;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #97f0ff;
  --md-sys-color-on-primary-container-light: #001f24;
  --md-sys-color-secondary-light: #4a6267;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #cde7ec;
  --md-sys-color-on-secondary-container-light: #051f23;
  --md-sys-color-tertiary-light: #525e7d;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #dae2ff;
  --md-sys-color-on-tertiary-container-light: #0e1b37;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fafdfd;
  --md-sys-color-on-background-light: #191c1d;
  --md-sys-color-surface-light: #fafdfd;
  --md-sys-color-on-surface-light: #191c1d;
  --md-sys-color-surface-variant-light: #dbe4e6;
  --md-sys-color-on-surface-variant-light: #3f484a;
  --md-sys-color-outline-light: #6f797a;
  --md-sys-color-inverse-on-surface-light: #eff1f1;
  --md-sys-color-inverse-surface-light: #2e3132;
  --md-sys-color-inverse-primary-light: #4fd8eb;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #006874;
  --md-sys-color-surface-tint-color-light: #006874;
  /* dark */
  --md-sys-color-primary-dark: #4fd8eb;
  --md-sys-color-on-primary-dark: #00363d;
  --md-sys-color-primary-container-dark: #004f58;
  --md-sys-color-on-primary-container-dark: #97f0ff;
  --md-sys-color-secondary-dark: #b1cbd0;
  --md-sys-color-on-secondary-dark: #1c3438;
  --md-sys-color-secondary-container-dark: #334b4f;
  --md-sys-color-on-secondary-container-dark: #cde7ec;
  --md-sys-color-tertiary-dark: #bac6ea;
  --md-sys-color-on-tertiary-dark: #24304d;
  --md-sys-color-tertiary-container-dark: #3b4664;
  --md-sys-color-on-tertiary-container-dark: #dae2ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #191c1d;
  --md-sys-color-on-background-dark: #e1e3e3;
  --md-sys-color-surface-dark: #191c1d;
  --md-sys-color-on-surface-dark: #e1e3e3;
  --md-sys-color-surface-variant-dark: #3f484a;
  --md-sys-color-on-surface-variant-dark: #bfc8ca;
  --md-sys-color-outline-dark: #899294;
  --md-sys-color-inverse-on-surface-dark: #191c1d;
  --md-sys-color-inverse-surface-dark: #e1e3e3;
  --md-sys-color-inverse-primary-dark: #006874;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #4fd8eb;
  --md-sys-color-surface-tint-color-dark: #4fd8eb;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  --md-sys-typescale-label-small-line-height: 16px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  --md-sys-typescale-label-medium-line-height: 16px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  --md-sys-typescale-label-large-line-height: 20px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  --md-sys-typescale-body-small-line-height: 16px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  --md-sys-typescale-body-medium-line-height: 20px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  --md-sys-typescale-body-large-line-height: 24px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  --md-sys-typescale-headline-small-line-height: 32px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  --md-sys-typescale-headline-large-line-height: 40px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  --md-sys-typescale-display-small-line-height: 44px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  --md-sys-typescale-display-medium-line-height: 52px;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  --md-sys-typescale-display-large-line-height: 64px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
  --md-sys-typescale-title-small-line-height: 20px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  --md-sys-typescale-title-medium-line-height: 24px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  --md-sys-typescale-title-large-line-height: 28px;
}
`;
