import React, { CSSProperties, useRef } from 'react';

type Props = {
  title?: string;
  src?: string;
  srcDoc?: string;
  style?: CSSProperties;
  onLoaded?: () => void;
  sandBoxPermissions?: string;
};

const BasicIframe = ({ title = '', src, srcDoc, style }: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <iframe
      ref={iframeRef}
      title={title}
      src={src}
      srcDoc={srcDoc}
      style={{
        border: 0,
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        position: 'relative',
        background: 'transparent',
        ...style,
      }}
    ></iframe>
  );
};

export default BasicIframe;
