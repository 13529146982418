import { UseQueryOptions } from '@tanstack/react-query';
import { useIdentifier } from '../../global/useIdentifier';
import { useQueryApi } from '../../global/useApi';
import { defaultReactQueryParams } from '../../../utils/request';
import { ListQuery } from '../../../types/request';
import {
  Go2AgentAppWebsiteWhitelistItem,
  Go2AgentAppWebsiteWhitelistItemDetail,
} from './app-website-whitelist.type';
import { ListResult } from '../../../types/response';

export type UseGo2AgentAppWebsiteWhitelistDefaultsProps = ListQuery & {};
export function useGo2AgentAppWebsiteWhitelistDefaults<
  R = Go2AgentAppWebsiteWhitelistItem,
>(
  params: UseGo2AgentAppWebsiteWhitelistDefaultsProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseGo2AgentAppWebsiteWhitelistDefaultsProps>({
      params,
      baseIdentifier: 'useGo2AgentAppWebsiteWhitelistDefaults',
      identifierKeys: [],
      queryParamKeys: [],
    });

  return useQueryApi<UseGo2AgentAppWebsiteWhitelistDefaultsProps, R[]>(
    identifiers,
    '/go2-agent/app-website-whitelist/defaults/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseUserGo2AgentAppConfigProps = ListQuery & {
  type?: 'web' | 'app';
  division_ids?: string;
  user_ids?: string;
  is_default?: boolean;
};
export function useUserGo2AgentAppWebsiteWhitelist<
  R = Go2AgentAppWebsiteWhitelistItem,
>(
  params: UseUserGo2AgentAppConfigProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseUserGo2AgentAppConfigProps>({
      params,
      baseIdentifier: 'useUserGo2AgentAppWebsiteWhitelist',
      identifierKeys: ['type', 'is_default', 'division_ids', 'user_ids'],
      queryParamKeys: ['type', 'is_default', 'division_ids', 'user_ids'],
    });

  return useQueryApi<UseUserGo2AgentAppConfigProps, ListResult<R>>(
    identifiers,
    '/go2-agent/app-website-whitelist/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseAppWebsiteWhitelistByIdProps = ListQuery & {
  id: number | string;
};
export function useAppWebsiteWhitelistById<
  R = Go2AgentAppWebsiteWhitelistItemDetail,
>(
  params: UseAppWebsiteWhitelistByIdProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseAppWebsiteWhitelistByIdProps>({
      params,
      baseIdentifier: 'useAppWebsiteWhitelistById',
      identifierKeys: ['id'],
      queryParamKeys: [],
    });

  return useQueryApi<UseAppWebsiteWhitelistByIdProps, R>(
    identifiers,
    `/go2-agent/app-website-whitelist/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export type UseAppWebsiteWhitelistForUserProps = ListQuery & {
  id: number | string | null;
};
export function useAppWebsiteWhitelistForUser<
  R = Go2AgentAppWebsiteWhitelistItemDetail,
>(
  params: UseAppWebsiteWhitelistForUserProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseAppWebsiteWhitelistForUserProps>({
      params,
      baseIdentifier: 'useAppWebsiteWhitelistForUser',
      identifierKeys: ['id'],
      queryParamKeys: [],
    });

  return useQueryApi<UseAppWebsiteWhitelistForUserProps, R[]>(
    identifiers,
    `/go2-agent/app-website-whitelist/users/${params.id}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
