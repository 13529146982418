import { Box, SxProps } from '@mui/material';
import React, { CSSProperties, PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  sx?: SxProps;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
};

const AbsoluteCenterBox = ({
  children,
  sx,
  className,
  onClick,
  style,
}: Props) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        ...sx,
      }}
      style={style}
      onClick={onClick}
      className={className}
    >
      {children}
    </Box>
  );
};

export default AbsoluteCenterBox;
