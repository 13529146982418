import React, { useMemo } from 'react';
import { TextField, styled, TextFieldProps } from '@mui/material';
import { v4 as uuid } from 'uuid';

export const M3TextFieldBase = styled(TextField)`
  .MuiInputBase-root {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--md-ref-palette-primary40);
      }
    }
  }

  .MuiInputBase-input {
    height: 50px;
    font-size: 16px;
    display: block;
    box-sizing: border-box;
    padding: 0 15px 0 15px;
  }

  .MuiInputLabel-root {
    transform: translate(14px, 13px) scale(1);
  }

  .MuiInputLabel-shrink {
    transform: translate(15px, -8px) scale(0.74);
  }

  .MuiInputBase-multiline {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiInputBase-inputMultiline {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .MuiInputLabel-root {
    color: var(--md-sys-color-on-surface-variant-light);

    &.Mui-focused {
      color: var(--md-sys-color-on-surface-variant-light);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--md-ref-palette-neutral80);
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--md-ref-palette-primary40) !important;
    }
  }

  .Mui-disabled {
    opacity: 0.8;
    pointer-events: none;
    -webkit-text-fill-color: inherit !important;
  }
`;

export type M3TextFieldProps = TextFieldProps & {
  stripAllAutoProps?: boolean;
};
export const M3TextField = ({
  sx,
  stripAllAutoProps,
  ...restProps
}: M3TextFieldProps) => {
  /**
   * NOTE: We are having different id, there's a bug on the mui system
   * where it removes styling when it has the same sx structure
   */
  const uniqueId = useMemo(() => uuid(), []);

  return (
    <M3TextFieldBase
      sx={{
        [uniqueId]: {
          m: 0,
        },
        ...sx,
      }}
      {...(stripAllAutoProps
        ? {
            autoCapitalize: 'off',
            autoComplete: 'off',
            autoCorrect: 'off',
            autoFocus: false,
          }
        : null)}
      {...restProps}
    />
  );
};
