import { PropsWithChildren, MutableRefObject } from 'react';
import clsx from 'clsx';
import PerfectScrollbarPackage, {
  ScrollBarProps,
} from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

export type PerfectScrollbarProps = PropsWithChildren &
  ScrollBarProps & {
    className?: string;
    onSetRef?: MutableRefObject<null | PerfectScrollbarRef>;
  };

export type PerfectScrollbarRef = PerfectScrollbarPackage & {
  _container: HTMLDivElement;
  _ps: {
    update?: () => void;
  };
};

function PerfectScrollbar({
  children,
  className,
  onSetRef,
  onScroll,
  style,
  options,
  ...restProps
}: PerfectScrollbarProps) {
  return (
    <PerfectScrollbarPackage
      ref={onSetRef}
      className={clsx(className, {
        'perfect-scrollbar': true,
      })}
      options={{
        wheelPropagation: false,
        ...options,
      }}
      style={{
        touchAction: 'none',
        ...style,
      }}
      onScroll={onScroll}
      {...restProps}
    >
      {children}
    </PerfectScrollbarPackage>
  );
}

export default PerfectScrollbar;
