import { Box, Stack, SxProps } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import BasicDatePicker from './BasicDatePicker';
import { DatePickerProps } from '@mui/x-date-pickers';

type Props = {
  direction?: 'row' | 'column';
  format?: string;
  startDate?: string | null;
  endDate?: string | null;
  width?: string | number;
  textFieldSx?: SxProps;
  columnSx?: SxProps;
  gap?: number;
  fromDatePickerProps?: Partial<DatePickerProps<any, any>>;
  toDatePickerProps?: Partial<DatePickerProps<any, any>>;
  onChange?: (start: string | null, end: string | null) => void;
};

const BasicDateRangePicker = ({
  direction = 'row',
  startDate = null,
  format = 'YYYY-MM-DD',
  endDate = null,
  gap = 2,
  width,
  onChange,
  textFieldSx,
  columnSx,
  fromDatePickerProps,
  toDatePickerProps,
}: Props) => {
  const [values, setValues] = React.useState<(string | null)[]>([
    startDate,
    endDate,
  ]);

  const textFieldPropsSX: SxProps = {
    p: 0,
    pt: 1,
    width: width || 130,
    border: 0,
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
      border: '0 !important',
    },
    '.MuiSvgIcon-root': {},
    '.MuiInputBase-input': {
      p: 0,
      height: 30,
      fontSize: 14,
      borderRadius: 0,
    },
    '.MuiInputBase-root': {},
    '.MuiInputLabel-root': {
      top: -12,
      left: -5,
      '&.Mui-focused,&.MuiFormLabel-filled': {
        opacity: 0.8,
        fontWeight: 'bold',
        transform: 'translate(4px, 6px) scale(0.8)',
      },
    },
  };

  useEffect(() => {
    onChange?.(values[0], values[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[0], values[1]]);

  return (
    <Stack direction={direction} gap={gap}>
      <Box flex={1} sx={columnSx}>
        <BasicDatePicker
          label='From'
          defaultValue={values[0]}
          format={format}
          maxDate={values[1] ? moment(values[1]) : null}
          textFieldProps={{
            fullWidth: true,
            sx: {
              ...textFieldPropsSX,
              ...textFieldSx,
            },
          }}
          onChange={(value: Moment | null) => {
            if (value) {
              setValues((state) => [value.format(format), state[1]]);
            }
          }}
          datePickerProps={fromDatePickerProps}
        />
      </Box>
      <Box flex={1} sx={columnSx}>
        <BasicDatePicker
          disableFuture
          label='To'
          defaultValue={values[1]}
          format={format}
          textFieldProps={{
            fullWidth: true,
            sx: {
              ...textFieldPropsSX,
              ...textFieldSx,
            },
          }}
          onChange={(value: Moment | null) => {
            if (value) {
              setValues((state) => [state[0], value.format(format)]);
            }
          }}
          datePickerProps={toDatePickerProps}
        />
      </Box>
    </Stack>
  );
};

export default BasicDateRangePicker;
