import React, { CSSProperties, PropsWithChildren } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import BasicIframe from '../../components/BasicIframe';

import { ReactRenderElement } from '../../types/types';
import {
  useMetabaseIFrame,
  UseMetabaseProps,
} from '../../hooks/utils/metabase';
import { useAppProvider } from '../../providers/app/app';

type ActivityChartProps = PropsWithChildren & {
  title?: ReactRenderElement;
  flex?: number;
  sx?: SxProps;
  chartSx?: SxProps;
  chartStyle?: CSSProperties;
  metabaseProps: UseMetabaseProps;
};

const ActivityChart = ({
  title,
  flex = 1,
  sx,
  chartSx,
  chartStyle,
  metabaseProps,
}: ActivityChartProps) => {
  const { isDarkMode } = useAppProvider();
  const { iframe } = useMetabaseIFrame(metabaseProps);

  return (
    <Box flex={flex} sx={sx}>
      {title && (
        <Typography
          component='div'
          fontSize={15}
          fontWeight={500}
          mb={1}
          sx={{
            opacity: 0.8,
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          height: 320,
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          border: '1px solid rgba(255, 255, 255, 0.03)',
          borderRadius: 1,
          ...chartSx,
        }}
        style={chartStyle}
      >
        <BasicIframe
          src={
            iframe
              ? iframe +
                '&hide_download_button=true' +
                `${isDarkMode ? '&theme=night' : ''}`
              : ''
          }
        />
      </Box>
    </Box>
  );
};

export default ActivityChart;
