import { SxProps, Toolbar } from '@mui/material';
import React, { CSSProperties } from 'react';
import { useAppProvider } from '../providers/app/app';
import { ReactRenderElement } from '../types/types';

type Props = {
  children?: ReactRenderElement;
  sx?: SxProps;
  style?: CSSProperties;
};

const AppToolbar = ({ children, sx, style }: Props) => {
  const { toolbarHeight } = useAppProvider();

  return (
    <Toolbar
      sx={{
        height: `${toolbarHeight}px !important`,
        minHeight: `${toolbarHeight}px !important`,
        width: '100%',
        ...sx,
      }}
      style={style}
      className='app-toolbar'
    >
      {children}
    </Toolbar>
  );
};

export default AppToolbar;
