import { useEffect } from 'react';

type Props = {};

const HtmlBodyListener = (props: Props) => {
  useEffect(() => {
    let observer: MutationObserver = new MutationObserver(
      (mutations: MutationRecord[], observer: MutationObserver) => {
        for (let mutation of mutations) {
          const overflow = (mutation.target as HTMLBodyElement).style.overflow;
          document.documentElement.style.overflow = overflow;
        }
      },
    );

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer && observer.disconnect();
    };
  }, []);

  return null;
};

export default HtmlBodyListener;
