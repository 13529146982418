import moment from 'moment';
import { useEffect, useState } from 'react';

export function useTick(interval: number = 1000) {
  const [second, setSecond] = useState(0);
  const [start] = useState(() => moment());

  useEffect(() => {
    const tick = () => {
      setSecond(moment().diff(start, 'second'));
    };
    const timer = setInterval(tick, interval);
    tick();

    return () => {
      timer && clearInterval(timer);
    };
  }, [start, interval, setSecond]);

  return second;
}
