import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

import { ReactRenderElement } from '../../../types/types';
import { Go2AgentAppWebsiteWhitelistItemDetail } from '../../../hooks/cosmos/go2-agent/app-website-whitelist.type';

type ApplicationsAndWebsitesSettingsProps = {
  isGlobal?: boolean;
  userId?: number | null;
  userAppWebsiteWhitelist?: Go2AgentAppWebsiteWhitelistItemDetail[];
};

const ApplicationsAndWebsitesSettings = ({
  isGlobal,
  userId,
  userAppWebsiteWhitelist = [],
}: ApplicationsAndWebsitesSettingsProps) => {
  const renderForm = () => {
    return (
      <>
        <Box>
          <Typography
            gap={1}
            component='h5'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            Applications & Websites
          </Typography>
          <Typography component='div' fontSize={14} style={{ opacity: 0.5 }}>
            All applications and websites are monitored by default. Below are
            the lists of exceptions for this user.
          </Typography>
        </Box>
        <br />
        <SettingsForm
          isGlobal={isGlobal}
          userId={userId}
          userAppWebsiteWhitelist={userAppWebsiteWhitelist}
        />
      </>
    );
  };

  return <Box>{renderForm()}</Box>;
};

type SettingsFormProps = Partial<ApplicationsAndWebsitesSettingsProps> & {};
const SettingsForm = ({
  userId,
  userAppWebsiteWhitelist,
}: SettingsFormProps) => {
  const { appItems, webItems } = useMemo(() => {
    return {
      appItems: userAppWebsiteWhitelist?.filter((d) => d.type === 'app') ?? [],
      webItems: userAppWebsiteWhitelist?.filter((d) => d.type === 'web') ?? [],
    };
  }, [userAppWebsiteWhitelist]);

  const renderAppsWebsiteItem = (
    item: Go2AgentAppWebsiteWhitelistItemDetail,
  ) => {
    return (
      <Typography
        key={item.uuid}
        component='div'
        fontSize={15}
        display='flex'
        alignItems='center'
        style={{
          padding: '2px 0',
          userSelect: 'none',
        }}
      >
        <Box flex={1} display='flex' alignItems='center' flexWrap='nowrap'>
          <span>{item.text}</span>
          {item.is_default && (
            <PublicOutlinedIcon
              style={{
                fontSize: 13,
                opacity: 0.4,
                marginLeft: 8,
              }}
            />
          )}
        </Box>
      </Typography>
    );
  };

  const renderColumnPanelTitle = (
    title: ReactRenderElement,
    total: number,
    icon: ReactRenderElement,
  ) => {
    return (
      <Typography
        mb={1}
        component='div'
        fontSize={15}
        fontWeight={500}
        display='flex'
        alignItems='center'
      >
        {icon}
        &nbsp;&nbsp;{title}
        <span style={{ opacity: 0.5, marginLeft: 6 }}>({total})</span>
      </Typography>
    );
  };

  return (
    <Box>
      <Stack gap={10} direction='row' flex={1} style={{ minHeight: 100 }}>
        <Box flex={1} width={0}>
          {renderColumnPanelTitle(
            'Applications',
            appItems.length,
            <WidgetsOutlinedIcon style={{ fontSize: 20 }} />,
          )}
          {appItems.map(renderAppsWebsiteItem)}
        </Box>
        <Box flex={1} width={0}>
          {renderColumnPanelTitle(
            'Websites',
            webItems.length,
            <LanguageOutlinedIcon style={{ fontSize: 20 }} />,
          )}
          {webItems.map(renderAppsWebsiteItem)}
        </Box>
      </Stack>
      <br />
    </Box>
  );
};

export default ApplicationsAndWebsitesSettings;
