import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../../utils/request';
import { useIdentifier } from '../global/useIdentifier';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { useQueryPortalServiceApi } from '../global/usePortalServiceApi';
import { AgentUser, AgentUserLastActive } from '../../pages/Users/types';

export type UseUserAgentStatusProps = ListQuery & {
  user_ids?: string;
  division_ids?: string;
  os?: string | null;
  os_versions?: string;
  go2_versions?: string;
  order_by?: string;
};
export function useUserAgentStatus<T = AgentUser>(
  params: UseUserAgentStatusProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseUserAgentStatusProps>({
    params,
    baseIdentifier: 'useUserAgentStatus',
    identifierKeys: [
      'user_ids',
      'division_ids',
      'os',
      'os_versions',
      'go2_versions',
      'order_by',
    ],
    queryParamKeys: [
      'user_ids',
      'division_ids',
      'os',
      'os_versions',
      'go2_versions',
      'order_by',
    ],
  });

  return useQueryPortalServiceApi<UseUserAgentStatusProps, ListResult<T>>(
    identifiers,
    '/go2-agent/user-status',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
    },
  );
}

export type UseUserLastActiveProps = ListQuery & {
  start_date: string;
  end_date: string;
  user_ids?: string;
  division_ids?: string;
};
export function useUserLastActive<T = AgentUserLastActive>(
  params: UseUserLastActiveProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseUserLastActiveProps>({
    params,
    baseIdentifier: 'useUserLastActive',
    identifierKeys: ['division_ids', 'user_ids', 'start_date', 'end_date'],
    queryParamKeys: ['division_ids', 'user_ids', 'start_date', 'end_date'],
  });

  return useQueryPortalServiceApi<UseUserLastActiveProps, ListResult<T>>(
    identifiers,
    '/go2-agent/user-status/last-active',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(data: ListResult<AgentUserLastActive>) {
        data.results.forEach((item) => {
          item.id = item.id || item.user_id;
        });

        return data;
      },
    },
  );
}
