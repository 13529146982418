import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { M3IconButton } from './M3/M3Button';

import { useVercelDeployment } from '../hooks/vercel';
import { useAppProvider } from '../providers/app/app';
import { applyOpacityOnColor } from '../utils/color';

type FileVersioningNoticeProps = {};

const FileVersioningNotice = (props: FileVersioningNoticeProps) => {
  const {
    isDarkMode,
    palettes: { main: palette },
  } = useAppProvider();
  const [isNoticeShown, setIsNoticeShown] = useState(false);
  const [animate, setAnimate] = useState(false);
  const isProdBranch =
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'production';

  const intervalInMinutes = 30;
  const { data } = useVercelDeployment(
    {
      repo: process.env.REACT_APP_VERCEL_GIT_REPO_SLUG!,
      branch: process.env.REACT_APP_VERCEL_GIT_COMMIT_REF!,
    },
    {
      enabled: process.env.NODE_ENV === 'production' && isProdBranch,
      refetchInterval: intervalInMinutes * 60 * 1000,
    },
  );
  const sha = data?.sha;

  useEffect(() => {
    if (
      sha &&
      process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA &&
      sha !== process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA
    ) {
      setIsNoticeShown(true);
      setTimeout(() => setAnimate(true), 100);
    }
  }, [sha, setAnimate, setIsNoticeShown]);

  if (!isNoticeShown || !isProdBranch) return null;

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        zIndex: (theme) => theme.zIndex.appBar + 10000,
        background: isDarkMode
          ? 'var(--md-sys-color-background-dark)'
          : 'var(--md-sys-color-background-light)',
        transition: '300ms',
        transform: animate ? `translateY(0)` : `translateY(-100%)`,
      }}
    >
      <Typography
        pl={3}
        pr={1}
        pt={1}
        pb={1}
        component='div'
        display='flex'
        alignItems='flex-start'
        justifyContent='space-between'
        sx={{
          background: isDarkMode
            ? applyOpacityOnColor(palette['md.ref.palette.error40'], 0.5)
            : applyOpacityOnColor(palette['md.ref.palette.error80'], 0.5),
        }}
      >
        <Box
          flex={1}
          textAlign='center'
          fontWeight={500}
          fontSize={14}
          style={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          Hey there! We've just released a new update to improve your
          experience. To make sure you have the latest version of our site,
          please refresh your page now.
        </Box>
        <M3IconButton
          sx={{
            width: 30,
            height: 30,
          }}
          onClick={() => {
            setIsNoticeShown(false);
          }}
        >
          <CloseOutlinedIcon style={{ fontSize: 14 }} />
        </M3IconButton>
      </Typography>
    </Box>
  );
};

export default FileVersioningNotice;
