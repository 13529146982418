import { UseQueryOptions } from '@tanstack/react-query';
import { useQueryApi } from './global/useApi';
import { BasicQuery } from '../types/request';
import { useIdentifier } from './global/useIdentifier';
import { defaultReactQueryParams } from '../utils/request';

type UseVercelDeployment = BasicQuery & {
  repo: string;
  branch: string;
};
export function useVercelDeployment<R = { sha: string }>(
  params: UseVercelDeployment,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseVercelDeployment>({
    params,
    baseIdentifier: 'useVercelDeployment',
    identifierKeys: ['repo', 'branch'],
    queryParamKeys: ['repo', 'branch'],
  });

  return useQueryApi<UseVercelDeployment, R>(
    identifiers,
    `/vercel/deployment/${params.repo}/${params.branch}/`,
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
