import React from 'react';
import clsx from 'clsx';
import { Tab, styled, TabProps } from '@mui/material';
import { PaletteTypeButtonProps } from '../../types/app';
import { useAppProvider } from '../../providers/app/app';

export const M3TabButtonBase = styled(Tab)`
  opacity: 1;
  height: 100%;
  transition: none;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 8px;
  text-transform: initial;
  color: var(--md-sys-color-on-surface-light);
  font-weight: 500;
  font-size: 15px;
  font-family: 'Roboto Flex', Roboto, Lato, sans-serif;
  flex-direction: row;
  justify-content: flex-start;

  &:first-of-type {
    margin-left: 0;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 8px;
  }

  &.m3-tab-small {
    height: 32px;
    font-size: 13px;
    min-height: 32px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      var(--md-ref-palette-primary99);
  }

  &.Mui-selected {
    color: var(--md-ref-palette-primary40);
  }

  &.dark-mode-button {
    color: var(--md-sys-color-on-surface-dark);

    &.Mui-selected {
      color: var(--md-ref-palette-primary80);

      .MuiSvgIcon-root {
        color: var(--md-ref-palette-primary80);
      }
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.04)
        ),
        var(--md-ref-palette-primary30);
    }
  }
`;

type M3TabButtonProps = TabProps &
  PaletteTypeButtonProps & {
    size?: 'small' | 'default';
  };
export const M3TabButton = ({
  sx,
  palette: paletteKey = 'main',
  className,
  size = 'default',
  ...restProps
}: M3TabButtonProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <M3TabButtonBase
      sx={{
        ...sx,
      }}
      className={clsx(
        {
          'dark-mode-button': isDarkMode,
          [`m3-tab-${size}`]: !!size,
        },
        className,
      )}
      {...restProps}
    />
  );
};
