import { CSSProperties, FC } from 'react';
import classes from './index.module.css';

export type IconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
  strokeColor?: string;
  wrapperStyle?: CSSProperties;
  style?: CSSProperties;
};

export const withWrapper =
  (Component: FC<IconProps>) =>
  ({ width, height, wrapperStyle, ...restProps }: IconProps) => {
    return (
      <div
        className={classes.wrapper}
        style={{ width, height, ...wrapperStyle }}
      >
        <Component {...restProps} />
      </div>
    );
  };
