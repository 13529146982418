import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormLabel,
  List,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { M3ListItemButton, M3ListItemText } from './M3/M3ListItem';

import { IterableObject, MenuItem, ReactRenderElement } from '../types/types';
import { useCurrentProfile } from '../hooks/profile';
import { UserProfile } from '../types/profile';
import { useAppProvider } from '../providers/app/app';
import feature from '../feature.config';

type Props = {
  header?:
    | {
        icon?: ReactRenderElement;
        label: string;
      }
    | (() => ReactRenderElement);
  menus: MenuItem[];
  menusDependencies?: any[];
  onExpanded?: (isExpanded: SectionExpanded) => void;
};

type SectionExpanded = {
  [key: string]: boolean;
};

const AppDrawerMenuList = ({
  header,
  menus: propsMenus,
  menusDependencies = [],
  onExpanded,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentProfile = useCurrentProfile();
  const { isDarkMode } = useAppProvider();
  const userProfile = currentProfile.data as unknown as UserProfile;

  /**
   * Check if the menu should be visible to the current user
   */
  const isMenuPassed = (menu: MenuItem) => {
    if (menu.restrict) {
      if (menu.restrict.some((role) => userProfile!.roles.indexOf(role) > -1)) {
        return false;
      }
    }

    /**
     * Check if the menu will be visible when feature is enabled
     */
    if (menu.featureKey) {
      return feature[menu.featureKey];
    }

    return menu.roles?.length
      ? menu.roles.some((role) => {
          if (userProfile) {
            return userProfile.roles.indexOf(role) > -1;
          }
          return false;
        })
      : true;
  };

  const menus = useMemo(() => {
    const menuKeyShield: IterableObject = {};
    /**
     * map all the children for filtering the menu for visibility
     */
    const mapMenuChildren = (menu: MenuItem) => {
      if (menuKeyShield[menu.id]) {
        throw new Error(`Menu ID "${menu.id}" exists`);
      }
      menuKeyShield[menu.id] = true;

      if (menu.children?.length) {
        menu.children = menu.children.filter(mapMenuChildren);
      }
      return isMenuPassed(menu);
    };
    let topMenus = [...propsMenus];
    topMenus.forEach(mapMenuChildren);
    topMenus = topMenus.filter(isMenuPassed);

    return topMenus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsMenus, userProfile, ...menusDependencies]);

  const defaultIsSectionExpanded = menus.reduce(
    checkMenusReducer,
    {} as SectionExpanded,
  );

  const [isSectionExpanded, setIsSectionExpanded] = useState<SectionExpanded>(
    defaultIsSectionExpanded,
  );

  function checkMenusReducer(expanded: IterableObject, menu: MenuItem) {
    let keys = isSectionActiveKey(menu);
    keys.forEach((key) => {
      if (typeof expanded[key] !== 'undefined' && expanded[key] !== null) {
        // eslint-disable-next-line no-self-assign
        expanded[key] = expanded[key];
      } else {
        expanded[key] = true;
      }
    });
    return expanded;
  }

  function isSectionActiveKey(menu: MenuItem): string[] {
    let menuKeys: string[] = [];

    const checkMenuActive = (menu: MenuItem, parentMenu?: MenuItem): void => {
      if (menu.active) {
        if (parentMenu) {
          menuKeys.push(parentMenu.id);
        } else {
          menuKeys.push(menu.id);
        }
      }

      if (menu.basePath) {
        if (
          menu.isExact
            ? menu.basePath === location.pathname ||
              menu.paths?.some((p) => p === location.pathname)
            : location.pathname.includes(menu.basePath) ||
              menu.paths?.some((p) => location.pathname.includes(p))
        ) {
          if (parentMenu) {
            menuKeys.push(parentMenu.id);
          } else {
            menuKeys.push(menu.id);
          }
        }
      }

      menu.children?.map((menuChild) => checkMenuActive(menuChild, menu));
    };

    checkMenuActive(menu);

    return Array.from(new Set(menuKeys));
  }

  const renderMenuItem = (
    menu: MenuItem,
    insetCount: number = 1,
  ): ReactRenderElement => {
    const isMenuActive = isSectionActiveKey(menu).indexOf(menu.id) > -1;
    const isActive = menu.active || isMenuActive;

    if (menu.isLabel) {
      return (
        <Typography key={menu.id} component='span'>
          <FormLabel
            key={menu.id}
            sx={{
              ml: 2,
              mb: 0.5,
              opacity: 0.5,
              fontSize: 12,
              fontWeight: 600,
              display: 'block',
              color: 'inherit !important',
              '& > .MuiSvgIcon-root:first-of-type': {
                mr: 1,
              },
            }}
          >
            {menu.icon}
            {menu.name}
          </FormLabel>
        </Typography>
      );
    }

    if (menu.divider) {
      return (
        <Divider
          key={menu.id}
          sx={{
            m: 2,
            mt: 1,
            mb: 1,
            opacity: 0.6,
            borderWidth: 1,
          }}
        />
      );
    }

    if (menu.headline) {
      return (
        <Typography
          key={menu.id}
          component='div'
          display='flex'
          alignItems='center'
          sx={{
            pl: 2,
            mb: 0.3,
            height: 48,
            fontSize: 16,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            '& > .MuiSvgIcon-root:first-of-type': {
              mr: 1,
              fontSize: 24,
            },
          }}
          style={{
            color: isDarkMode
              ? 'var(--md-sys-color-on-background-dark)'
              : undefined,
          }}
        >
          {menu.icon}
          {menu.name}
        </Typography>
      );
    }

    return (
      <Fragment key={menu.id}>
        <M3ListItemButton
          disableRipple
          disableGutters
          selected={isActive}
          collapsable={!!(menu.collapsable || menu.children?.length)}
          onClick={(evt) => {
            if (menu.collapsable && !menu.path) {
              setIsSectionExpanded((s) => ({
                ...s,
                [menu.id]: !s[menu.id],
              }));
            }
            if (menu.path) {
              navigate(menu.path);
            }
            menu.onClick?.(evt, menu);
          }}
          sx={{
            mb: 0.3,
          }}
        >
          <M3ListItemText
            primary={
              <>
                {menu.icon}
                {menu.name}
              </>
            }
            sx={{
              '.MuiListItemText-primary': {
                fontSize: 14,
                color: isDarkMode
                  ? 'var(--md-sys-color-on-background-dark) !important'
                  : undefined,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& > .MuiSvgIcon-root:first-of-type': {
                  mr: 1,
                },
              },
            }}
          />
          {!!menu.secondaryAction && (
            <Box sx={{ pr: 1 }}>{menu.secondaryAction}</Box>
          )}
          {!!menu.collapsable && !!menu.children?.length && (
            <Button
              color='inherit'
              disableRipple
              sx={{
                mr: -1,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={(evt) => {
                evt.stopPropagation();
                setIsSectionExpanded((s) => ({
                  ...s,
                  [menu.id]: !s[menu.id],
                }));
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  opacity: 1,
                  transform: isSectionExpanded[menu.id]
                    ? 'rotateZ(180deg)'
                    : 'rotateZ(0deg)',
                  color: isDarkMode
                    ? 'var(--md-sys-color-on-background-dark) !important'
                    : 'var(--md-sys-color-on-background-light) !important',
                }}
              />
            </Button>
          )}
        </M3ListItemButton>
        {!!menu.children?.length && (
          <Collapse in={isSectionExpanded[menu.id]} timeout={0}>
            <List component='div' disablePadding sx={{ pl: 2 }}>
              {menu.children.map((menuChild) => {
                return renderMenuItem(menuChild, insetCount * 2);
              })}
            </List>
          </Collapse>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    const sectionExpanded = menus.reduce(checkMenusReducer, {
      ...isSectionExpanded,
    });
    setIsSectionExpanded(sectionExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);

  useEffect(() => {
    onExpanded?.(isSectionExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isSectionExpanded).join(',')]);

  return (
    <>
      {typeof header === 'function' ? (
        header()
      ) : header ? (
        <Typography
          display='flex'
          alignItems='center'
          fontWeight={500}
          sx={{
            p: 2,
          }}
        >
          {!!header?.icon && <>{header?.icon}&nbsp;&nbsp;</>}
          {header.label}
        </Typography>
      ) : (
        <Box sx={{ p: 0.5 }} />
      )}
      <Box sx={{ pl: 1, pr: 1 }}>
        {menus.map((menu) => renderMenuItem(menu, 1))}
      </Box>
    </>
  );
};

type AppMenuChipProps = {
  total?: number | null;
};
export const AppMenuChip = ({ total = 0 }: AppMenuChipProps) => {
  if (!total) return null;

  return (
    <Chip
      label={total > 99 ? '99+' : total}
      size='small'
      sx={{
        '.MuiChip-label': {
          fontSize: 14,
          fontWeight: 500,
        },
      }}
      style={{
        background: 'transparent',
      }}
    />
  );
};

export default AppDrawerMenuList;
