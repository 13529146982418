import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import components from './components';
import mixins from './mixins';
import palette from './palette';
import shape from './shape';
import transitions from './transitions';
import typography from './typography';
import zIndex from './zIndex';

const theme = createTheme({
  breakpoints,
  components,
  mixins,
  palette,
  shape,
  transitions,
  typography,
  zIndex,
});

export default theme;
