import React from 'react';
import { Stack, Typography } from '@mui/material';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';

import M3Modal, {
  M3ModalView,
  M3ModalHeader,
  M3ModalBody,
} from '../../../components/M3/M3Modal';
import ActivityTimelinePlayer from './ActivityTimelinePlayer';

type TimezoneModalProps = {
  onClose: () => void;
  open: boolean;
};

const UserTimelineModal: React.FC<TimezoneModalProps> = ({ onClose, open }) => {
  return (
    <>
      <M3Modal open={open} onClose={onClose}>
        <M3ModalView
          onClose={onClose}
          style={{
            maxWidth: 'initial',
          }}
        >
          <M3ModalHeader>
            <Stack direction='row' alignItems='center' gap={2}>
              <OndemandVideoOutlinedIcon style={{ fontSize: 28 }} />
              <Typography component='div' fontSize={18} fontWeight={500}>
                View Activity Playback
              </Typography>
            </Stack>
          </M3ModalHeader>
          <M3ModalBody>
            <ActivityTimelinePlayer />
          </M3ModalBody>
        </M3ModalView>
      </M3Modal>
    </>
  );
};

export default UserTimelineModal;
