import React, { useRef } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Moment } from 'moment';
import { HHMMSSOptionsJSONRet, toHHMMSS } from '../../../utils/date';
import { TimeTrackerWorklogItem } from '../../../types/time-tracker-worklogs';
import {
  getDimensionPositionTimelineLocalDates,
  ItemWithLocalDates,
} from '../../../utils/worklog';

type Props = {
  items: TimeTrackerWorklogItemWithLocal[];
  dimensions: { width: number; height: number };
  selectedFilteredDate: Moment;
};

type TimeTrackerWorklogItemWithLocal = TimeTrackerWorklogItem &
  ItemWithLocalDates;

const WorklogsStack = ({ items = [], selectedFilteredDate }: Props) => {
  const stackRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box display='flex' alignItems='center' flex={1}>
      <Box
        display='flex'
        alignItems='center'
        style={{
          width: 30,
          marginLeft: -30,
        }}
      >
        <AccessTimeOutlinedIcon style={{ fontSize: 18, opacity: 0.5 }} />
      </Box>
      <Stack
        ref={stackRef}
        direction='row'
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {items.map((item, index, arr) => {
          let { startPos, endPos, duration, startDateLocal, endDateLocal } =
            getDimensionPositionTimelineLocalDates({
              element: stackRef.current,
              startDateLocal: item.start_local,
              endDateLocal: item.end_local,
            });

          const hms = toHHMMSS(duration, {
            minDigit: 1,
            hrDigit: 1,
            secDigit: 1,
            json: true,
          }) as HHMMSSOptionsJSONRet;
          const { hours, minutes } = hms;

          const tooltipTitle = (
            <>
              <span className='tooltip-duration'>
                {`${hours}h ${minutes}m`.trim()}
                {!!item.notes && (
                  <span
                    style={{
                      fontSize: 10,
                      opacity: 0.5,
                      paddingLeft: 4,
                      fontWeight: 400,
                    }}
                  >
                    (edited)
                  </span>
                )}
              </span>
              <br />
              <span className='tooltip-task-name'>{item.task_name}</span>
              <br />
              <span className='tooltip-start-end'>
                {startDateLocal.format('hh:mm A')} -{' '}
                {endDateLocal ? endDateLocal.format('hh:mm A') : 'ongoing'}
              </span>
            </>
          );

          return (
            <Box
              key={index}
              style={{
                top: 0,
                left: startPos,
                width: endPos,
                height: '100%',
                position: 'absolute',
                background: 'var(--md-ref-palette-neutral-variant40)',
              }}
            >
              <Tooltip title={tooltipTitle}>
                <Box
                  style={{
                    height: '100%',
                  }}
                />
              </Tooltip>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default WorklogsStack;
