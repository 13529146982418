import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Slider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useRefAssign } from '../../hooks/global/useRefAlwaysUpdated';
import VolumeControl from './VolumeControl';

type AudioPlayerProps = {
  src: string;
  autoplay?: boolean;
  onPause?: () => void;
  onPlay?: () => void;
};

const iconStyle: CSSProperties = {
  cursor: 'pointer',
  opacity: 0.8,
};

const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const AudioPlayer = ({ autoplay, src, onPause, onPlay }: AudioPlayerProps) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRefAssign(isPlaying);

  const audioRef = useRef<HTMLAudioElement>();

  /**
   * Plays the audio element
   */
  const playAudio = useCallback(async () => {
    try {
      await audioRef.current?.play();
    } catch (e) {
      console.log(e);
    }
  }, [audioRef]);

  /**
   * Pause the audio element
   */
  const pauseAudio = useCallback(() => {
    audioRef.current?.pause();
  }, [audioRef]);

  /**
   * Sets the audio element current time. Good to set after seeking.
   */
  const setAudioCurrenTime = useCallback(
    (value: number) => {
      if (audioRef.current) {
        audioRef.current.currentTime = value;
      }
    },
    [audioRef],
  );

  /**
   * Sets the total duration
   */
  const setAudioDuration = useCallback(
    () => setDuration(audioRef.current?.duration ?? 0),
    [audioRef, setDuration],
  );

  /**
   * Sets the audios current time when audio is playing
   */
  const setAudioTimeUpdate = useCallback(
    (evt: Event) => {
      let audio = (evt.target || evt.currentTarget) as HTMLAudioElement;
      let currentTime = (audio || audioRef.current).currentTime ?? 0;

      if (isPlayingRef.current) {
        setCurrentTime(currentTime);
      }
    },
    [audioRef, isPlayingRef, setCurrentTime],
  );

  /**
   * Handle when pressing the Pause button
   */
  const handleOnPause = useCallback(() => {
    pauseAudio();
    setIsPlaying(false);
    onPause?.();
  }, [onPause, pauseAudio, setIsPlaying]);

  /**
   * Loads the audio initially, and add the listeners
   */
  const loadAudio = useCallback(() => {
    let audio = audioRef.current || new Audio(src);
    audioRef.current = audio;

    // remove event listeners
    audio.removeEventListener('loadeddata', setAudioDuration);
    audio.removeEventListener('timeupdate', setAudioTimeUpdate);
    audio.removeEventListener('ended', handleOnPause);

    // Add event listeners
    audio.addEventListener('loadeddata', setAudioDuration);
    audio.addEventListener('timeupdate', setAudioTimeUpdate);
    audio.addEventListener('ended', handleOnPause);
  }, [src, audioRef, setAudioDuration, setAudioTimeUpdate, handleOnPause]);

  /**
   * handle when pressing the Play button
   */
  const handleOnPlay = useCallback(() => {
    loadAudio();
    playAudio();
    setIsPlaying(true);
    onPlay?.();
  }, [onPlay, loadAudio, playAudio, setIsPlaying]);

  /**
   * handle when seeker change, when user press the switch and drag it
   */
  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      handleOnPause();
      setCurrentTime(newValue as number);
    },
    [setCurrentTime, handleOnPause],
  );

  /**
   * Handle when user release the switch
   */
  const handleSliderChangeEnd = useCallback(
    (event: SyntheticEvent | Event, newValue: number | number[]) => {
      setCurrentTime(newValue as number);
      setAudioCurrenTime(newValue as number);
      handleOnPlay();
    },
    [setCurrentTime, setAudioCurrenTime, handleOnPlay],
  );

  const handleOnVolumeChange = useCallback(
    (volume: number) => {
      const audio = audioRef.current;
      if (audio) {
        audio.volume = volume;
      }
    },
    [audioRef],
  );

  /**
   * When player is not playing, pause the audio
   */
  useEffect(() => {
    if (!isPlaying) {
      pauseAudio();
    }
  }, [isPlaying, pauseAudio]);

  /**
   * When it's unmounted pause the video
   */
  useEffect(() => {
    return pauseAudio;
  }, [pauseAudio]);

  useEffect(() => {
    autoplay && handleOnPlay();
  }, [autoplay, handleOnPlay]);

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        gap={1}
        sx={{
          display: 'flex',
          userSelect: 'none',
          alignItems: 'center',
        }}
      >
        {isPlaying ? (
          <PauseIcon onClick={handleOnPause} style={iconStyle} />
        ) : (
          <PlayArrowIcon onClick={handleOnPlay} style={iconStyle} />
        )}
        <Box
          style={{
            width: 90,
            marginRight: 8,
            textAlign: 'right',
          }}
        >
          <span style={{ opacity: 0.5 }}>{formatTime(currentTime)}</span> /{' '}
          <span style={{ opacity: 0.8 }}>{formatTime(duration)}</span>
        </Box>
        <Box
          style={{
            flex: 1,
            top: -14,
            maxWidth: 300,
            position: 'relative',
          }}
        >
          <Slider
            size='small'
            min={0}
            max={duration}
            value={currentTime}
            disabled={!duration}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeEnd}
            style={{
              top: 0,
              left: 0,
              right: 0,
              position: 'absolute',
            }}
          />
        </Box>
        <VolumeControl onVolumeChange={handleOnVolumeChange} />
      </Box>
    </Box>
  );
};

export default AudioPlayer;
