import { useEffect, useRef } from 'react';

type Props = {
  handler?: HTMLDivElement | Window;
  threshold?: number;
  onReachBottom?: () => void;
  children?: any;
};

const InfiniteScrollListener = ({
  handler = window,
  threshold = 10,
  onReachBottom,
  children,
}: Props) => {
  const onReachBottomRef = useRef(onReachBottom);
  onReachBottomRef.current = onReachBottom;

  // NOTE: Do not allow 0 or negative threshold value
  threshold = threshold < 10 ? 10 : threshold;

  useEffect(() => {
    let localHandler = handler;
    const onScroll = () => {
      if (!localHandler) return;

      if (localHandler === window) {
        if (
          localHandler.innerHeight + localHandler.scrollY >=
          document.body.offsetHeight - threshold
        ) {
          onReachBottomRef.current?.();
        }
      } else {
        localHandler = localHandler as HTMLDivElement;
        const offsetHeight = localHandler.offsetHeight;
        const scrollTop = localHandler.scrollTop;
        const scrollHeight = localHandler.scrollHeight;

        // check if it's reaches the bottom
        if (scrollTop && offsetHeight + scrollTop >= scrollHeight - threshold) {
          onReachBottomRef.current?.();
        }
      }
    };

    localHandler?.addEventListener('scroll', onScroll, false);

    return () => {
      localHandler?.removeEventListener('scroll', onScroll, false);
    };
  }, [handler, threshold, onReachBottomRef]);

  return children ?? null;
};

export default InfiniteScrollListener;
