import React, { CSSProperties, useCallback, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';

import DataTable from '../../components/DataTable/DataTable';
import { ActivityApplicationItem } from '../Activities/types';

import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import {
  getDurationSeconds,
  HHMMSSOptionsJSONRet,
  toHHMMSS,
} from '../../utils/date';

type UserActivityLogsProps = {
  withChart?: boolean;
  isLoading?: boolean;
  items: ActivityApplicationItem[];
};

const activityAppFieldLabelMap: {
  [K in keyof Partial<ActivityApplicationItem>]: string;
} = {
  duration: 'Duration',
  url: 'URL',
  exec: 'Application',
  task_name: 'Focus',
};

const agentFieldStyleMap: {
  [K in keyof Partial<ActivityApplicationItem>]: CSSProperties;
} = {
  start: {
    width: 240,
    minWidth: 240,
  },
  end: {
    width: 240,
    minWidth: 240,
  },
  exec: {
    width: 300,
    minWidth: 300,
  },
  duration: {
    width: 200,
    minWidth: 200,
  },
  title: {
    width: 400,
    minWidth: 400,
  },
  url: {
    width: 600,
    minWidth: 600,
  },
  go2_version: {
    width: 180,
    minWidth: 180,
  },
  task_name: {
    width: 300,
    minWidth: 300,
  },
};

const defaultAgentFields: (keyof ActivityApplicationItem)[] = [
  'start',
  'end',
  'duration',
  'task_name',
  'go2_version',
  'exec',
  'url',
  'title',
];

const defaultActiveField: keyof ActivityApplicationItem = 'timestamp';

const UserActivityLogs = ({
  withChart = true,
  items,
  isLoading,
}: UserActivityLogsProps) => {
  const [filters] = useState<{
    active: keyof ActivityApplicationItem;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  // const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey] = useState(0);

  const [selectedFields] = useState<(keyof ActivityApplicationItem)[]>([]);

  const handleColumnItem = useCallback(
    (field: keyof ActivityApplicationItem, item: ActivityApplicationItem) => {
      if (field === 'duration') {
        let duration = getDurationSeconds(item.end, item.start);
        const hms = toHHMMSS(duration, {
          minDigit: 1,
          hrDigit: 1,
          secDigit: 1,
          msDigit: 1,
          json: true,
        }) as HHMMSSOptionsJSONRet;
        const { hours, minutes, seconds } = hms;

        return `${hours ? ` ${hours}h ` : ''}${minutes}m ${seconds}s`;
      }

      if (field === 'start' || field === 'end') {
        return (
          <Stack direction='row' gap={1}>
            {field === 'start' && (
              <MonitorOutlinedIcon
                style={{
                  fontSize: 20,
                  opacity: 0.5,
                  marginTop: 1,
                }}
              />
            )}
            {`${moment.utc(item[field]).local().format('MMM D, YYYY hh:mma')}`}
          </Stack>
        );
      }

      if (field === 'url' && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).href;
        } catch (e) {}
        return (
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className='m3-button-link'
          >
            {item[field]}
          </a>
        );
      }

      return item[field];
    },
    [],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof ActivityApplicationItem) =>
      activityAppFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  /*
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof ActivityApplicationItem, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  */
  /*
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof ActivityApplicationItem)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );
  */

  // const dataTablePagination = useDataTablePagination({
  //   defaultSize: 25,
  //   items: items,
  // });

  return (
    <>
      {/* {withChart && (
        <ActivityChart
          chartSx={{
            height: 500,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        />
      )} */}
      <Divider style={{ borderWidth: 1, width: '100%' }} />
      {/* <Stack direction='row' alignItems='center'>
        <Typography component='div'></Typography>
        <Box display='flex' justifyContent='flex-end' gap={1} flex={1} pr={1}>
          <Tooltip title='Download'>
            <Box>
              <M3IconButton>
                <DownloadOutlinedIcon />
              </M3IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Stack> */}
      <Typography component='div'>
        <DataTable
          key={dataTableKey}
          orderBy={filters.order}
          // activeField={filters.active}
          fields={selectedFields.length ? selectedFields : defaultAgentFields}
          data={items}
          loadingCount={10}
          isLoading={isLoading}
          fieldColumnStyleMap={agentFieldStyleMap}
          handleColumnItem={handleColumnItem}
          handleHeaderColumnItem={handleHeaderColumnItem}
          // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
        />
      </Typography>
    </>
  );
};

export default UserActivityLogs;
