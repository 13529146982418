import { useEffect } from 'react';

type Props = {
  handler?: Window | HTMLElement | null;
  deps?: any[];
};

const WindowScrollTop = ({ handler = window, deps = [] }: Props) => {
  useEffect(() => {
    if (handler === window) {
      handler.scrollTo(0, 0);
    } else if (handler) {
      (handler as HTMLElement).scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, ...deps]);

  return null;
};

export default WindowScrollTop;
