import { useEffect, useRef, useState } from 'react';
import { request as cosmosRequest } from '../../services/cosmos';
import { useAuthProvider } from '../../providers/auth/auth';
import { IterableObject } from '../../types/types';
import { toURL } from '../../utils/url';
import { getConfigWithAuthorization } from '../../services/base';

export type UseCosmosPayload<P> = {
  enabled?: boolean;
  endpoint: string;
  deps: any[];
  params?: P;
};

export type UseCosmosHandlers<R> = {
  onSuccess?: (result: R) => void;
};

export type UseCosmosRet = {
  isLoading: boolean;
  isSuccess: boolean;
  error: Error | null;
  refetch: () => void;
};

export function useCosmos<P = any, R = any>(
  { enabled = true, endpoint, deps = [], params }: UseCosmosPayload<P>,
  { onSuccess }: UseCosmosHandlers<R>,
): UseCosmosRet {
  const { getTokenSilently } = useAuthProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const cachePayloadStateRef = useRef<IterableObject<boolean>>({});

  async function fetchApi() {
    const fetchKey = JSON.stringify(params);

    try {
      // check if there's already an ongoing request
      if (cachePayloadStateRef.current[fetchKey] || !enabled) return;

      cachePayloadStateRef.current[fetchKey] = true;

      setIsLoading(true);
      setIsSuccess(false);
      setError(null);

      const result: R = await cosmosRequest.get(
        toURL(endpoint, params),
        getConfigWithAuthorization(await getTokenSilently()),
      );

      cachePayloadStateRef.current[fetchKey] = false;

      setIsLoading(false);
      setIsSuccess(true);
      onSuccess?.(result);
    } catch (e) {
      cachePayloadStateRef.current[fetchKey] = false;

      setIsLoading(false);
      setError(e as Error);
    }
  }

  const refetch = () => fetchApi();

  useEffect(() => {
    fetchApi();
    // eslint-disable-next-line
  }, [enabled, ...deps]);

  return {
    isLoading,
    isSuccess,
    error,
    refetch,
  };
}
