import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  PaperProps,
  Popover,
  PopoverProps,
  Typography,
} from '@mui/material';

import { M3Autocomplete, M3AutocompleteProps } from '../M3/M3Autocomplete';
import { M3TextField } from '../M3/M3TextField';
import { M3MenuItem } from '../M3/M3MenuItem';

import { useAppProvider } from '../../providers/app/app';

export type BasicPopoverWithSearchProps = {
  open: boolean;
  withSearch?: boolean;
  loading?: boolean;
  placeholder?: string;
  anchorEl: HTMLElement | null;
  options: M3OptionItem[];
  selected?: M3OptionItem | null;
  onClose?: () => void;
  onSelect?: (item: M3OptionItem) => void;
  onChange?: M3AutocompleteProps<M3OptionItem>['onChange'];
  renderOption?: M3AutocompleteProps<M3OptionItem>['renderOption'];
  customRenderOption?: (props: CustomRenderOptionProps) => any;
  popoverProps?: {
    anchorOrigin?: PopoverProps['anchorOrigin'];
    transformOrigin?: PopoverProps['transformOrigin'];
  };
  paperProps?: PaperProps;
  maxRowVisible?: number;
};
export type M3OptionItem<T = any> = {
  id: number | string;
  label: string;
  props?: T;
};
export type CustomRenderOptionProps = {
  key: string | number;
  option: M3OptionItem;
  active: boolean;
  onSelect?: BasicPopoverWithSearchProps['onSelect'];
};

type BasicPopoverProps = {
  isOpen: boolean;
};
export function useBasicPopover<T = HTMLElement>() {
  const ref = useRef<T | null>(null);
  const [state, setState] = useState<BasicPopoverProps>({
    isOpen: false,
  });

  const openPopover = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: true,
    }));
  }, []);

  const closePopover = useCallback(() => {
    setState((state) => ({
      ...state,
      isOpen: false,
    }));
  }, []);

  const ret = useMemo(() => {
    return {
      ...state,
      ref,
      open: openPopover,
      close: closePopover,
    };
  }, [state, openPopover, closePopover, ref]);

  return ret;
}

const menuItemHeight = 42;
const menuInputHeight = 50;
const BasicPopoverWithSearch = ({
  open,
  loading,
  anchorEl,
  placeholder,
  onClose,
  options,
  selected,
  renderOption,
  customRenderOption,
  popoverProps,
  onSelect,
  paperProps,
  withSearch = true,
  maxRowVisible = 5,
}: BasicPopoverWithSearchProps) => {
  const { isDarkMode } = useAppProvider();
  const gap = 4;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      transitionDuration={0}
      onClose={onClose}
      anchorOrigin={{
        vertical: 0,
        horizontal: -8,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        ...paperProps,
        style: {
          width: 240,
          overflow: 'hidden',
          ...paperProps?.style,
        },
      }}
      {...popoverProps}
    >
      <Box
        sx={{
          height:
            menuItemHeight * options.length +
            menuItemHeight +
            gap * 2 -
            (withSearch ? 0 : menuInputHeight),
          maxHeight:
            menuItemHeight * maxRowVisible +
            menuItemHeight +
            gap * 2 -
            (withSearch ? 0 : menuInputHeight),
          '.MuiAutocomplete-listbox': {
            padding: 0,
            width: '100%',
            overflow: options.length > maxRowVisible ? 'hidden auto' : 'hidden',
            maxHeight: menuItemHeight * maxRowVisible,
          },
          '.MuiPaper-root': {
            borderRadius: 0,
            boxShadow: 'none !important',
          },
          ...(!withSearch
            ? {
                '.MuiAutocomplete-root': {
                  left: 0,
                  right: 0,
                  position: 'absolute',
                },
                '.MuiAutocomplete-popper': {
                  // opacity: 0,
                  transform: 'none !important',
                },
              }
            : null),
        }}
      >
        <M3Autocomplete
          open
          disablePortal
          options={options}
          renderInput={(params) => {
            return (
              <M3TextField
                {...params}
                fullWidth
                autoFocus
                placeholder={placeholder ?? 'Search...'}
                InputLabelProps={{
                  focused: true,
                }}
                sx={{
                  height: withSearch ? menuInputHeight : 0,
                  opacity: withSearch ? 1 : 0,
                  visibility: withSearch ? undefined : 'hidden',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderRadius: 0,
                    border: '0 !important',
                  },
                  '.MuiInputBase-root': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    background: `${
                      isDarkMode
                        ? 'var(--md-ref-palette-neutral20)'
                        : 'var(--md-ref-palette-neutral90)'
                    } !important`,
                  },
                }}
              />
            );
          }}
          renderOption={(_, option: M3OptionItem) => {
            return customRenderOption
              ? customRenderOption({
                  key: option.id,
                  option: option,
                  active: option.id === selected?.id,
                  onSelect: onSelect,
                })
              : renderOption ?? (
                  <BasicItemOption
                    key={option.id}
                    option={option}
                    active={option.id === selected?.id}
                    onSelect={onSelect}
                  />
                );
          }}
          loading={loading}
          loadingText={<BasicItemNotFound label='Loading...' />}
          noOptionsText={<BasicItemNotFound />}
          sx={{
            '.MuiInputBase-formControl': {
              pt: 0,
              pb: 0,
            },
          }}
        />
      </Box>
    </Popover>
  );
};

export default BasicPopoverWithSearch;

type BasicItemOptionProps = PropsWithChildren & {
  option: M3OptionItem;
  active?: boolean;
  onSelect?: BasicPopoverWithSearchProps['onSelect'];
  height?: number;
};
export function BasicItemOption({
  option,
  active,
  onSelect,
  children,
  height,
}: BasicItemOptionProps) {
  return (
    <M3MenuItem
      data-id={option.id}
      active={active}
      sx={{ height: height || menuItemHeight }}
      onClick={() => onSelect?.(option)}
    >
      {children ? (
        children
      ) : (
        <Typography
          flex={1}
          width={0}
          fontSize={14}
          component='span'
          lineHeight={1.3}
          title={option.label}
          whiteSpace='nowrap'
          className='text-truncate'
        >
          {option.label}
        </Typography>
      )}
    </M3MenuItem>
  );
}

type BasicItemNotFoundProps = {
  label?: string;
};
export function BasicItemNotFound({ label }: BasicItemNotFoundProps) {
  return (
    <Typography
      top={-6}
      fontSize={14}
      component='span'
      lineHeight={1.3}
      position='relative'
    >
      {label ?? 'No Matches Found'}
    </Typography>
  );
}
