import React, { useCallback, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { faker } from '@faker-js/faker';

import TimelinePlayer, {
  TimelineMediaSource,
} from './TimelinePlayer/TimelinePlayer';
import TimelineFilters from './TimelineFilters';
import ActivityKeystrokes from './ActivityKeystrokes';

import { IterableObject } from '../../../types/types';
import { sortBy } from '../../../utils/array';

type Props = {};

const videoLinks = [
  {
    src: '/videos/1.mp4',
    duration: 60,
  },
  {
    src: '/videos/2.mp4',
    duration: 55,
  },
  {
    src: '/videos/3.mp4',
    duration: 21,
  },
  {
    src: '/videos/4.mp4',
    duration: 171,
  },
  {
    src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    duration: 596,
  },
];
const audioLinks = [
  {
    // src: '/audios/1.m4a',
    src: 'https://samplelib.com/lib/preview/mp3/sample-15s.mp3',
    duration: 60,
  },
  {
    // src: '/audios/2.m4a',
    src: 'https://samplelib.com/lib/preview/mp3/sample-15s.mp3',
    duration: 55,
  },
  {
    // src: '/audios/3.m4a',
    src: 'https://samplelib.com/lib/preview/mp3/sample-15s.mp3',
    duration: 21,
  },
  {
    // src: '/audios/4.m4a',
    src: 'https://samplelib.com/lib/preview/mp3/sample-15s.mp3',
    duration: 171,
  },
  {
    src: 'https://samplelib.com/lib/preview/mp3/sample-15s.mp3',
    duration: 596,
  },
];
const generateNonOverlappingVideos = (
  count: number,
): TimelineMediaSource<'video'>[] => {
  const videos: TimelineMediaSource<'video'>[] = [];
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0); // Start of today in UTC
  let lastEndTime = today.getTime();

  for (let i = 0; i < count; i++) {
    // const duration = faker.number.int({ min: 200, max: VIDEO_MAX_DURATION });
    // Gap between 5 minutes and 1 hour in milliseconds
    const timestamp = new Date(
      lastEndTime + faker.number.int({ min: 300000, max: 3600000 }),
    );

    videos.push({
      id: uuid(),
      duration: videoLinks[i].duration,
      src: videoLinks[i].src,
      // Store as ISO string for UTC date
      timestamp: timestamp.toISOString(),
      color: faker.color.rgb(),
      type: 'video',
    });

    // Convert duration to milliseconds
    lastEndTime = timestamp.getTime() + videoLinks[i].duration * 1000;
  }

  return sortBy(videos, 'timestamp', true, 'ASC', 'date_string');
};

const ActivityTimelinePlayer = (props: Props) => {
  const [dataTypeFilters, setDataTypeFilters] = useState<
    IterableObject<boolean>
  >({
    activity: true,
    keystrokes: true,
  });

  const onDataTypeFilterChange = useCallback(
    (name: string, value: boolean) =>
      setDataTypeFilters((state) => ({ ...state, [name]: value })),
    [setDataTypeFilters],
  );

  const videos = useMemo(
    () => generateNonOverlappingVideos(videoLinks.length),
    [],
  );
  const audios = useMemo(() => {
    return videos.map((v, i) => ({
      ...v,
      src: audioLinks[i].src,
      type: 'audio',
    })) as TimelineMediaSource<'audio'>[];
  }, [videos]);

  return (
    <Box>
      <Stack direction='column'>
        <Box>
          <TimelinePlayer
            ratio='21:9'
            videoSource={videos}
            audioSource={audios}
          />
          {/* <VideoPlayer
            key={current.id}
            autoPlay
            ratio='21:9'
            src={current.src}
          /> */}
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          style={{
            minHeight: '40vh',
          }}
        >
          <Box style={{ paddingTop: 4 }}>
            <TimelineFilters
              dataTypeFilters={dataTypeFilters}
              onDataTypeFilterChange={onDataTypeFilterChange}
            />
          </Box>
          <ActivityKeystrokes dataTypeFilters={dataTypeFilters} />
        </Box>
      </Stack>
    </Box>
  );
};

export default ActivityTimelinePlayer;
