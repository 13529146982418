import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useParams,
  Location,
  useNavigate,
} from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Box, CircularProgress, Stack, Tooltip } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import DocumentTitle from '../../components/DocumentTitle';
import WindowScrollTop from '../../components/WindowScrollTop';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import MainContainer from '../../components/MainContainer';
import UserMetadataView from './UserMetadata';
import UserTabs, {
  TabButtonParamItem,
  TabButtonParamItemNames,
} from './UserTabs';
import UserActivityLogs from './UserActivityLogs';
import UserSessionLogs from './UserSessionLogs';
import UserTimeWorked from './UserTimeWorked';
import UserSnapshots from './UserSnapshots';
import UserOnlineMeetings from './UserOnlineMeetings';
import UserKeystrokes from './UserKeystrokes';
import UserNetworkMonitoring from './UserNetworkMonitoring';
import UserAudioRecordings from './UserAudioRecordings';
import UserFilters from './UserFilters';
import ActivityChart from '../Activities/ActivityChart';
import { M3IconButton } from '../../components/M3/M3Button';
import M3SurfaceContainer from '../../components/M3/M3SurfaceContainer';
import InfiniteScrollListener from '../../components/InfiniteScrollListener';
import { useTimezone } from '../../components/Util/Timezone';
import UserTimeline from './UserTimeline/UserTimeline';
import { UserDetailFilterParams } from './UserTimeline/_types';

import { AgentUser } from '../Users/types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { useUserProvider } from '../../providers/user/user';
import { UserMetadata } from '../../types/profile';
import { getUserDisplayName } from '../../utils/user';
import { useInfinite } from '../../hooks/global/useInfinite';
import { ActivityApplicationItem } from '../Activities/types';
import {
  useActivities,
  UseActivitiesProps,
} from '../../hooks/go2-agent/activities';
import { useKeystrokes } from '../../hooks/go2-agent/keystroke';
import { useUserSystemInfo } from '../../hooks/go2-agent/system-info';
import { fetchStartDownloadToCSV } from '../../utils/download';
import { getConfigWithAuthorization } from '../../services/base';
import { guessTimezone } from '../../utils/timezone';
import { useAuthProvider } from '../../providers/auth/auth';
import { toURL } from '../../utils/url';
import { useMetadataProvider } from '../../providers/metadata/metadata';

type UserDetailProps = {};

type UserDetailParams = {
  userId: string;
  tabName: TabButtonParamItemNames;
};

type UserDetailLocation = Location<null | {
  item: AgentUser;
  user: UserMetadata;
}>;

const UserDetail = (props: UserDetailProps) => {
  const navigate = useNavigate();
  const timezone = useTimezone();
  const { userId, tabName } = useParams<UserDetailParams>();
  const { state, pathname } = useLocation() as UserDetailLocation;
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();
  const { getTokenSilently } = useAuthProvider();
  const { metabase } = useMetadataProvider();

  let item: AgentUser | null = state?.item ?? null;
  let user = getUser('user_id', userId) ?? state?.user;

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<UserDetailFilterParams>(
    () => ({
      start_date: moment().startOf('day'),
      end_date: moment().endOf('day'),
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<UserDetailFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // Tabs ---------------------------------------------------------------------
  const [tabIndexName, setTabIndexName] = useState<TabButtonParamItemNames>(
    tabName ?? 'activity',
  );
  const onTabIndexHandleChange = useCallback(
    (tabParams: TabButtonParamItem, index: number) => {
      setTabIndexName(tabParams.name);
      navigate(`/users/${userId}/${tabParams.name}`, {
        state: {
          item,
          user,
        },
      });
    },
    [userId, user, item, setTabIndexName, navigate],
  );

  // System Info ---------------------------------------------------------------
  const { data: userSystemInfo } = useUserSystemInfo(
    { user_id: userId! },
    { enabled: !!userId },
  );

  // Activities ----------------------------------------------------------------
  const activityLimit = 100;
  const infiniteActivities = useInfinite<
    ActivityApplicationItem,
    UseActivitiesProps
  >({
    isFlatResult: false,
    skipFetchOnInit: true,
    useQuery: useActivities,
    queryParams: {
      start_date: filterParams.start_date.clone().utc().format(),
      end_date: filterParams.end_date.clone().utc().format(),
      user_ids: userId,
      limit: activityLimit,
      order_by: 'start_time ASC',
    },
  });

  // Keystrokes ----------------------------------------------------------------
  const keystrokeLimit = 100;
  const infiniteKeystrokes = useInfinite<any, any>({
    isFlatResult: false,
    skipFetchOnInit: true,
    useQuery: useKeystrokes,
    queryParams: {
      start_date: filterParams.start_date.clone().utc().format(),
      end_date: filterParams.end_date.clone().utc().format(),
      user_ids: userId,
      limit: keystrokeLimit,
      order_by: 'timestamp ASC',
    },
  });

  // Charts --------------------------------------------------------------------
  const activityChartParams = useMemo(
    () => ({
      start_date: filterParams.start_date.format('YYYY-MM-DD'),
      end_date: filterParams.end_date.format('YYYY-MM-DD'),
      division: [],
      user_id: [userId?.toString()],
      timezone,
    }),
    [timezone, userId, filterParams.start_date, filterParams.end_date],
  );

  // User Timeline Worklogs ----------------------------------------------------
  const [selectedFilteredDate, setSelectedFilteredDate] = useState<Moment>(() =>
    filterParams.end_date.clone(),
  );
  const prevNextSelectedFilteredDate = useCallback(
    (action: 'next' | 'previous') => {
      let date = selectedFilteredDate.clone();
      date[action === 'next' ? 'add' : 'subtract'](1, 'day');
      setSelectedFilteredDate(date);
      updateFilterParams({
        start_date: date.clone().startOf('day'),
        end_date: date.clone().endOf('day'),
      });
    },
    [selectedFilteredDate, setSelectedFilteredDate, updateFilterParams],
  );

  const [isDownloading, setIsDownloading] = useState(false);
  const handleOnDownloadClick = useCallback(async () => {
    if (tabName !== 'activity' && tabName !== 'keystrokes') return;

    let url = '';
    let filename = '';
    switch (tabName) {
      case 'activity': {
        filename = `${userId}_activity_${filterParams.start_date.format(
          'YYYY-MM-DD',
        )}`;
        url = toURL(
          `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/activity/download`,
          {
            start_date: filterParams.start_date.clone().utc().format(),
            end_date: filterParams.end_date.clone().utc().format(),
            user_ids: userId,
            order_by: 'start_time ASC',
          },
        );
        break;
      }
      case 'keystrokes': {
        filename = `${userId}_keystroke_${filterParams.start_date.format(
          'YYYY-MM-DD',
        )}`;
        url = toURL(
          `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/keystroke/download`,
          {
            start_date: filterParams.start_date.clone().utc().format(),
            end_date: filterParams.end_date.clone().utc().format(),
            user_ids: userId,
            order_by: 'timestamp ASC',
          },
        );
        break;
      }
      default:
        break;
    }

    try {
      setIsDownloading(true);
      await fetchStartDownloadToCSV(
        url,
        {
          headers: {
            ...getConfigWithAuthorization(await getTokenSilently()).headers,
            ['x-timezone']: guessTimezone(), // eslint-disable-line
          },
        },
        {
          filename,
        },
      );
    } finally {
      setIsDownloading(false);
    }
  }, [userId, tabName, setIsDownloading, filterParams, getTokenSilently]);

  /**
   * When filter changes, it resets the activities
   */
  useEffect(() => {
    if (!user) return;

    if (tabName === 'activity') {
      infiniteActivities.reset({
        emptyList: true,
      });
    } else if (tabName === 'keystrokes') {
      infiniteKeystrokes.reset({
        emptyList: true,
      });
    }
    // eslint-disable-next-line
  }, [filterParams, tabName, user]);

  /**
   * Set's the active tab index when it changes
   */
  useEffect(() => {
    tabName && tabIndexName !== tabName && setTabIndexName(tabName);
  }, [pathname, tabName, tabIndexName, setTabIndexName]);

  /**
   * Fetches the user's metadata
   */
  useEffect(() => {
    if (users[userId!]) return;

    setUniqueIdsToFetch({
      user_ids: [userId],
    });
  }, [userId, user, users, setUniqueIdsToFetch]);

  /**
   * When the main control date range is changed.
   * Let's reset the selected date
   */
  useEffect(() => {
    setSelectedFilteredDate(filterParams.end_date.clone());
  }, [filterParams.start_date, filterParams.end_date, setSelectedFilteredDate]);

  return (
    <>
      <DocumentTitle
        title={toTitleCase(stripToFormatterWord(tabIndexName))}
        trailingTitle='Users'
      />
      <WindowScrollTop deps={[userId]} />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppBreadcrumbs
          items={[
            {
              id: 'users',
              label: 'Users',
              path: '/users',
            },
            {
              id: (user ? user.id : userId)?.toString() || 'unknown',
              label: user ? getUserDisplayName(user).fullName : userId,
              path: user ? `/users/${userId}` : '',
            },
          ]}
        />
        <Box display='flex' alignItems='stretch' gap={4}>
          <UserMetadataView
            item={item}
            user={user}
            userId={userId}
            userSystemInfo={userSystemInfo}
          />
          <Box flex={1} width={0}>
            <M3SurfaceContainer elevation={1}>
              <Box>
                <UserFilters
                  withDownload={
                    tabName === 'activity' || tabName === 'keystrokes'
                  }
                  isDownloading={isDownloading}
                  filterParams={filterParams}
                  selectedFilteredDate={selectedFilteredDate}
                  updateFilterParams={updateFilterParams}
                  handleOnDownloadClick={handleOnDownloadClick}
                />
                <Box gap={3} display='flex' flexWrap='wrap' px={3} mt={-2}>
                  <ActivityChart
                    title='Apps/Domains'
                    metabaseProps={{
                      url: '/embed/question/',
                      key: 'question',
                      id: metabase.charts.activities.top_apps_website,
                      params: activityChartParams,
                    }}
                  />
                  <ActivityChart
                    title='Keystrokes'
                    metabaseProps={{
                      url: '/embed/question/',
                      key: 'question',
                      id: metabase.charts.keystrokes.top_apps_website,
                      params: activityChartParams,
                    }}
                  />
                  <ActivityChart
                    title='Login Sessions'
                    metabaseProps={{
                      url: '/embed/question/',
                      key: 'question',
                      id: metabase.charts.login_sessions.hourly,
                      params: activityChartParams,
                    }}
                  />
                </Box>
                <br />
                <UserTimeline
                  userId={+userId!}
                  filterParams={filterParams}
                  selectedFilteredDate={selectedFilteredDate}
                  prevNextSelectedFilteredDate={prevNextSelectedFilteredDate}
                />
                <br />
                <Stack direction='row' justifyContent='space-between'>
                  <UserTabs
                    tabIndexName={tabIndexName}
                    handleOnTabChange={onTabIndexHandleChange}
                  />
                  <Box
                    display='flex'
                    justifyContent='flex-end'
                    gap={1}
                    flex={1}
                    px={2}
                  >
                    {/* <Tooltip title='Refresh'>
                    <Box>
                      <M3IconButton onClick={() => {}}>
                        <RefreshOutlinedIcon
                        // className={infiniteActivities.isLoading ? 'spinner' : ''}
                        />
                      </M3IconButton>
                    </Box>
                  </Tooltip> */}
                    {(tabName === 'activity' || tabName === 'keystrokes') && (
                      <Tooltip title='Download'>
                        <Box>
                          <M3IconButton
                            disabled={isDownloading}
                            onClick={handleOnDownloadClick}
                          >
                            {isDownloading ? (
                              <CircularProgress size={18} />
                            ) : (
                              <DownloadOutlinedIcon />
                            )}
                          </M3IconButton>
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Stack>
                {tabIndexName === 'activity' && (
                  <UserActivityLogs
                    items={infiniteActivities.data}
                    isLoading={infiniteActivities.isLoading}
                  />
                )}
                {tabIndexName === 'session-log' && <UserSessionLogs />}
                {tabIndexName === 'time-worked' && <UserTimeWorked />}
                {tabIndexName === 'snapshots' && <UserSnapshots />}
                {tabIndexName === 'online-meetings' && <UserOnlineMeetings />}
                {tabIndexName === 'keystrokes' && (
                  <UserKeystrokes
                    items={infiniteKeystrokes.data}
                    isLoading={infiniteKeystrokes.isLoading}
                  />
                )}
                {tabIndexName === 'network-monitoring' && (
                  <UserNetworkMonitoring />
                )}
                {tabIndexName === 'audio' && <UserAudioRecordings />}
                {/* {tabIndexName === 'activity' && <UserActivityLogs />}
              {tabIndexName === 'snapshots' && <UserSnapshots />}
              {tabIndexName === 'keystrokes' && <UserKeystrokes />}
              {tabIndexName === 'audio' && <UserAudioRecordings />} */}
              </Box>
            </M3SurfaceContainer>
          </Box>
        </Box>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => {
          if (!user) return;

          if (tabName === 'activity') {
            infiniteActivities.loadNext();
          } else if (tabName === 'keystrokes') {
            infiniteKeystrokes.loadNext();
          }
        }}
      />
    </>
  );
};

export default UserDetail;
