import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

export function useDebounce<T = any>(value: T, delay: number = 300): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(
      () => setDebouncedValue(value),
      delay,
    );
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

type UseHasElapsedRet = {
  reset: () => void;
  elapsed: boolean;
};
export function useHasElapsed(elapsedTime: number): UseHasElapsedRet {
  const [time, setTime] = useState(() => new Date());

  function reset() {
    setTime(new Date(Date.now() + elapsedTime));
  }

  return {
    elapsed: time < new Date(),
    reset,
  };
}

/**
 * Store previous value
 */
export function usePrevious<T = any>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useDebounceCallback(callback: any, deps = [], delay = 300) {
  const debounceCallback = useCallback(debounce(callback, delay), deps); // eslint-disable-line
  return debounceCallback;
}
