import { Fragment, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

import TimezoneChangeModal from './TimezoneChangeModal';

import { useTick } from '../../hooks/utils/tick';

type Props = {};

export function useTimezone() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone ?? moment.tz.guess(true);
}

const Timezone = (props: Props) => {
  const [isTimezoneModalOpen, setIsTimezoneModalOpen] =
    useState<boolean>(false);
  const timezone = useTimezone();
  const [prevTimezone] = useState(timezone);

  const tick = useTick();

  useEffect(() => {
    if (!isTimezoneModalOpen && timezone !== prevTimezone) {
      setIsTimezoneModalOpen(true);
    }
  }, [tick, isTimezoneModalOpen, timezone, prevTimezone]);

  const onClose = (): void => {
    setIsTimezoneModalOpen(false);
  };

  return (
    <Fragment>
      <Typography
        mt={-1}
        mb={0.5}
        textAlign='right'
        variant='subtitle2'
        fontWeight={400}
      >
        <span style={{ opacity: 0.5 }}>
          This view is using your local timezone -{' '}
        </span>
        <span style={{ fontWeight: 500 }}>{timezone.toUpperCase()}</span>
      </Typography>
      {isTimezoneModalOpen && (
        <TimezoneChangeModal onClose={onClose} open={isTimezoneModalOpen} />
      )}
    </Fragment>
  );
};

export default Timezone;
