import { Box, ClickAwayListener, Paper, SxProps } from '@mui/material';
import React, { useState, Children, cloneElement, CSSProperties } from 'react';
import { useAppProvider } from '../../providers/app/app';

import { M3TextField } from '../M3/M3TextField';

type IsOpenByField =
  | 'date'
  | 'members'
  | 'focus'
  | 'questions'
  | 'division'
  | 'month'
  | 'week'
  | string
  | null;

type FilterColumnPanelProps = {
  name: IsOpenByField;
  label?: string;
  displayValue: any;
  placeholder?: string;
  children?: any;
  sx?: SxProps;
  textFieldSx?: SxProps;
  paperSx?: SxProps;
  containerPaperStyle?: CSSProperties;
  alwaysOpen?: boolean;
  useTextField?: boolean;
  boxFieldSx?: SxProps;
  boxFieldStyle?: CSSProperties;
};

const FilterColumnPanel = ({
  label,
  displayValue,
  children,
  placeholder,
  sx,
  textFieldSx,
  paperSx,
  containerPaperStyle,
  alwaysOpen = false,
  useTextField = true,
  boxFieldSx,
  boxFieldStyle,
}: FilterColumnPanelProps) => {
  const { isDarkMode } = useAppProvider();
  const [isOpen, setIsOpen] = useState(alwaysOpen ?? false);

  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        height: '100%',
        position: 'relative',
        ...sx,
      }}
    >
      {useTextField ? (
        <M3TextField
          label={label}
          placeholder={placeholder}
          value={displayValue}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            focused: true,
          }}
          sx={textFieldSx}
          onClick={() => setIsOpen(true)}
        />
      ) : (
        <Box
          style={{
            height: 50,
            fontSize: 16,
            borderRadius: 4,
            color: isDarkMode
              ? 'var(--md-sys-color-on-background-dark)'
              : 'var(--md-sys-color-on-background-light)',
            background: isDarkMode
              ? 'var(--md-sys-color-background-dark)'
              : 'var(--md-sys-color-background-light)',
            border: `1px solid ${
              isDarkMode
                ? 'var(--md-ref-palette-neutral-variant20)'
                : 'var(--md-ref-palette-neutral-variant80)'
            }`,
            ...boxFieldStyle,
          }}
          sx={boxFieldSx}
          onClick={() => setIsOpen(true)}
        >
          {displayValue}
        </Box>
      )}
      {(isOpen || alwaysOpen) && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Box
            style={{
              marginTop: -8,
              ...containerPaperStyle,
            }}
            sx={{ position: 'absolute', top: '100%', left: 0, right: 0, ...sx }}
          >
            <Paper
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                background: isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : undefined,
                ...paperSx,
              }}
            >
              {Children.map(children, (child) => {
                return !child
                  ? null
                  : cloneElement(child, {
                      close: () => setIsOpen(false),
                    });
              })}
            </Paper>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default FilterColumnPanel;
