/**
 * This is a complete copy of VideoPlayer component.
 * We will create a custom player for timeline.
 */

import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Slider, Stack, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import AbsoluteCenterBox from '../../../../components/AbsoluteCenterBox';
import VolumeControl from '../../../../components/AudioPlayer/VolumeControl';

import { useStateRef } from '../../../../hooks/global/useRefAlwaysUpdated';
import AspectRatioContainer, {
  AspectRatioContainerProps,
} from '../../../../components/AspectRatioContainer';
import TimelineGauge from './TimelineGauge';
import moment from 'moment';
import { useFullscreen } from '../../../../components/VideoPlayer/VideoPlayer';
import SpeedControl, {
  useSpeedControl,
} from '../../../../components/VideoPlayer/SpeedControl';
import TimelineVideoSource from './TimelineVideoSource';
import { getDurationSeconds } from '../../../../utils/date';
import { preloadVideo } from '../../../../utils/video';
import { preloadAudio } from '../../../../utils/audio';

export type TimelineMediaSource<Type = 'audio' | 'video'> = {
  id: string;
  timestamp: string;
  duration: number;
  src: string;
  color?: string;
  type: Type;
};
type TimelinePlayerProps = {
  ratio?: AspectRatioContainerProps['ratio'];
  onPause?: () => void;
  onPlay?: () => void;
  style?: CSSProperties;
  videoSource?: TimelineMediaSource<'video'>[];
  audioSource?: TimelineMediaSource<'audio'>[];
};

// eslint-disable-next-line
const formatTime = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return [hours, minutes, seconds]
    .map((unit) => unit.toString().padStart(2, '0'))
    .join(':');
};

const TimelinePlayer = ({
  ratio = '4:3',
  onPause,
  onPlay,
  style,
  videoSource = [],
  audioSource = [],
}: TimelinePlayerProps) => {
  /**
   * Video States --------------------------------------------------------------
   */
  const fps = 30; // 30 | 60
  const [config] = useState(() => ({
    fps: fps, // Frames per second
    step: 1 / fps, // Step size in seconds (1 / fps)
    duration: 24 * 60 * 60, // 24 hours in seconds (day)
  }));
  const {
    ref: currentTimeRef,
    value: currentTime,
    setValue: setCurrentTime,
  } = useStateRef(0);
  const [duration] = useState(config.duration);
  const [isHovering, setIsHovering] = useState(false);
  const {
    ref: isPlayingRef,
    value: isPlaying,
    setValue: setIsPlaying,
  } = useStateRef(false);

  /**
   * Audio States --------------------------------------------------------------
   */
  // const { ref: isAudioPlayingRef, setValue: setIsAudioPlaying } =
  //   useStateRef(false);

  /**
   * Refs ----------------------------------------------------------------------
   */
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hoverTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isDraggingRef = useRef(false);

  /**
   * Video Controls ------------------------------------------------------------
   */
  const speedControl = useSpeedControl();
  const fullscreen = useFullscreen(containerRef.current);
  const { ref: volumeRef, value: volume, setValue: setVolume } = useStateRef(1);
  const handleOnVolumeChange = useCallback(
    (volume: number) => {
      // const video = videoRef.current;
      const audio = audioRef.current;
      /*
      if (video) {
        video.volume = volume;
      }
      */
      if (audio) {
        audio.volume = volume;
      }

      setVolume(volume);
    },
    [audioRef, setVolume],
  );

  /**
   * Video Hover Callbacks -----------------------------------------------------
   */
  const clearHoverTimer = useCallback(() => {
    hoverTimerRef.current && clearTimeout(hoverTimerRef.current);
    hoverTimerRef.current = null;
  }, [hoverTimerRef]);

  const onMouseEnterOnVideo = useCallback(() => {
    clearHoverTimer();
    !isHovering && setIsHovering(true);
    // Check if it's currently playing when we hover the video so we can automatically hide it
    if (isPlayingRef.current || isHovering) {
      hoverTimerRef.current = setTimeout(() => setIsHovering(false), 2000);
    }
  }, [isHovering, isPlayingRef, hoverTimerRef, setIsHovering, clearHoverTimer]);

  const onMouseLeaveOnVideo = useCallback(() => {
    clearHoverTimer();
    setIsHovering(false);
  }, [setIsHovering, clearHoverTimer]);

  /**
   * Video Dispatch Actions ----------------------------------------------------
   */
  const dispatchVideoAction = useCallback(
    (
      action:
        | 'play'
        | 'pause'
        | 'set-current-time'
        | 'set-src'
        | 'load'
        | 'set-playback-rate',
      payload?: unknown,
    ) => {
      let video = videoRef.current;
      if (!video) return;

      switch (action) {
        case 'play':
          video.play();
          break;
        case 'pause':
          video.pause();
          break;
        case 'set-current-time':
          video.currentTime = payload as number;
          break;
        case 'set-src':
          video.src = payload as string;
          break;
        case 'load':
          video.load();
          break;
        case 'set-playback-rate':
          video.playbackRate = payload as number;
          break;
        default:
          break;
      }
    },
    [videoRef],
  );

  /**
   * Media Source States -------------------------------------------------------
   */
  const {
    ref: currentMediaSourceRef,
    value: currentMediaSource,
    setValue: setCurrentMediaSource,
  } = useStateRef<null | TimelineMediaSource>(null);
  // Get the duration (position from the left side "start of day")
  const getMediaSourceDurationOfDay = useCallback(
    (mediaSource: TimelineMediaSource) => {
      let sTime = moment.utc(mediaSource!.timestamp).local();
      let dur = getDurationSeconds(sTime, sTime.clone().startOf('day'));
      return dur;
    },
    [],
  );
  // Gets the next media source to play
  const getNextMediaSource = useCallback((): TimelineMediaSource | null => {
    let ms = currentMediaSourceRef.current;
    let index = videoSource.findIndex((s) => s.id === ms?.id);
    let newMs = videoSource[index + 1] || null;
    return newMs;
  }, [currentMediaSourceRef, videoSource]);
  // Use when the video is playing to update the current time (slider position)
  const updateCurrentTimeFromVideoUpdate = useCallback(
    (evt: Event) => {
      let video = (evt.target || evt.currentTarget) as HTMLVideoElement;
      let currentTime = (video || videoRef.current).currentTime ?? 0;

      if (currentMediaSourceRef.current && !isDraggingRef.current) {
        let ms = currentMediaSourceRef.current;
        let dur = getMediaSourceDurationOfDay(ms);

        setCurrentTime(currentTime + dur);
      }
    },
    [
      videoRef,
      currentMediaSourceRef,
      isDraggingRef,
      setCurrentTime,
      getMediaSourceDurationOfDay,
    ],
  );

  /**
   * Audio Dispatch Actions / Methods ------------------------------------------
   */
  const dispatchAudioAction = useCallback(
    (
      action:
        | 'play'
        | 'pause'
        | 'set-current-time'
        | 'set-src'
        | 'load'
        | 'set-playback-rate'
        | 'set-volume',
      payload?: unknown,
    ) => {
      let audio = audioRef.current;

      switch (action) {
        case 'play':
          audio?.src && audio.play();
          break;
        case 'pause':
          audio?.src && audio.pause();
          break;
        case 'set-current-time':
          audio?.src && (audio.currentTime = payload as number);
          break;
        case 'set-src':
          audio = audio || new Audio(payload as string);
          audio.src = payload as string;
          break;
        case 'load':
          audio?.src && audio?.load();
          break;
        case 'set-playback-rate':
          audio?.src && (audio.playbackRate = payload as number);
          break;
        case 'set-volume':
          audio?.src && (audio.volume = payload as number);
          break;
        default:
          break;
      }

      audioRef.current = audio;
    },
    [audioRef],
  );
  // Loads audio source that matches the video timestamp
  const loadAudioSourceMatchesVideoTimestamp = useCallback(
    (autoplay?: boolean) => {
      let curTime = currentTimeRef.current;
      // Filter the audio sources that matches video timestamp
      let filter = (ams: TimelineMediaSource<'audio'>) => {
        let aDur = getMediaSourceDurationOfDay(ams);
        return curTime >= aDur && curTime < aDur + ams.duration;
      };
      // Audio load actions and setting source
      let execute = (ams: TimelineMediaSource<'audio'>) => {
        let aDur = getMediaSourceDurationOfDay(ams);

        if (curTime >= aDur && curTime < aDur + ams.duration) {
          let aTime = curTime - aDur;

          dispatchAudioAction('set-src', ams.src);
          dispatchAudioAction('load');
          dispatchAudioAction('set-current-time', aTime);
          dispatchAudioAction('set-playback-rate', speedControl.ref.current);
          dispatchAudioAction('set-volume', volumeRef.current);

          // If video is already playing. Then we auto play
          if (isPlayingRef.current) {
            dispatchAudioAction('play');
          }
        }
      };
      // Use filter???
      let ams = audioSource.find(filter);
      ams && execute(ams);

      // Try to load the next audio to be played to avoid buffering
      let index = ams ? audioSource.indexOf(ams) : -1;
      let nextAms = audioSource[index + 1];
      nextAms && preloadAudio(nextAms.src);
    },
    [
      volumeRef,
      speedControl.ref,
      isPlayingRef,
      currentTimeRef,
      getMediaSourceDurationOfDay,
      audioSource,
      dispatchAudioAction,
    ],
  );

  // Calls when current media source ended playing
  const setNextVideoAfterCurrentEnded = useCallback(() => {
    let nextMs = getNextMediaSource();
    // If there's a next media source. Set it
    if (nextMs) {
      setCurrentMediaSource(nextMs);
    }
    // else, if there's none. Stop the video state. Basically, a mimic call
    // of the handleOnPause
    else {
      dispatchVideoAction('pause');
      dispatchAudioAction('pause');
      setIsPlaying(false);
      onPause?.();
    }
  }, [
    getNextMediaSource,
    setCurrentMediaSource,
    dispatchVideoAction,
    dispatchAudioAction,
    setIsPlaying,
    onPause,
  ]);

  /**
   * Video Button Handles ------------------------------------------------------
   */
  // Handle when pressing the Pause button
  const handleOnPause = useCallback(() => {
    dispatchVideoAction('pause');
    setIsPlaying(false);
    onPause?.();
  }, [onPause, dispatchVideoAction, setIsPlaying]);
  // Handle when pressing the Play button
  const handleOnPlay = useCallback(() => {
    let ms = currentMediaSourceRef.current || getNextMediaSource();

    if (!ms) return;

    if (currentMediaSourceRef.current?.id !== ms.id) {
      setCurrentMediaSource(ms);
    }
    setIsPlaying(true);
  }, [
    setIsPlaying,
    getNextMediaSource,
    setCurrentMediaSource,
    currentMediaSourceRef,
  ]);

  /**
   * Slider Handlers -----------------------------------------------------------
   */
  /**
   * When seeker change, when user press the switch and drag it. We pause the video
   * and update the slider immediately as to update the slider.
   */
  const handleSliderChange = useCallback(
    (event: Event, value: number | number[]) => {
      isDraggingRef.current = true;
      setCurrentTime(value as number);
    },
    [isDraggingRef, setCurrentTime],
  );
  // When the slider end. This is when user release its pointing
  const handleSliderChangeEnd = useCallback(
    (event: SyntheticEvent | Event, value: number | number[]) => {
      let newValue = value as number;
      isDraggingRef.current = false;
      /**
       * TODO: Need to handle to match videos of current value.
       * If there's non matches. Find the nearest video at this point.
       */
      if (!videoSource.length) return;

      let selectedMs = videoSource.find((ms) => {
        let dur = getMediaSourceDurationOfDay(ms);
        // Check if the time the user drags matches any video source
        // Then we set that
        if (newValue >= dur && newValue < dur + ms.duration) {
          return true;
        }
        return false;
      });
      /**
       * Check there's no existing video source on the selected. Then let's
       * find the nearest video instead.
       */
      if (!selectedMs) {
        videoSource.forEach((ms, index) => {
          if (selectedMs) return;

          let dur = getMediaSourceDurationOfDay(ms);
          let durDistance = Math.abs(newValue - dur);
          let nextMs = videoSource[index + 1];

          // If the current time selected is less than the first one
          if (newValue <= dur && index === 0) {
            selectedMs = ms;
          }
          // Check first if there's next media source, then we calculated which
          // is the nearest media source instead
          else if (nextMs) {
            let nextDur = getMediaSourceDurationOfDay(nextMs);

            // Check first if it's in the range between them
            if (newValue >= dur && newValue < nextDur) {
              let nextDurDistance = Math.abs(newValue - nextDur);
              // Check if the current media source distance is closer,
              // then we select the current media source
              selectedMs = durDistance <= nextDurDistance ? ms : nextMs;
            }
          }
          // No more next media source? Then we select the last one
          else if (newValue >= dur) {
            selectedMs = ms;
          }
        });
      }

      // If at this point it could not find anything nothing we can do??
      if (!selectedMs) return;

      let selectedDur = getMediaSourceDurationOfDay(selectedMs);

      // Check if it's still the current one being selected, Then we just
      // simple set the time of the video on the distance it drag
      if (currentMediaSourceRef.current?.id === selectedMs.id) {
        let time = newValue - selectedDur;
        // Check the dragging stops at in between of the selected media source
        // Then we set/skip the video time
        if (
          newValue >= selectedDur &&
          newValue < selectedDur + selectedMs.duration
        ) {
          setCurrentTime(newValue);
          dispatchVideoAction('set-current-time', time <= 0 ? 0 : time);
        }
      }
      // else, we set the new one
      else {
        setCurrentTime(selectedDur);
        setCurrentMediaSource(selectedMs);
      }

      requestAnimationFrame(() => handleOnPlay());
    },
    [
      currentMediaSourceRef,
      setCurrentTime,
      handleOnPlay,
      videoSource,
      setCurrentMediaSource,
      dispatchVideoAction,
      getMediaSourceDurationOfDay,
    ],
  );

  // USE EFFECTS ---------------------------------------------------------------

  /**
   * On first load, and there's no media source yet, and video sources exist.
   * We set the first one
   */
  useEffect(() => {
    if (!currentMediaSource && videoSource.length) {
      setCurrentMediaSource(videoSource[0]);
    }
  }, [currentMediaSource, videoSource, setCurrentMediaSource]);

  /**
   * Load the video when the current media source changes
   */
  useEffect(() => {
    if (!currentMediaSource?.id || !videoRef.current) return;

    let video = videoRef.current;
    let src = currentMediaSource.src;

    video.preload = 'auto';
    dispatchVideoAction('set-src', src);
    dispatchVideoAction('set-playback-rate', speedControl.ref.current);

    // Preload the next media source to avoid, long loading
    let nextMs = getNextMediaSource();
    nextMs && preloadVideo(nextMs.src);
  }, [
    currentMediaSource?.id,
    currentMediaSource?.src,
    videoRef,
    audioRef,
    speedControl.ref,
    dispatchVideoAction,
    dispatchAudioAction,
    getNextMediaSource,
  ]);

  /**
   * Load the video when the current media source changes
   */
  useEffect(() => {
    if (!videoRef.current) return;

    let video = videoRef.current;
    let loadedMetadataCallback = (evt: Event) => {
      loadAudioSourceMatchesVideoTimestamp(true);
      updateCurrentTimeFromVideoUpdate(evt);
    };
    let timePlayingCallback = (evt: Event) => {
      // updateCurrentTimeFromVideoUpdate(evt);
    };
    let timeupdateCallback = (evt: Event) => {
      updateCurrentTimeFromVideoUpdate(evt);
    };
    let timeEndedCallback = (evt: Event) => {
      setNextVideoAfterCurrentEnded();
    };

    // remove event listeners
    video.removeEventListener('loadeddata', loadedMetadataCallback);
    video.removeEventListener('playing', timePlayingCallback);
    video.removeEventListener('timeupdate', timeupdateCallback);
    video.removeEventListener('ended', timeEndedCallback);

    // Add event listeners
    video.addEventListener('loadeddata', loadedMetadataCallback);
    video.addEventListener('playing', timePlayingCallback);
    video.addEventListener('timeupdate', timeupdateCallback);
    video.addEventListener('ended', timeEndedCallback);

    return () => {
      video.removeEventListener('loadeddata', loadedMetadataCallback);
      video.removeEventListener('playing', timePlayingCallback);
      video.removeEventListener('timeupdate', timeupdateCallback);
      video.removeEventListener('ended', timeEndedCallback);
    };
  }, [
    videoRef,
    updateCurrentTimeFromVideoUpdate,
    setNextVideoAfterCurrentEnded,
    loadAudioSourceMatchesVideoTimestamp,
  ]);

  /**
   * When video state isPlaying changes. Play the loaded video.
   */
  useEffect(() => {
    if (isPlaying) {
      dispatchVideoAction('play');
      dispatchAudioAction('play');
      onPlay?.();
    }
  }, [
    isPlaying,
    currentMediaSource?.id,
    onPlay,
    dispatchVideoAction,
    dispatchAudioAction,
  ]);

  /**
   * When player is not playing, pause the video
   */
  useEffect(() => {
    if (!isPlaying) {
      dispatchVideoAction('pause');
      dispatchAudioAction('pause');
    }
  }, [isPlaying, dispatchVideoAction, dispatchAudioAction]);

  /**
   * When it's unmounted pause the video
   */
  useEffect(() => {
    return () => {
      dispatchVideoAction('pause');
      dispatchAudioAction('pause');
    };
  }, [dispatchVideoAction, dispatchAudioAction]);

  /**
   * Update changing playback rate
   */
  useEffect(() => {
    dispatchVideoAction('set-playback-rate', speedControl.speed);
    dispatchAudioAction('set-playback-rate', speedControl.speed);
  }, [speedControl.speed, dispatchVideoAction, dispatchAudioAction]);

  const iconStyle: CSSProperties = {
    cursor: 'pointer',
    opacity: 0.8,
    fontSize: 30,
  };

  return (
    <Box
      ref={containerRef}
      style={{
        // marginBottom: 40,
        paddingBottom: 10,
      }}
    >
      <AspectRatioContainer
        ratio={ratio}
        style={{
          ...(fullscreen.isFullscreen
            ? {
                height: '100%',
                paddingBottom: 0,
              }
            : style),
        }}
      >
        <Box
          style={{
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            userSelect: 'none',
            position: 'absolute',
            flexDirection: 'column',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            style={{
              height: 0,
              flexGrow: 2,
              position: 'relative',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
            onMouseMove={onMouseEnterOnVideo}
            onMouseEnter={onMouseEnterOnVideo}
            onMouseLeave={onMouseLeaveOnVideo}
          >
            <video
              ref={videoRef}
              muted
              controls={false}
              autoPlay={false}
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                position: 'absolute',
              }}
            />
            <AbsoluteCenterBox
              style={{
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                transition: 'opacity 200ms',
                opacity: isHovering || !isPlaying ? 1 : 0,
              }}
              onClick={isPlaying ? handleOnPause : handleOnPlay}
            >
              {isPlaying ? (
                isHovering && (
                  <PauseIcon
                    style={{
                      ...iconStyle,
                      fontSize: 60,
                      color: '#fff',
                      pointerEvents: 'none',
                    }}
                  />
                )
              ) : (
                <PlayArrowIcon
                  style={{
                    ...iconStyle,
                    fontSize: 60,
                    color: '#fff',
                    pointerEvents: 'none',
                  }}
                />
              )}
              <Stack
                direction='row'
                justifyContent='flex-end'
                style={{
                  left: 0,
                  right: 0,
                  bottom: 0,
                  padding: '20px 40px',
                  position: 'absolute',
                }}
                onClick={(evt) => evt.stopPropagation()}
              >
                {fullscreen.isFullscreen ? (
                  <FullscreenExitIcon
                    style={{ ...iconStyle, color: '#fff' }}
                    onClick={fullscreen.hideFullscreen}
                  />
                ) : (
                  <FullscreenIcon
                    style={{ ...iconStyle, color: '#fff' }}
                    onClick={fullscreen.showFullscreen}
                  />
                )}
              </Stack>
            </AbsoluteCenterBox>
          </Box>
          <Box
            gap={1}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 6,
              paddingBottom: 20,
            }}
          >
            {isPlaying ? (
              <PauseIcon onClick={handleOnPause} style={iconStyle} />
            ) : (
              <PlayArrowIcon onClick={handleOnPlay} style={iconStyle} />
            )}
            <VolumeControl
              initialVolume={volume}
              style={{
                marginRight: -16,
              }}
              iconStyle={{
                fontSize: 24,
              }}
              onVolumeChange={handleOnVolumeChange}
            />
            <Typography
              component='div'
              fontSize={14}
              style={{
                width: 80,
                marginLeft: 8,
                marginRight: 4,
                textAlign: 'right',
              }}
            >
              <span style={{ opacity: 0.5 }}>
                {moment().startOf('day').format('YYYY-MM-DD')}
              </span>
            </Typography>
            <SpeedControl
              speed={speedControl.speed}
              onChange={speedControl.onChange}
            />
            <Box
              style={{
                flex: 1,
                top: -14,
                marginRight: 10,
                position: 'relative',
              }}
            >
              <Box
                style={{
                  top: 22,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                }}
              >
                <TimelineGauge />
              </Box>
              <Box
                style={{
                  top: 8,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                }}
              >
                <TimelineVideoSource sources={videoSource} />
              </Box>
              <Slider
                disableSwap
                size='small'
                min={0}
                max={duration}
                step={config.step}
                value={currentTime}
                disabled={!duration}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeEnd}
                valueLabelDisplay='on'
                valueLabelFormat={(value: number, index: number) =>
                  moment()
                    .startOf('day')
                    .add(value, 'second')
                    .format('hh:mm:ss a')
                }
                style={{
                  top: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                }}
                sx={{
                  '& .MuiSlider-thumb': {
                    transition: 'none',
                  },
                  '& .MuiSlider-track': {
                    transition: 'none',
                  },
                  '& .MuiSlider-rail': {
                    transition: 'none',
                  },
                }}
              />
            </Box>
            <Typography
              component='div'
              fontSize={14}
              style={{
                width: 80,
                textAlign: 'right',
              }}
            >
              <span style={{ opacity: 0.5 }}>
                {moment().startOf('day').add(1, 'day').format('YYYY-MM-DD')}
              </span>
            </Typography>
          </Box>
        </Box>
      </AspectRatioContainer>
    </Box>
  );
};

export default TimelinePlayer;
