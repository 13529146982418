import * as React from 'react';
import { Box, Divider, SxProps, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { M3Button } from './M3Button';

import { ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../../providers/app/app';

export type M3AlertDialogProps = DialogProps & {
  dialog?: {
    title?: ReactRenderElement;
    message?: ReactRenderElement;
    content?: ReactRenderElement;
    afterMessage?: ReactRenderElement;
  };
  actions?: {
    cancel?: {
      disabled?: boolean;
      label?: ReactRenderElement;
      onClick?: () => void;
      sx?: SxProps;
      width?: number;
    };
    confirm?: {
      disabled?: boolean;
      label?: ReactRenderElement;
      onClick?: () => void;
      sx?: SxProps;
      width?: number;
    };
  };
};

export default function M3AlertDialog({
  open,
  dialog,
  actions,
  onClose,
  ...restDialogProps
}: M3AlertDialogProps) {
  const { isDarkMode } = useAppProvider();
  const buttonSx = {
    pl: 3,
    pr: 3,
  };
  const { PaperProps, ...restDialogPropsProps } = restDialogProps ?? {};

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='sm'
      PaperProps={{
        elevation: PaperProps?.elevation ?? 4,
        sx: {
          maxWidth: 680,
          borderRadius: 3,
          ...PaperProps?.sx,
        },
      }}
      onClose={onClose}
      {...restDialogPropsProps}
    >
      <Box>
        <DialogTitle
          sx={{
            background: isDarkMode
              ? 'var(--md-ref-palette-primary20)'
              : 'var(--md-ref-palette-primary95)',
          }}
        >
          {dialog?.title}
        </DialogTitle>
        <Divider sx={{ opacity: 0.8 }} />
        {!!dialog?.message && (
          <Typography
            component='div'
            sx={{
              pt: 2,
              pl: 3,
              pr: 3,
              opacity: 0.8,
            }}
          >
            {dialog.message}
          </Typography>
        )}
        <DialogContent
          sx={{
            p: 3,
            pt: 2,
            pb: 2,
          }}
        >
          {dialog?.content}
        </DialogContent>
        {!!dialog?.afterMessage && (
          <Typography
            component='div'
            sx={{
              pl: 3,
              pr: 3,
              mb: 3,
              opacity: 0.8,
            }}
          >
            {dialog.afterMessage}
          </Typography>
        )}
      </Box>
      <Divider sx={{ opacity: 0.8 }} />
      <DialogActions
        sx={{
          p: 3,
          pt: 1.5,
          pb: 1.5,
          background: isDarkMode
            ? 'var(--md-ref-palette-primary20)'
            : 'var(--md-ref-palette-primary95)',
        }}
      >
        {actions?.cancel && (
          <M3Button
            sx={{
              ...buttonSx,
              ...actions.cancel.sx,
            }}
            disabled={actions.cancel.disabled}
            onClick={actions.cancel.onClick}
          >
            {actions.cancel.label ?? 'Cancel'}
          </M3Button>
        )}
        {actions?.confirm && (
          <M3Button
            variant='contained'
            sx={{
              ...buttonSx,
              ...actions.confirm.sx,
            }}
            disabled={actions.confirm.disabled}
            onClick={actions.confirm.onClick}
          >
            {actions.confirm.label ?? 'Confirm'}
          </M3Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
