import { ListQuery } from '../../types/request';

// Build Identifiers
type BuildIdentifiersProps<T = ListQuery> = {
  params: T;
  baseIdentifier: string;
  identifierKeys?: (keyof T)[];
  queryParamKeys?: (keyof T)[];
  omitAllDefaultParamKeys?: boolean;
  omitParamKeys?: (keyof T)[];
};

function buildIdentifiers<T>({
  params,
  baseIdentifier,
  identifierKeys = [],
  queryParamKeys = [],
  omitParamKeys = [],
  omitAllDefaultParamKeys = false,
}: BuildIdentifiersProps<T>): {
  identifiers: string[];
  queryParams: T;
} {
  const identifiers = [baseIdentifier];
  const queryParams: Partial<T> = {};

  const defaultIdentifiers: (keyof (ListQuery & T))[] = ['key', 'page'];

  const defaultQueryKeys: (keyof (ListQuery & T))[] = [
    'page',
    'page_size',
    'offset',
    'limit',
    'omit',
    'fields',
    'search',
    'ordering',
  ];

  for (const key in params) {
    const typedKey = key as keyof T;

    if (
      omitParamKeys.includes(typedKey) ||
      (omitAllDefaultParamKeys && defaultQueryKeys.includes(typedKey))
    ) {
      continue;
    }

    if (
      (identifierKeys.includes(typedKey) ||
        defaultIdentifiers.includes(typedKey)) &&
      params[typedKey] !== undefined &&
      params[typedKey] !== null
    ) {
      identifiers.push(`${params[typedKey]}`);
    }

    if (
      (queryParamKeys.includes(typedKey) ||
        defaultQueryKeys.includes(typedKey)) &&
      params[typedKey] !== undefined
    ) {
      queryParams[typedKey] = params[typedKey];
    }
  }

  return { identifiers, queryParams: queryParams as T };
}

// Use Identifier Hook
export function useIdentifier<T>(props: BuildIdentifiersProps<T>) {
  return buildIdentifiers<T>(props);
}
