import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Stack, Tooltip, Typography } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import {
  getUserInitials,
  getUserPhotoUrl,
  getUserDisplayName,
} from '../../utils/user';
import { useUserProvider } from '../../providers/user/user';
import {
  AutocompleteSearchOptionItem,
  SearchResultProps,
} from './AutocompleteSearch';
import { useCurrentProfile } from '../../hooks/profile';

export function useAutocompleteUserSearch() {
  const { data: currentProfile } = useCurrentProfile();
  const { setUniqueIdsToFetch } = useUserProvider();

  const [usersSelected, setUsersSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [users, setUsers] = useState<AutocompleteSearchOptionItem[]>([]);

  const handleOnUserSearchSelected = useCallback(
    (users: any) => setUsersSelected(users as AutocompleteSearchOptionItem[]),
    [setUsersSelected],
  );

  const getUserOptionFormatter = useCallback(
    (user: any) =>
      ({
        id: user.id,
        label: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
        props: user,
      } as AutocompleteSearchOptionItem),
    [],
  );

  const onUserSearchTextOptions = useCallback(
    (regex: RegExp) => {
      const fullName = getUserDisplayName(currentProfile).fullName;
      let options: AutocompleteSearchOptionItem[] = [];

      if (
        regex.test(fullName ?? '') ||
        regex.test(currentProfile?.email ?? '')
      ) {
        options.push({
          id: currentProfile!.id,
          label: fullName!,
          props: currentProfile,
        });
      }

      return options;
    },
    [currentProfile],
  );

  const getRenderUserOptionItem = useCallback(
    (props: SearchResultProps) => <UserSearchResult {...props} />,
    [],
  );

  useEffect(() => {
    if (users.length) {
      setUniqueIdsToFetch({
        user_ids: users.map((u) => u.id),
      });
    }
  }, [users, setUniqueIdsToFetch]);

  return {
    users,
    setUsers,
    usersSelected,
    setUsersSelected,
    handleOnUserSearchSelected,
    getUserOptionFormatter,
    onUserSearchTextOptions,
    getRenderUserOptionItem,
  };
}

type UserSearchResultProps = {
  multiple?: boolean;
  selected?: boolean;
  option: AutocompleteSearchOptionItem;
};
export function UserSearchResult({
  selected,
  multiple,
  option,
}: UserSearchResultProps) {
  const { getUser } = useUserProvider();
  const user = getUser('user_id', option.id) ?? option.props;
  const email = option.props?.email;
  const fullName = getUserDisplayName(user || option.props).fullName;

  return (
    <Stack
      gap={1}
      py={0.5}
      flex={1}
      flexDirection='row'
      alignItems='center'
      position='relative'
    >
      <Avatar
        sx={{
          width: 25,
          height: 25,
        }}
        src={getUserPhotoUrl(user?.photo_url)}
      >
        {getUserInitials(user?.first_name).initial}
      </Avatar>
      <Box flex={1} width={0}>
        <Typography
          component='div'
          fontSize={14}
          fontWeight={500}
          minWidth={70}
          lineHeight={1.2}
        >
          {fullName}
        </Typography>
        <Typography
          component='div'
          fontSize={12}
          fontWeight={500}
          lineHeight={1.2}
          sx={{
            opacity: 0.5,
          }}
        >
          {email}
        </Typography>
      </Box>
      {multiple && selected && (
        <CheckOutlinedIcon
          style={{
            opacity: 0.5,
            fontSize: 16,
            marginRight: -4,
          }}
        />
      )}
    </Stack>
  );
}

type UserSearchSelectedProps = {
  userId: number;
};
export function UserSearchSelected({ userId }: UserSearchSelectedProps) {
  const { getUser } = useUserProvider();
  const user = getUser('user_id', userId);
  const fullName = getUserDisplayName(user).fullName;

  return (
    <Tooltip title={fullName!}>
      <Box
        sx={{
          top: 12,
          left: 12,
          zIndex: 2,
          position: 'absolute',
        }}
      >
        <Avatar
          sx={{
            width: 25,
            height: 25,
          }}
          src={getUserPhotoUrl(user?.photo_url)}
        >
          {getUserInitials(user?.first_name).initial}
        </Avatar>
      </Box>
    </Tooltip>
  );
}
