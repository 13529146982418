import React, { useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';

import M3Modal, {
  M3ModalBody,
  M3ModalHeader,
  M3ModalView,
  UseModalRes,
} from '../../M3/M3Modal';
import Go2AppSettings from './Go2AppSettings';
import ApplicationsAndWebsitesSettings from './ApplicationsAndWebsitesSettings';
import AppDrawerMenuList from '../../AppDrawerMenuList';

import { useGo2AgentAppConfigDefaults } from '../../../hooks/cosmos/go2-agent/app-config';
import { useUserGo2AgentAppWebsiteWhitelist } from '../../../hooks/cosmos/go2-agent/app-website-whitelist';
import { MenuItem } from '../../../types/types';

type WebAppSettingsProps = {
  modal: UseModalRes;
};

const WebAppSettings = ({ modal }: WebAppSettingsProps) => {
  const innerRef = useRef<HTMLDivElement>();

  const [menuId, setMenuId] = useState<string>('go2_app');
  const menus: MenuItem[] = [
    {
      id: 'settings',
      name: 'Manage',
      headline: true,
      icon: <AppRegistrationOutlinedIcon style={{ fontSize: 20 }} />,
    },
    {
      id: 'go2_app',
      name: 'Go2 App Settings',
    },
    {
      id: 'apps_websites',
      name: 'Applications & Websites',
    },
  ];
  menus.forEach((menu) => (menu.active = menu.id === menuId));
  menus.forEach((menu) => (menu.onClick = () => setMenuId(menu.id)));

  const activeMenu = menus.find((menu) => menu.active);

  const {
    data: go2AgentAppConfigData,
    isFetching: isFetchingAppConfigData,
    refetch: go2AgentAppConfigRefetch,
  } = useGo2AgentAppConfigDefaults({});

  const appUserGo2AgentAppWebsiteWhitelist = useUserGo2AgentAppWebsiteWhitelist(
    {
      type: 'app',
      limit: 2000,
    },
  );
  const webUserGo2AgentAppWebsiteWhitelist = useUserGo2AgentAppWebsiteWhitelist(
    {
      type: 'web',
      limit: 2000,
    },
  );

  const renderSidebar = () => {
    return (
      <Box style={{ width: 200 }}>
        {/* <PerfectScrollbar> */}
        <AppDrawerMenuList
          menus={menus}
          // onExpanded={() => setScrollYMenuList((s) => ++s)}
        />
        {/* </PerfectScrollbar> */}
      </Box>
    );
  };

  const renderActiveMenuContent = () => {
    return (
      <Box flex={1} width={0} style={{ paddingTop: 24, paddingLeft: 8 }}>
        {activeMenu?.id === 'go2_app' && (
          <>
            <Go2AppSettings
              isGlobal
              isFetchingAppConfigData={isFetchingAppConfigData}
              globalAppConfigData={go2AgentAppConfigData}
              appConfigData={go2AgentAppConfigData}
              appConfigRefetch={go2AgentAppConfigRefetch}
            />
            <br />
            <br />
            <br />
          </>
        )}
        {activeMenu?.id === 'apps_websites' && (
          <ApplicationsAndWebsitesSettings
            isGlobal
            isFetchingAppWebsiteData={
              appUserGo2AgentAppWebsiteWhitelist.isLoading ||
              webUserGo2AgentAppWebsiteWhitelist.isLoading
            }
            appUserAppWebsiteWhitelist={appUserGo2AgentAppWebsiteWhitelist.data}
            appUserAppWebsiteWhitelistRefetch={
              appUserGo2AgentAppWebsiteWhitelist.refetch
            }
            webUserAppWebsiteWhitelist={webUserGo2AgentAppWebsiteWhitelist.data}
            webUserAppWebsiteWhitelistRefetch={
              webUserGo2AgentAppWebsiteWhitelist.refetch
            }
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <M3Modal open={modal.isOpen} onClose={modal.close}>
        <M3ModalView
          onClose={modal.close}
          style={{
            maxWidth: 1200,
          }}
        >
          <M3ModalHeader>
            <Stack direction='row' alignItems='center' gap={1} pl={1}>
              <SettingsOutlinedIcon style={{ fontSize: 26 }} />
              <Typography component='div' fontSize={22} fontWeight={500}>
                Settings
              </Typography>
            </Stack>
          </M3ModalHeader>
          <M3ModalBody>
            <Box
              ref={innerRef}
              gap={4}
              display='flex'
              style={{
                padding: '0px 32px 24px 8px',
                height: '90vh',
              }}
            >
              {renderSidebar()}
              {renderActiveMenuContent()}
            </Box>
          </M3ModalBody>
        </M3ModalView>
      </M3Modal>
    </>
  );
};

export default WebAppSettings;
