import { Badge, Box, Tooltip, Typography } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import clsx from 'clsx';

import { ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../../providers/app/app';
import { PaletteTypeButtonProps } from '../../types/app';

type Props = {
  label?: ReactRenderElement;
  icon: ReactRenderElement;
  active?: boolean;
  badgeCount?: number | ReactRenderElement;
  tooltip?: {
    title: string;
    placement?: 'bottom' | 'right' | 'left' | 'top';
  };
  onClick?: (evt: SyntheticEvent) => void;
  children?: ReactRenderElement;
  useBadge?: boolean;
} & PaletteTypeButtonProps;

const M3NavRailButton = ({
  icon,
  label,
  active,
  badgeCount,
  tooltip,
  onClick,
  children,
}: Props) => {
  const { isDarkMode } = useAppProvider();

  const content = (
    <Box
      sx={{
        // marginBottom: '14px',
        marginBottom: '12px',
        '&:last-child': {
          marginBottom: 0,
        },
      }}
    >
      <Box
        flexDirection='column'
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{
          userSelect: 'none',
          padding: '2px',
          cursor: 'pointer',
          '&:hover': {
            '.iconWrapper': {
              background: `linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.04),
                  rgba(0, 0, 0, 0.04)
                ),
                var(${
                  isDarkMode
                    ? '--md-ref-palette-secondary40'
                    : '--md-ref-palette-secondary80'
                })`,
              '&.active': {
                background: `linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.08),
                    rgba(0, 0, 0, 0.08)
                  ),
                  var(${
                    isDarkMode
                      ? '--md-ref-palette-secondary40'
                      : '--md-ref-palette-secondary80'
                  })`,
                '&:active': {
                  background: `linear-gradient(
                      0deg,
                      rgba(0, 0, 0, 0.16),
                      rgba(0, 0, 0, 0.16)
                    ),
                    var(${
                      isDarkMode
                        ? '--md-ref-palette-secondary40'
                        : '--md-ref-palette-secondary80'
                    })`,
                  '.MuiSvgIcon-root': {
                    transform: 'scale(0.96)',
                  },
                },
              },
            },
          },
        }}
        onClick={onClick}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            mb: 0.3,
            width: 56,
            height: 32,
            borderRadius: 12,
            '&.active': {
              color: isDarkMode
                ? `var(--md-ref-palette-secondary90)`
                : `var(--md-ref-palette-secondary10)`,
              backgroundColor: isDarkMode
                ? `var(--md-ref-palette-secondary40)`
                : `var(--md-ref-palette-secondary80)`,
            },
            '.MuiSvgIcon-root': {
              fontSize: 24,
              transition: '200ms',
            },
          }}
          className={clsx({
            active: !!active,
            iconWrapper: true,
          })}
        >
          {icon}
          {!!badgeCount && (
            <Badge
              badgeContent={badgeCount}
              color='primary'
              sx={{
                top: -18,
                right: -6,
                '& .MuiBadge-badge': {
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  color: isDarkMode
                    ? 'var(--md-sys-color-background-dark)'
                    : undefined,
                  backgroundColor: isDarkMode
                    ? `var(--md-ref-palette-primary80)`
                    : `var(--md-ref-palette-primary40)`,
                },
              }}
            />
          )}
        </Box>
        {!!label && (
          <Typography component='div' fontSize={12} fontWeight={500} noWrap>
            {label}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
  let render: ReactRenderElement = null;

  if (tooltip) {
    render = (
      <Tooltip title={tooltip.title} placement={tooltip.placement ?? 'bottom'}>
        {content}
      </Tooltip>
    );
  } else {
    render = content;
  }

  return render;
};

export default M3NavRailButton;
