import React, { CSSProperties, Fragment, useCallback, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';

import M3Modal, {
  M3ModalBody,
  M3ModalHeader,
  M3ModalView,
  useModal,
} from '../M3/M3Modal';
import { IterableObject, ReactRenderElement } from '../../types/types';
import {
  formatBytes,
  stripToFormatterWord,
  toTitleCase,
} from '../../utils/string';
import {
  DiskLayoutElement,
  GraphicControllerElement,
  GraphicDisplayElement,
  UserSystemInfo,
  WifiNetworkElement,
} from '../../types/system-info';

type UserComputerProps = {
  userSystemInfo?: UserSystemInfo | null;
};

const UserComputer = ({ userSystemInfo }: UserComputerProps) => {
  const modal = useModal();
  const [moreUserSystemInfo, setMoreUserSystemInfo] = useState<
    UserSystemInfo[]
  >([]);
  const onMoreComputerClick = useCallback(() => {
    if (userSystemInfo) {
      setMoreUserSystemInfo((state) => [userSystemInfo]);
    }
    modal.open();
    // eslint-disable-next-line
  }, [modal.open, userSystemInfo]);

  return (
    <>
      <UserComputerView
        overview
        userSystemInfo={userSystemInfo}
        onMoreComputerClick={onMoreComputerClick}
      />
      <UserComputerModal
        open={modal.isOpen}
        onClose={modal.close}
        userSystemInfos={moreUserSystemInfo}
      />
    </>
  );
};

type UserComputerModalProps = {
  onClose: () => void;
  open: boolean;
  userSystemInfos: UserSystemInfo[];
};
const UserComputerModal: React.FC<UserComputerModalProps> = ({
  userSystemInfos,
  onClose,
  open,
}) => {
  return (
    <>
      <M3Modal open={open} onClose={onClose}>
        <M3ModalView
          onClose={onClose}
          style={{
            maxWidth: 800,
          }}
        >
          <M3ModalHeader>
            <Stack direction='row' alignItems='center' gap={2}>
              <ComputerOutlinedIcon style={{ fontSize: 24 }} />
              <Typography component='div' fontSize={18} fontWeight={500}>
                Computers
              </Typography>
            </Stack>
          </M3ModalHeader>
          <M3ModalBody>
            {userSystemInfos.map((userSystemInfo, index) => {
              return (
                <UserComputerView key={index} userSystemInfo={userSystemInfo} />
              );
            })}
          </M3ModalBody>
        </M3ModalView>
      </M3Modal>
    </>
  );
};

type UserComputerViewProps = {
  overview?: boolean;
  userSystemInfo?: UserSystemInfo | null;
  onMoreComputerClick?: () => void;
};
const UserComputerView = ({
  overview,
  userSystemInfo,
  onMoreComputerClick,
}: UserComputerViewProps) => {
  const [isSectionVisible, setIsSectionVisible] = useState<IterableObject>({
    system: true,
    os: false,
    cpu: false,
    graphic_controllers: false,
    graphic_displays: false,
    bios: false,
    versions: false,
    disks: false,
    time: false,
    wifi_networks: false,
    mem: false,
  });

  const toggleSectionVisibility = useCallback(
    (key: string) => {
      setIsSectionVisible((state) => ({
        ...state,
        [key]: !state[key],
      }));
    },
    [setIsSectionVisible],
  );

  const renderRow = (label: ReactRenderElement, value: ReactRenderElement) => {
    return (
      <Typography
        component='div'
        display='flex'
        fontSize={14}
        justifyContent={overview ? 'space-between' : 'flex-start'}
        style={{ padding: '2px 0' }}
      >
        <span
          style={{
            width: overview ? 100 : 300,
            minWidth: overview ? 100 : 300,
            opacity: 0.6,
          }}
        >
          {label}
        </span>{' '}
        <span
          style={{
            wordBreak: 'break-word',
            textAlign: overview ? 'right' : 'left',
          }}
        >
          {value}
        </span>
      </Typography>
    );
  };

  const renderTitle = (
    title: ReactRenderElement,
    Icon: any,
    visible?: boolean,
    onClick?: () => void,
  ) => {
    return (
      <Typography
        display='flex'
        alignItems='center'
        component='div'
        fontSize={18}
        style={{
          marginLeft: -26,
          marginBottom: 8,
        }}
      >
        <Icon
          style={{
            fontSize: 22,
            opacity: 0.5,
            marginRight: 4,
          }}
        />
        <span style={{ flex: 1 }}>{title}</span>
        {overview && (
          <ExpandMoreOutlinedIcon
            onClick={onClick}
            style={{
              opacity: 0.6,
              cursor: 'pointer',
              userSelect: 'none',
              transform: `rotateZ(${visible ? 0 : -90}deg)`,
            }}
          />
        )}
      </Typography>
    );
  };

  const renderObjectToRows = (
    obj?: IterableObject | null,
    keys?: string[],
    opt?: any,
  ) => {
    let { transform } = opt || {};

    if (!obj) return null;

    return Object.keys(obj)
      .filter((key) => !keys?.includes(key))
      .map((key, index) => {
        let value = obj[key];
        let skip = false;
        if (transform) {
          skip = true;
          value = transform(value, key, obj);
        }
        if (typeof value === 'object' && !skip) {
          value = value ? JSON.stringify(value) : '';
        } else if (typeof value === 'boolean' && !skip) {
          value = value && (
            <CheckOutlinedIcon style={{ fontSize: 18, opacity: 0.8 }} />
          );
        }
        return (
          <Fragment key={index}>
            {renderRow(toTitleCase(stripToFormatterWord(key)), value)}
          </Fragment>
        );
      });
  };

  const sectionStyle: CSSProperties = {
    padding: overview ? '8px 0px 16px' : '8px 32px 24px',
  };
  const renderComputer = ({
    title,
    systemInfo: {
      system,
      bios,
      os,
      versions,
      cpu,
      graphics,
      diskLayout,
      time,
      wifiNetworks,
      mem,
    },
  }: any) => {
    return (
      <Box>
        <Typography
          gap={1}
          display='flex'
          alignItems='center'
          component='div'
          fontSize={22}
          fontWeight={500}
          style={{
            paddingBottom: 16,
            marginLeft: overview ? -30 : 0,
          }}
        >
          <ComputerOutlinedIcon
            style={{
              fontSize: overview ? 22 : 24,
              opacity: 0.5,
            }}
          />
          <span style={{ flex: 1 }}>{title}</span>
          {overview && (
            <LaunchOutlinedIcon
              onClick={onMoreComputerClick}
              style={{
                fontSize: 20,
                opacity: 0.6,
                cursor: 'pointer',
                userSelect: 'none',
              }}
            />
          )}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            'System',
            LightbulbOutlinedIcon,
            isSectionVisible.system,
            () => toggleSectionVisibility('system'),
          )}
          {(!overview || (overview && isSectionVisible.system)) &&
            renderObjectToRows(system)}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle('OS', AutoAwesomeOutlinedIcon, isSectionVisible.os, () =>
            toggleSectionVisibility('os'),
          )}
          {(!overview || (overview && isSectionVisible.os)) &&
            renderObjectToRows(os)}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            'CPU',
            DeveloperBoardOutlinedIcon,
            isSectionVisible.cpu,
            () => toggleSectionVisibility('cpu'),
          )}
          {(!overview || (overview && isSectionVisible.cpu)) &&
            renderObjectToRows(cpu, ['cache'])}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle('Memory', MemoryOutlinedIcon, isSectionVisible.mem, () =>
            toggleSectionVisibility('mem'),
          )}
          {(!overview || (overview && isSectionVisible.mem)) &&
            renderObjectToRows(mem, [], {
              transform: (value: any, key: string, obj: IterableObject) => {
                return typeof value === 'number' ? (
                  <span style={{ opacity: value ? 1 : 0.5 }}>
                    {formatBytes(value)}
                  </span>
                ) : (
                  value
                );
              },
            })}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            <>
              Controllers{' '}
              <span style={{ fontSize: 12, opacity: 0.4 }}>(Graphic)</span>
            </>,
            DisplaySettingsOutlinedIcon,
            isSectionVisible.graphic_controllers,
            () => toggleSectionVisibility('graphic_controllers'),
          )}
          {(!overview ||
            (overview && isSectionVisible.graphic_controllers)) && (
            <Typography component='div'>
              {extractGraphicControllers(graphics).map(
                (
                  controller: GraphicControllerElement,
                  index: number,
                  arr: GraphicControllerElement[],
                ) => {
                  return (
                    <Box key={index} mb={index !== arr.length - 1 ? 2 : 0}>
                      <Typography component='div' fontSize={15}>
                        {controller.model}
                      </Typography>
                      {renderObjectToRows(controller)}
                    </Box>
                  );
                },
              )}
            </Typography>
          )}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            <>
              Displays{' '}
              <span style={{ fontSize: 12, opacity: 0.4 }}>(Graphic)</span>
            </>,
            DvrOutlinedIcon,
            isSectionVisible.graphic_displays,
            () => toggleSectionVisibility('graphic_displays'),
          )}
          {(!overview || (overview && isSectionVisible.graphic_displays)) && (
            <Typography component='div'>
              {extractGraphicDisplays(graphics).map(
                (
                  display: GraphicDisplayElement,
                  index: number,
                  arr: GraphicDisplayElement[],
                ) => {
                  return (
                    <Box key={index} mb={index !== arr.length - 1 ? 2 : 0}>
                      <Typography component='div' fontSize={15}>
                        {display.model}
                      </Typography>
                      {renderObjectToRows(display)}
                    </Box>
                  );
                },
              )}
            </Typography>
          )}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle('Bios', OutputOutlinedIcon, isSectionVisible.bios, () =>
            toggleSectionVisibility('bios'),
          )}
          {(!overview || (overview && isSectionVisible.bios)) &&
            renderObjectToRows(bios)}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            'Versions',
            ChangeHistoryOutlinedIcon,
            isSectionVisible.versions,
            () => toggleSectionVisibility('versions'),
          )}
          {(!overview || (overview && isSectionVisible.versions)) &&
            renderObjectToRows(versions)}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            'Disks',
            StorageOutlinedIcon,
            isSectionVisible.disks,
            () => toggleSectionVisibility('disks'),
          )}
          {(!overview || (overview && isSectionVisible.disks)) && (
            <Typography component='div'>
              {extractDiskLayouts(diskLayout).map(
                (
                  disk: DiskLayoutElement,
                  index: number,
                  arr: DiskLayoutElement[],
                ) => {
                  return (
                    <Box key={index} mb={index !== arr.length - 1 ? 2 : 0}>
                      <Typography component='div' fontSize={15}>
                        {disk.name}
                      </Typography>
                      {renderObjectToRows(disk)}
                    </Box>
                  );
                },
              )}
            </Typography>
          )}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            'Time',
            AccessTimeOutlinedIcon,
            isSectionVisible.time,
            () => toggleSectionVisibility('time'),
          )}
          {(!overview || (overview && isSectionVisible.time)) &&
            renderObjectToRows(time, ['current', 'uptime'])}
        </Typography>
        <Typography component='div' style={sectionStyle}>
          {renderTitle(
            'Wifi Networks',
            WifiOutlinedIcon,
            isSectionVisible.wifi_networks,
            () => toggleSectionVisibility('wifi_networks'),
          )}
          {(!overview || (overview && isSectionVisible.wifi_networks)) && (
            <Typography component='div'>
              {extractWifiNetworks(wifiNetworks).map(
                (
                  wifi: WifiNetworkElement,
                  index: number,
                  arr: WifiNetworkElement[],
                ) => {
                  return (
                    <Box key={index} mb={index !== arr.length - 1 ? 2 : 0}>
                      <Typography component='div' fontSize={15}>
                        {wifi.ssid}
                      </Typography>
                      {renderObjectToRows(wifi, [
                        'rsnFlags',
                        'security',
                        'wpaFlags',
                      ])}
                    </Box>
                  );
                },
              )}
            </Typography>
          )}
        </Typography>
      </Box>
    );
  };

  const renderComputerPlaceholder = () => {
    return null;
  };

  return (
    <Box
      style={{
        padding: overview ? '0px 0px 0px 18px' : '20px 26px',
      }}
    >
      {userSystemInfo
        ? renderComputer({
            title: userSystemInfo.system_info?.os.hostname,
            visible: true,
            systemInfo: userSystemInfo.system_info,
          })
        : renderComputerPlaceholder()}
    </Box>
  );
};

export default UserComputer;

function extractGraphicControllers(graphics: any) {
  let controllers = [];

  if (Array.isArray(graphics.controllers)) {
    controllers = graphics.controllers;
  } else if (graphics.controllers && typeof graphics.controllers === 'object') {
    graphics.controllers.list?.forEach((obj: any) => {
      if (obj?.element) {
        controllers.push(obj?.element);
      }
    });
  }

  return controllers;
}

function extractGraphicDisplays(graphics: any) {
  let displays: any[] = [];

  if (Array.isArray(graphics.displays)) {
    displays = graphics.displays;
  } else if (graphics.displays && typeof graphics.displays === 'object') {
    graphics.displays.list?.forEach((obj: any) => {
      if (obj?.element) {
        displays.push(obj?.element);
      }
    });
  }

  return displays;
}

function extractDiskLayouts(diskLayout: any) {
  let diskLayouts: any[] = [];

  if (Array.isArray(diskLayout)) {
    diskLayouts = diskLayout;
  } else if (diskLayout && typeof diskLayout === 'object') {
    diskLayout.list?.forEach((obj: any) => {
      if (obj?.element) {
        diskLayouts.push(obj?.element);
      }
    });
  }

  return diskLayouts;
}

function extractWifiNetworks(wifiNetwork: any) {
  let wifiNetworks: any[] = [];

  if (Array.isArray(wifiNetwork)) {
    wifiNetworks = wifiNetwork;
  } else if (wifiNetwork && typeof wifiNetwork === 'object') {
    wifiNetwork.list?.forEach((obj: any) => {
      if (obj?.element) {
        wifiNetworks.push(obj?.element);
      }
    });
  }

  return wifiNetworks;
}
