import { useEffect, useState } from 'react';
import moment from 'moment';

import { useTimezone } from '../Util/Timezone';

import { useTick } from '../../hooks/utils/tick';
import { useTimeTrackerWorklogs } from '../../hooks/time-tracker/worklogs';
import { TimeTrackerWorklogItem } from '../../types/time-tracker-worklogs';

type Props = {
  userId?: number | string;
};

export function useUserLastOnlineStatus({ userId }: Props) {
  const [lastWorklog, setLastWorklog] = useState<TimeTrackerWorklogItem>();
  const timezone = useTimezone();
  const { data, isLoading } = useTimeTrackerWorklogs(
    {
      key: 'user-last-online',
      users: userId?.toString(),
      start_date: moment().startOf('year').format(),
      end_date: moment().endOf('year').format(),
      timezone,
      limit: 1,
    },
    {
      enabled: !!userId,
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: true,
    },
  );
  const wl = data?.results?.[0];
  const date = lastWorklog ? moment.utc(lastWorklog?.end).local() : moment();

  useTick();

  useEffect(() => {
    setLastWorklog((lastWl) => wl || lastWl);
  }, [wl, setLastWorklog]);

  return {
    lastOnline: lastWorklog
      ? moment().diff(date, 'minute') < 1
        ? 'few seconds ago'
        : date.fromNow()
      : '',
    isOnline: lastWorklog ? moment().diff(date, 'minute') <= 1 : false,
    isLoading,
  };
}
