export type AppWebItemType = 'app' | 'web' | 'unknown';

export function getAppWebType(text: string): AppWebItemType {
  // Check for app extensions
  if (/\.(app|exe)$/i.test(text)) {
    return 'app';
  }

  // Check for valid URL or domain
  const urlPattern =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i; // eslint-disable-line
  if (urlPattern.test(text)) {
    return 'web';
  }

  // If it's neither an app nor a web URL
  return 'unknown';
}
