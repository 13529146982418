import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { TextFieldProps } from '@mui/material/TextField';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { M3TextField } from '../M3/M3TextField';

type Props = {
  label?: string;
  format?: string | undefined;
  value?: string | null | Moment;
  defaultValue?: string | null | Moment;
  minDate?: Moment | any;
  maxDate?: Moment | any;
  readOnly?: boolean;
  disableFuture?: boolean;
  textFieldProps?: TextFieldProps;
  datePickerProps?: Partial<DatePickerProps<any, any>>;
  onChange?: (date: Moment | null) => void;
  shouldDisableDate?: (date: Moment | null) => boolean;
};

const BasicDatePicker = ({
  label = 'Date',
  format = undefined,
  defaultValue = null,
  value: _value,
  textFieldProps,
  minDate,
  maxDate,
  readOnly,
  disableFuture,
  onChange,
  shouldDisableDate,
  datePickerProps,
}: Props) => {
  const [value, setValue] = useState<string | null | Moment>(
    _value || defaultValue,
  );

  return (
    <DatePicker
      label={label}
      value={_value || value}
      inputFormat={format}
      toolbarFormat={format}
      minDate={minDate || undefined}
      maxDate={maxDate || undefined}
      readOnly={readOnly}
      disableFuture={disableFuture}
      onChange={(newValue) => {
        let m = moment(newValue);
        if (m.isValid()) {
          setValue(newValue);
          onChange?.(m.clone());
        } else {
          setValue(newValue);
          onChange?.(null);
        }
      }}
      shouldDisableDate={shouldDisableDate}
      PaperProps={{
        classes: {
          root: 'm3-datepicker-calendar-paper',
        },
      }}
      renderInput={(params) => (
        <M3TextField
          fullWidth
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          autoSave='off'
          {...params}
          {...textFieldProps}
          sx={{
            ...textFieldProps?.sx,
          }}
          className='datepicker-m3-textfield'
        />
      )}
      {...datePickerProps}
    />
  );
};

export default BasicDatePicker;
