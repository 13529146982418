import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import { M3IconButton } from '../../components/M3/M3Button';
import UserComputer from '../../components/Computers/UserComputer';
import { useUserLastOnlineStatus } from '../../components/User/UserLastOnlineStatus';
import WebAppSettings from '../../components/Settings/UserWebAppConfigurations/WebAppSettings';
import { useModal } from '../../components/M3/M3Modal';

import { AgentUser } from '../Users/types';
import { useAppProvider } from '../../providers/app/app';
import {
  getUserDisplayName,
  getUserInitials,
  getUserPhotoUrl,
} from '../../utils/user';
import { UserMetadata } from '../../types/profile';
import { UserSystemInfo } from '../../types/system-info';

type UserMetadataProps = {
  item?: AgentUser | null;
  user?: UserMetadata;
  userId?: number | string;
  userSystemInfo?: UserSystemInfo | null;
};

const UserMetadataView = ({
  item,
  user,
  userId,
  userSystemInfo,
}: UserMetadataProps) => {
  const { isDarkMode } = useAppProvider();
  const {
    isLoading: onlineIsLoading,
    lastOnline,
    isOnline,
  } = useUserLastOnlineStatus({ userId });

  const userWebAppConfigurationsModal = useModal();

  const renderActionButton = ({
    title,
    icon,
    onClick,
    disabled,
    active,
  }: any) => {
    return (
      <Tooltip title={title} style={{ marginLeft: 4 }}>
        <Box>
          <M3IconButton
            size='small'
            onClick={onClick}
            disabled={disabled}
            active={active}
          >
            {icon}
          </M3IconButton>
        </Box>
      </Tooltip>
    );
  };

  const renderUserActionPanels = () => {
    return (
      <>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          style={{
            marginTop: -28,
            marginRight: -12,
            marginBottom: 12,
          }}
        >
          <Typography
            flex={1}
            fontSize={14}
            component='div'
            style={{ opacity: 0.5 }}
          >
            <Typography component='div' fontSize={13}>
              {onlineIsLoading ? (
                <Skeleton variant='text' style={{ width: 120 }} />
              ) : (
                `Last online: ${lastOnline}`
              )}
            </Typography>
          </Typography>
          {renderActionButton({
            disabled: true,
            title: (
              <>
                Watch Live <span style={{ opacity: 0.5 }}>(Soon)</span>
              </>
            ),
            icon: <LiveTvOutlinedIcon style={{ fontSize: 20 }} />,
          })}
          {renderActionButton({
            title: 'User Configurations',
            icon: (
              <ManageAccountsOutlinedIcon
                style={{ fontSize: 22, marginRight: -2 }}
              />
            ),
            active: userWebAppConfigurationsModal.isOpen,
            onClick: userWebAppConfigurationsModal.open,
          })}
        </Stack>
        <WebAppSettings
          userId={userId ? +userId : null}
          modal={userWebAppConfigurationsModal}
        />
      </>
    );
  };

  return (
    <Box
      pt={4}
      pb={4}
      position='relative'
      sx={{
        width: 300,
        minWidth: 300,
      }}
    >
      {renderUserActionPanels()}
      <Box
        display='flex'
        justifyContent='center'
        sx={{
          position: 'relative',
        }}
      >
        <Box position='relative'>
          <Avatar
            sx={{
              width: 160,
              height: 160,
              fontSize: 60,
              margin: '0 auto',
            }}
            src={getUserPhotoUrl(user?.photo_url)}
          >
            {user ? getUserInitials(user.first_name).initials : ''}
          </Avatar>
          <Tooltip title={isOnline ? 'Online' : 'Offline'}>
            <Box
              sx={{
                width: 12,
                height: 12,
                right: 14,
                bottom: 18,
                position: 'absolute',
                borderRadius: '50%',
                background: isOnline
                  ? 'green'
                  : isDarkMode
                  ? '#444748'
                  : '#e1e3e3',
                boxShadow: `0 0 0 3px ${
                  isDarkMode
                    ? 'var(--md-sys-color-background-dark)'
                    : 'var(--md-sys-color-on-primary-light)'
                }`,
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        // alignItems='center'
        pt={1}
        pb={1}
      >
        <Typography fontSize={22} fontWeight={500}>
          {user ? (
            getUserDisplayName(user).fullName
          ) : (
            <Skeleton variant='text' style={{ width: 160 }} />
          )}
        </Typography>
        <Typography component='div' fontSize={14} style={{ opacity: 0.5 }}>
          {user ? (
            `Email: ${user?.email}`
          ) : (
            <Skeleton variant='text' style={{ width: 120 }} />
          )}
        </Typography>
        <Typography component='div' fontSize={14} style={{ opacity: 0.5 }}>
          {userSystemInfo ? (
            `Go2 version: v${userSystemInfo.go2_version}`
          ) : (
            <Skeleton variant='text' style={{ width: 120 }} />
          )}
        </Typography>
        {/* <Typography component='div' fontSize={13} style={{ opacity: 0.5 }}>
          {onlineIsLoading ? (
            <Skeleton variant='text' style={{ width: 120 }} />
          ) : (
            `Last online: ${lastOnline}`
          )}
        </Typography> */}
      </Box>
      <Divider />
      <br />
      <UserComputer userSystemInfo={userSystemInfo} />
    </Box>
  );
};

export default UserMetadataView;
