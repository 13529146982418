import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import * as Sentry from '@sentry/react';

import {
  fetchAuthRefreshToken,
  useAuthCodeTokens,
  useAuthRefreshToken,
} from '../../hooks/services/auth';
import { ReactRenderElement } from '../../types/types';
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from '../../utils/localstorage';
import { parseURL, toURL } from '../../utils/url';

type AuthProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type AuthInitialState = {
  isAuthenticated: boolean;
  isDoneAuthenticating: boolean;
  error?: Error | null;
  tokens?: null | AuthTokens;
  codeState: null | CodeState;
  debugMode?: boolean;
};
type AuthTokens = {
  id_token: string;
  refresh_token: string;
  expires_in?: number;
  expiry_date?: string;
};
type CodeState = {
  code: string | null;
  auth_state: string | null;
};
type TokenCacheRequest = {
  isRefreshing: boolean;
  resolveCallbacks: ((value: any) => any)[];
  rejectCallbacks: ((value: any) => any)[];
};
/**
 * Reducers that mutate the state
 */
type AuthReducers = {
  getTokenSilently: () => Promise<string>;
};
/**
 * Single store
 */
type AuthStore = AuthInitialState & AuthReducers;
let parsedURL = parseURL(window.location.search);
/**
 * Initial state / store
 */
const initialStore: AuthStore = {
  isAuthenticated: false,
  isDoneAuthenticating: false,
  error: null,
  tokens:
    typeof parsedURL.auth_tokens !== 'undefined'
      ? parsedURL.auth_tokens
      : getFromLocalStorage('auth-tokens') ?? null,
  codeState: null,
  getTokenSilently: () => {
    throw new Error('Implementation required');
  },
  debugMode: parseURL(window.location.search).debug_mode,
};
/**
 * Context Instance
 */
const AuthContext = createContext<AuthStore>(initialStore);

export function useAuthProvider(): AuthStore {
  return useContext(AuthContext);
}

export function useAuthForm() {
  const getLoginRedirectUrl = useCallback(() => {
    const authState = uuid();
    const url = toURL(`${process.env.REACT_APP_AUTH_URL}/login`, {
      redirect_uri: window.location.origin,
      auth_state: authState,
    });
    // before redirecting to the auth page, create a state first to match later when it comes back
    setToLocalStorage('auth-state', authState);
    return url;
  }, []);

  const onLoginClickHandler = useCallback(() => {
    window.location.href = getLoginRedirectUrl();
  }, [getLoginRedirectUrl]);

  const onLogoutClickHandler = useCallback(() => {
    removeFromLocalStorage('auth-tokens');
    removeFromLocalStorage('auth-state');
    removeFromLocalStorage('firebase-token');

    const url = toURL(`${process.env.REACT_APP_AUTH_URL}/logout`, {
      return_to: getLoginRedirectUrl(),
    });
    window.location.href = url;
  }, [getLoginRedirectUrl]);

  return {
    login: onLoginClickHandler,
    logout: onLogoutClickHandler,
  };
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, setState] = useState<AuthStore>(initialStore);
  const navigate = useNavigate();
  const { login } = useAuthForm();

  const refTokens = useRef(state.tokens);
  refTokens.current = state.tokens || refTokens.current;

  const [initCodeState] = useState<CodeState>(parseURL(window.location.search));

  const authRefreshToken = useAuthRefreshToken();
  const authCodeTokens = useAuthCodeTokens<AuthTokens>();

  const tokenCacheRequestRef = useRef<TokenCacheRequest>({
    isRefreshing: false,
    resolveCallbacks: [],
    rejectCallbacks: [],
  });

  // Gets the token silently when it's already/about to expire
  const getTokenSilently: () => Promise<string> = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      /**
       * Check if there's already an ongoing of refreshing of token,
       * then we queue all the succeeding callbacks
       */
      if (tokenCacheRequestRef.current.isRefreshing) {
        tokenCacheRequestRef.current.resolveCallbacks.push(resolve);
        tokenCacheRequestRef.current.rejectCallbacks.push(reject);
      } else {
        try {
          // check the expiration of the tokens first if it's still valid
          if (isUserTokenValid(refTokens.current?.expiry_date)) {
            resolve(refTokens.current?.id_token!);
          } else {
            throw new Error('Token has expired');
          }
        } catch (e) {
          try {
            /**
             * Check if no refresh token found when getSilently was called,
             * This should not be happening...
             */
            if (!refTokens.current?.refresh_token) {
              throw new Error('No refresh token found');
            }

            /**
             * Let's try to refresh the token first
             */
            tokenCacheRequestRef.current.isRefreshing = true;
            const result = await fetchAuthRefreshToken({
              refresh_token: refTokens.current?.refresh_token!,
              id_token: refTokens.current?.id_token,
              platform: 'web',
            });
            const tokens = parseTokens(
              refTokens.current,
              result as Partial<AuthTokens>,
            );
            refTokens.current = tokens;

            // set back the flag after refreshing
            tokenCacheRequestRef.current.isRefreshing = false;

            setState((state) => ({ ...state, tokens }));
            resolve(tokens.id_token);

            /**
             * Execute all resolve callbacks with the updated token
             */
            tokenCacheRequestRef.current.resolveCallbacks.forEach((cb) =>
              cb(tokens.id_token),
            );
            tokenCacheRequestRef.current.resolveCallbacks = [];
            tokenCacheRequestRef.current.rejectCallbacks = [];
          } catch (e) {
            Sentry.captureMessage(`Get Token Silently Failed: ${uuid()}`, {
              extra: {
                ...refTokens.current,
                platform: 'web',
                error: e,
              },
            });

            // set back the flag after it fails
            tokenCacheRequestRef.current.isRefreshing = false;

            reject(e);
            /**
             * Execute all reject callbacks with same error
             */
            tokenCacheRequestRef.current.rejectCallbacks.forEach((cb) => cb(e));
            tokenCacheRequestRef.current.rejectCallbacks = [];
            tokenCacheRequestRef.current.resolveCallbacks = [];
          }
        }
      }
    });
  }, [refTokens, tokenCacheRequestRef, setState]);

  /**
   * Sets the flag and tokens from the state it will return
   * a dispatcher that will be passed to the setState
   */
  const setStateTokens =
    (authResponse: AuthTokens) =>
    (state: AuthStore): AuthStore => {
      state = { ...state };
      state.tokens = parseTokens(state.tokens, authResponse);
      state.isAuthenticated = !!state.tokens;
      state.isDoneAuthenticating = state.isAuthenticated;
      refTokens.current = state.tokens;
      return state;
    };

  /**
   * Check page on load if there's code/state
   */
  useEffect(() => {
    if (!state.isAuthenticated && !state.isDoneAuthenticating) {
      if (initCodeState?.code) {
        setState((state) => ({ ...state, codeState: { ...initCodeState } }));
      } else if (!refTokens.current) {
        login();
      }
    }
    // eslint-disable-next-line
  }, [
    refTokens,
    initCodeState,
    state.isAuthenticated,
    state.isDoneAuthenticating,
  ]);

  /**
   * After redirecting from the authentication page, it will received the
   * code/state params, let's consume the code and swap it out with tokens
   */
  useEffect(() => {
    if (state.codeState?.code) {
      authCodeTokens.mutate({
        code: state.codeState.code!,
      });
    }
    // eslint-disable-next-line
  }, [state.codeState?.code]);

  /**
   * When successfully consuming the code and being swapped with actual tokens
   */
  useEffect(() => {
    if (authCodeTokens.data) {
      setState(setStateTokens(authCodeTokens.data as unknown as AuthTokens));
    }
    // eslint-disable-next-line
  }, [refTokens, authCodeTokens.data]);

  /**
   * Check when tokens will be updated, saved it to localstorage to be accessed later
   */
  useEffect(() => {
    if (state.tokens) {
      refTokens.current = state.tokens;
      setToLocalStorage('auth-tokens', state.tokens);
      setState((state) => ({
        ...state,
        isAuthenticated: !!state.tokens,
        isDoneAuthenticating: !!state.tokens,
      }));
    }
    // eslint-disable-next-line
  }, [state.tokens, refTokens]);

  /**
   * When token refreshed, update the state
   */
  useEffect(() => {
    if (authRefreshToken.data) {
      setState(setStateTokens(authRefreshToken.data as unknown as AuthTokens));
    }
    // eslint-disable-next-line
  }, [refTokens, authRefreshToken.data]);

  /**
   * When successfully authenticated and have the initial code/state
   * Redirect the user to the main page
   */
  useEffect(() => {
    if (state.isDoneAuthenticating && initCodeState?.code) {
      navigate('/', {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, [state.isDoneAuthenticating, initCodeState]);

  /**
   * Define all side effects here...
   */
  return (
    <AuthContext.Provider
      value={{
        ...state,
        getTokenSilently,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function isUserTokenValid(expiry_date?: string) {
  if (!expiry_date) return false;
  // when the token is about to expired within about 20 minutes
  const threshold = 20 * 60 * 1000;
  // check if it's about to expire
  return new Date(new Date(expiry_date).getTime() - threshold) > new Date();
}

function parseTokens(
  tokens?: null | AuthTokens,
  response?: Partial<AuthTokens>,
) {
  tokens = {
    ...tokens,
    id_token: (response?.id_token || tokens?.id_token)!,
    refresh_token: (response?.refresh_token || tokens?.refresh_token)!,
    expires_in: +(response?.expires_in ?? 3600),
  };

  // store the expiry date
  tokens!.expiry_date = new Date(
    Date.now() + tokens!.expires_in! * 1000,
  ).toString();

  return tokens;
}
