import React, { forwardRef } from 'react';
import { MenuItem, styled, MenuItemProps } from '@mui/material';
import clsx from 'clsx';
import { useAppProvider } from '../../providers/app/app';

export const M3MenuItemBase = styled(MenuItem)`
  &.dark-mode-menu {
    color: var(--md-sys-color-on-background-dark);

    &.Mui-selected {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.04)
        ),
        var(--md-ref-palette-secondary30);
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.08),
          rgba(255, 255, 255, 0.08)
        ),
        var(--md-ref-palette-secondary30);
    }
  }
`;

type M3IconButtonProps = MenuItemProps & { active?: boolean };
export const M3MenuItem = forwardRef(
  ({ className, selected, active, ...restProps }: M3IconButtonProps, ref) => {
    const { isDarkMode } = useAppProvider();

    return (
      <M3MenuItemBase
        {...restProps}
        selected={selected || active}
        className={clsx(
          {
            active,
            'dark-mode-menu': isDarkMode,
          },
          className,
        )}
      />
    );
  },
);
