import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import {
  UserMetadata,
  UserProfile,
  UserSearchItemResponse,
} from '../types/profile';
import { defaultReactQueryParams } from '../utils/request';
import { useMutationApi, useQueryApi } from './global/useApi';
import { useIdentifier } from './global/useIdentifier';
import { BasicQuery } from '../types/request';

/**
 * Return current logged in user's profile
 */
type UseCurrentProfileProps = {};
export function useCurrentProfile(
  props: UseCurrentProfileProps = {} as UseCurrentProfileProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseCurrentProfileProps>({
    params: props,
    baseIdentifier: 'useCurrentProfile',
  });

  return useQueryApi<any, UserProfile>(
    identifiers,
    '/users/me/',
    queryParams,
    {
      ...defaultReactQueryParams,
      keepPreviousData: true,
      enabled: false,
      ...reactQueryParams,
    },
    { signal: undefined },
  );
}

type UseUserDataProps = {
  key: string;
  user_ids?: string;
  staff_ids?: string;
  employee_ids?: string;
  partner_ids?: string;
  partner_member_ids?: string;
};
export function useUserData<T = UserMetadata>(
  params: UseUserDataProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseUserDataProps>({
    params,
    baseIdentifier: 'useUserData',
    identifierKeys: [
      'user_ids',
      'staff_ids',
      'employee_ids',
      'partner_ids',
      'partner_member_ids',
    ],
    queryParamKeys: [
      'user_ids',
      'staff_ids',
      'employee_ids',
      'partner_ids',
      'partner_member_ids',
    ],
  });

  return useQueryApi<UseUserDataProps, T[]>(
    identifiers,
    '/users/get-users-data/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}

export function usePostUserSettings(
  id?: number,
  axiosConfig?: AxiosRequestConfig,
) {
  return useMutationApi(
    `/users/settings/${id ? `${id}/` : ''}`,
    {},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...axiosConfig,
    },
  );
}

export type UseUserSearchProps = BasicQuery & {
  s: string;
};
export function useUserSearch<R = UserSearchItemResponse>(
  params: UseUserSearchProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseUserSearchProps>({
    params,
    baseIdentifier: 'useUserSearch',
    identifierKeys: ['s'],
    queryParamKeys: ['s'],
  });

  return useQueryApi<any, R[]>(identifiers, '/users/search/', queryParams, {
    ...defaultReactQueryParams,
    ...reactQueryParams,
  });
}
