import { IterableObject } from '../types/types';

// put commas into number greater than 999
export function dgs(
  number: string | number,
  precision?: string,
  delimiter?: string,
) {
  number = parseFloat(number as string) || 0;

  if (typeof precision === 'number') {
    return String(Number(number.toFixed(number === 0 ? 0 : precision))).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      typeof delimiter === 'string' ? delimiter : ',',
    );
  } else {
    return String(number).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      typeof delimiter === 'string' ? delimiter : ',',
    );
  }
}

export function stripToFormatterWord(str: string) {
  return (str || '').replace(/[_-]+/g, ' ');
}

// To title case the string, Upper cases the first letter in each word
export function toTitleCase(str?: string) {
  return String(str || '').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export function toTitleCaseFirst(str: string) {
  str = String(str || '');
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
}

// converts dashed words to Pascal Case
// to upper case match words and replace all dash and underscore
export function toPascalCase(str: string) {
  return toTitleCase(str || '').replace(
    /[-_][a-zA-Z0-9]{1}/gi,
    (txt: string) => {
      return txt.toUpperCase().replace(/-|_/g, '');
    },
  );
}

// converts dashed words to Camelcase
export function toCamelCase(str: string) {
  str = toPascalCase(str || '');
  return str.charAt(0).toLowerCase() + str.substring(1);
}

export function escapeRegExp(string = '') {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const si = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];
export function numberFormatter(num: number, digits: number = 0) {
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  let result: number | string = num / si[i].value;
  if (typeof digits === 'number') {
    result = result.toFixed(digits);
  }
  return `${result}`.replace(rx, '$1') + si[i].symbol;
}

export function stringToKey(str: string, replaceWith: string = ''): string {
  // Convert the string to lowercase and replace any spaces or non-alphanumeric characters
  let key = str.toLowerCase().replace(/[^a-z0-9]+/g, replaceWith);
  return key;
}

export function convertTimeToNumber(timeString: string, format = 'ms') {
  const units: IterableObject<number> = {
    ms: 1, // Conversion factor for milliseconds (1 millisecond = 1/1000 second)
    s: 1000, // Conversion factor for seconds (1 second = 1000 milliseconds)
    m: 60 * 1000, // Conversion factor for minutes (1 minute = 60 seconds = 60,000 milliseconds)
    h: 3600 * 1000, // Conversion factor for hours (1 hour = 60 minutes = 3,600 seconds = 3,600,000 milliseconds)
    d: 86400 * 1000, // Conversion factor for days (1 day = 24 hours = 86,400 seconds = 86,400,000 milliseconds)
  };
  const regex = /^(\d+)(\w{1,2})$/;

  const match = regex.exec(timeString.trim());

  if (!match) {
    throw new Error('Invalid time format');
  }

  const [, time, unit] = match;

  if (!units.hasOwnProperty(unit)) {
    throw new Error('Invalid time unit');
  }

  const value = parseFloat(time) * units[unit];

  if (!units.hasOwnProperty(format)) {
    throw new Error('Invalid format');
  }

  return value / units[format];
}

export function escapeHtml(unsafe?: string) {
  return (unsafe ?? '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
