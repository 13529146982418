import React, {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BasicPopoverWrapper, {
  PopoverItem,
  useBasicPopover,
} from '../Popover/BasicPopover';

type UseSpeedControlRet = {
  speed: number;
  ref: MutableRefObject<number>;
  onChange: (speed: number) => void;
};
export function useSpeedControl(defaultSpeed: number = 1): UseSpeedControlRet {
  const [speed, setSpeed] = useState(defaultSpeed);
  const ref = useRef(speed); // Playback speed, 1 is normal speed

  const handleOnSpeedChange = useCallback(
    (speed: number) => {
      ref.current = speed;
      setSpeed(speed);
    },
    [ref, setSpeed],
  );

  return useMemo(
    () => ({
      ref,
      speed,
      onChange: handleOnSpeedChange,
    }),
    [ref, speed, handleOnSpeedChange],
  );
}

type SpeedControlProps = {
  speed: number;
  onChange?: (speed: number) => void;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  playBackRates?: number[];
  withCaret?: boolean;
};
function SpeedControl({
  speed,
  onChange,
  style,
  contentStyle,
  playBackRates = [0.5, 1, 2, 4, 8, 16],
  withCaret = true,
}: SpeedControlProps) {
  const speedOptions: PopoverItem[] = useMemo(() => {
    return playBackRates.map((n) => ({
      id: n,
      label: `${n}x`,
    }));
  }, [playBackRates]);
  const selected = useMemo(() => {
    return (
      speedOptions.find((opt) => opt.id === speed) ?? {
        id: speed,
        label: `${speed}x`,
      }
    );
  }, [speedOptions, speed]);
  const speedPopover = useBasicPopover();

  const handleOnSpeedChange = useCallback(
    (item: PopoverItem) => {
      speedPopover.close();
      onChange?.(item.id as number);
    },
    [speedPopover, onChange],
  );

  return (
    <Box position='relative'>
      <Box
        ref={speedPopover.ref}
        style={{
          top: 16,
          left: -85,
          cursor: 'pointer',
          position: 'absolute',
          ...style,
        }}
        onClick={speedPopover.open}
      >
        <Typography
          fontSize={13}
          fontWeight={500}
          display='flex'
          alignItems='center'
          component='div'
          style={{
            opacity: 0.8,
            ...contentStyle,
          }}
        >
          <span>{selected.id}x&nbsp;</span>
          {withCaret && (
            <ExpandMoreOutlinedIcon
              style={{
                fontSize: 18,
                opacity: 0.5,
              }}
            />
          )}
        </Typography>
      </Box>
      <BasicPopoverWrapper
        width={44}
        usePerfectScrollbar={false}
        open={speedPopover.isOpen}
        anchorEl={speedPopover.ref.current}
        selected={selected}
        itemHeight={28}
        items={speedOptions}
        itemSx={{
          paddingLeft: 0,
          paddingRight: 0,
          '.MuiTypography-root': {
            fontSize: 12,
            textAlign: 'center',
          },
        }}
        maxRowVisible={10}
        popoverProps={{
          anchorOrigin: {
            vertical: -4,
            horizontal: -14,
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        onSelect={handleOnSpeedChange}
        onClose={speedPopover.close}
      />
    </Box>
  );
}

export default SpeedControl;
