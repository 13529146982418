import { IterableObject } from '../types/types';

export function sortBy<T>(
  items: T[] = [],
  key?: keyof T | null,
  toUpperCase?: boolean,
  order: 'asc' | 'desc' | 'ASC' | 'DESC' = 'asc',
  type?: 'date_string' | 'date' | 'number',
): T[] {
  order = order.toUpperCase() === 'ASC' ? 'ASC' : 'DESC';
  return items
    .sort((a, b) => {
      let aValue: any = a;
      let bValue: any = b;
      if (typeof aValue === 'object' && key) {
        const aV: any = aValue[key];
        const bV: any = bValue[key];
        aValue = typeof aV === 'string' && toUpperCase ? aV.toUpperCase() : aV;
        bValue = typeof bV === 'string' && toUpperCase ? bV.toUpperCase() : bV;
      } else {
        aValue =
          typeof aValue === 'string' && toUpperCase
            ? aValue.toUpperCase()
            : aValue;
        bValue =
          typeof bValue === 'string' && toUpperCase
            ? bValue.toUpperCase()
            : bValue;
      }
      if (type === 'date_string' || type === 'date') {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      } else if (type === 'number') {
        const nValueA = Number(aValue);
        const nValueB = Number(bValue);
        aValue = isNaN(nValueA) ? aValue : nValueA;
        bValue = isNaN(nValueB) ? bValue : nValueB;
      }
      if (order === 'ASC') {
        if (aValue > bValue) {
          return 1;
        }
        if (aValue < bValue) {
          return -1;
        }
        return 0;
      }
      if (aValue > bValue) {
        return -1;
      }
      if (aValue < bValue) {
        return 1;
      }
      return 0;
    })
    .slice(0);
}

// move the object at destination index
export function moveObjectAtIndex<T = any>(
  array: T[],
  sourceIndex: number,
  destinationIndex: number,
) {
  return array.splice(destinationIndex, 0, array.splice(sourceIndex, 1)[0]);
}

/**
 * Remove the items in the array.
 * Note: This mutate the original array
 */
export function spliceFromArray<T = any>(
  array: T[],
  needle: T,
  key: string = 'id',
) {
  let item: T | null = null;

  for (let i = 0; i < array.length; i++) {
    item = array[i] as T;
    if (
      typeof item === 'object' &&
      item &&
      typeof needle === 'object' &&
      needle
    ) {
      if ((item as IterableObject)[key] === (needle as IterableObject)[key]) {
        return array.splice(i, 1);
      }
    } else if (item === needle) {
      return array.splice(i, 1);
    }
  }

  return [];
}

export function shuffle<T>(array: T[]): T[] {
  // Create a copy of the original array to avoid modifying it directly
  const shuffled = [...array];

  // Fisher-Yates shuffle algorithm
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled;
}
