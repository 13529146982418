import React from 'react';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { PaletteTypeButtonProps } from '../../types/app';

export type M3AutocompleteProps<T = any> = AutocompleteProps<
  T,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
> &
  PaletteTypeButtonProps;
export const M3Autocomplete = <T,>({
  palette: paletteKey = 'main',
  ...restProps
}: M3AutocompleteProps<T>) => {
  return <Autocomplete {...restProps} />;
};
