import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCurrentProfile } from '../../hooks/profile';
import { UserMetadata } from '../../types/profile';
import { ListResult } from '../../types/response';
import { ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../app/app';
import { setToLocalStorage } from '../../utils/localstorage';
import { UseCosmosHandlers, useCosmos } from '../../hooks/global/useCosmos';
import { useRefAssign } from '../../hooks/global/useRefAlwaysUpdated';

type UserProviderProps = {
  children?: ReactRenderElement;
};
type UserIdKeys =
  | 'user_id'
  | 'staff_id'
  | 'employee_id'
  | 'partner_id'
  | 'partner_member_id';
type UserIdTypes = string | number | null | undefined;
type UserUniqueIdProps = {
  user_ids?: UserIdTypes[];
  staff_ids?: UserIdTypes[];
  employee_ids?: UserIdTypes[];
  partner_ids?: UserIdTypes[];
  partner_member_ids?: UserIdTypes[];
};
/**
 * State that we can mutate
 */
type UserInitialState = {
  [key: string]: any;
  users: { [key: string]: UserMetadata };
  uniqueIdsToFetch: UserIdTypes[];
  /**
   * List of unique ids
   */
  uniqueUserIdsToFetch: UserIdTypes[];
  uniqueEmployeeIdsToFetch: UserIdTypes[];
  uniquePartnerIdsToFetch: UserIdTypes[];
  uniquePartnerMemberIdsToFetch: UserIdTypes[];
  uniqueStaffIdsToFetch: UserIdTypes[];
};
/**
 * Reducers that mutate the state
 */
type UserReducers = {
  setUniqueIdsToFetch: (
    props: UserUniqueIdProps,
    ignoreCache?: boolean,
  ) => void;
  getUser: (type: UserIdKeys, id?: UserIdTypes) => UserMetadata | null;
};
/**
 * Single store
 */
type UserStore = UserInitialState & UserReducers;
/**
 * Initial state / store
 */
const initialStore: UserStore = {
  users: {},
  uniqueIdsToFetch: [],
  uniqueUserIdsToFetch: [],
  uniqueEmployeeIdsToFetch: [],
  uniquePartnerIdsToFetch: [],
  uniquePartnerMemberIdsToFetch: [],
  uniqueStaffIdsToFetch: [],
  setUniqueIdsToFetch: (props: UserUniqueIdProps, ignoreCache?: boolean) => {
    throw new Error('Implementation required');
  },
  getUser: (type: UserIdKeys, id?: UserIdTypes) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const UserContext = createContext<UserStore>(initialStore);

export function useUserProvider(): UserStore {
  return useContext(UserContext);
}

export function UserProvider({ children }: UserProviderProps) {
  const [state, setState] = useState<UserStore>(initialStore);
  const { updatePalette, getActionKeyCounter, isDarkMode } = useAppProvider();

  const {
    isSuccess: userProfileIsSuccess,
    data: userProfile,
    refetch: refetchCurrentProfile,
  } = useCurrentProfile();

  const userDataHandlers: UseCosmosHandlers<
    UserMetadata[] | ListResult<UserMetadata>
  > = {
    onSuccess: (result) => {
      if (Array.isArray(result)) {
        if (result.length) {
          updateCacheUser(result);
        }
      } else {
        updateCacheUser((result as ListResult<UserMetadata>).results);
      }
    },
  };

  useFetchUsersPayload(
    {
      key: 'user',
      user_ids: state.uniqueUserIdsToFetch.join(','),
    },
    userDataHandlers,
  );
  useFetchUsersPayload(
    {
      key: 'staff',
      staff_ids: state.uniqueStaffIdsToFetch.join(','),
    },
    userDataHandlers,
  );
  useFetchUsersPayload(
    {
      key: 'employee',
      employee_ids: state.uniqueEmployeeIdsToFetch.join(','),
    },
    userDataHandlers,
  );
  useFetchUsersPayload(
    {
      key: 'partner',
      partner_ids: state.uniquePartnerIdsToFetch.join(','),
    },
    userDataHandlers,
  );
  useFetchUsersPayload(
    {
      key: 'partner_member',
      partner_member_ids: state.uniquePartnerMemberIdsToFetch.join(','),
    },
    userDataHandlers,
  );

  const usersRef = useRefAssign(state.users);
  const getUser = useCallback(
    function (type: UserIdKeys, id?: UserIdTypes): UserMetadata | null {
      return id ? usersRef.current[`${type}_${id}`] ?? null : null;
    },
    [usersRef],
  );

  /**
   * Define all the handlers here how you want to mutate the state
   */
  const setUniqueIdsToFetch = useCallback(
    (
      {
        user_ids,
        staff_ids,
        employee_ids,
        partner_ids,
        partner_member_ids,
      }: UserUniqueIdProps,
      ignoreCache: boolean = false,
    ) => {
      const getUniqueUser = (
        ids?: UserIdTypes[],
        key?: UserIdKeys,
      ): UserIdTypes[] | null => {
        if (ids && key) {
          ids = ids.filter((id) => !!id).sort();
          ids = Array.from(new Set(ids));
          ids = ignoreCache ? ids : ids.filter((id) => !getUser(key, id!));
          return ids.length ? ids : null;
        }
        return null;
      };

      setState((state: UserStore) => {
        return {
          ...state,
          uniqueUserIdsToFetch:
            getUniqueUser(user_ids, 'user_id') || state.uniqueUserIdsToFetch,
          uniqueStaffIdsToFetch:
            getUniqueUser(staff_ids, 'staff_id') || state.uniqueStaffIdsToFetch,
          uniqueEmployeeIdsToFetch:
            getUniqueUser(employee_ids, 'employee_id') ||
            state.uniqueEmployeeIdsToFetch,
          uniquePartnerIdsToFetch:
            getUniqueUser(partner_ids, 'partner_id') ||
            state.uniquePartnerIdsToFetch,
          uniquePartnerMemberIdsToFetch:
            getUniqueUser(partner_member_ids, 'partner_member_id') ||
            state.uniquePartnerMemberIdsToFetch,
        };
      });
    },
    [setState, getUser],
  );

  const updateCacheUser = useCallback(
    (userResults: UserMetadata[]) => {
      setState((state) => {
        state = { ...state };
        state.users = {
          ...state.users,
          ...userResults
            .map((user) => ({ ...user, id: +user.id }))
            .reduce((users, user) => {
              user.photo_url = user.settings?.profile_image || user.photo_url;
              /**
               * NOTE: Key assignment must match the pattern:
               * <UserIdTypes>_<id>
               */
              if (user.id) {
                users[`user_id_${user.id}`] = user;
              }
              if (user.staff_id) {
                users[`staff_id_${user.staff_id}`] = user;
              }
              if (user.employee_id) {
                users[`employee_id_${user.employee_id}`] = user;
              }
              if (user.partner_id) {
                users[`partner_id_${user.partner_id}`] = user;
              }
              if (user.member_id) {
                users[`member_id_${user.member_id}`] = user;
              }
              return users;
            }, {} as { [key: string]: UserMetadata }),
        };
        return state;
      });
    },
    [setState],
  );

  /**
   * When current user is updated / refetch, update user's profile data
   */
  useEffect(() => {
    if (userProfileIsSuccess || userProfile) {
      if (userProfile) {
        setToLocalStorage(
          'user_color_scheme',
          userProfile.settings?.misc?.user_color_scheme || null,
        );
        updatePalette(userProfile.settings?.misc?.user_color_scheme);
        updateCacheUser([
          {
            id: userProfile.id,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            employee_id: userProfile.employee_id,
            member_id: userProfile.member_id,
            photo_url: userProfile.photo_url,
            settings: userProfile.settings,
            preferred_name: userProfile.preferred_name,
            job_title: userProfile.job_title,
            job_description_id: userProfile.job_description_id,
          } as UserMetadata,
        ]);
        setUniqueIdsToFetch(
          {
            user_ids: [userProfile.id],
          },
          true,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileIsSuccess, userProfile]);

  /**
   * When user saves current settings, refetch the current user
   */
  useEffect(() => {
    if (getActionKeyCounter('user_save_settings')) {
      refetchCurrentProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActionKeyCounter('user_save_settings')]);

  /**
   * When dark mode switch changes, save it to local storage
   */
  useEffect(() => {
    setToLocalStorage(`is-dark-mode`, isDarkMode);
    [document.documentElement, document.body].forEach((el) => {
      el.classList.remove('light-theme', 'dark-theme');
      el.classList.add(`${isDarkMode ? 'dark' : 'light'}-theme`);
    });
  }, [isDarkMode, getActionKeyCounter('toggle_switch_dark_mode')]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserContext.Provider
      value={{
        ...state,
        setUniqueIdsToFetch,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

type UseFetchUsersPayload = {
  key?: string;
  user_ids?: string;
  staff_ids?: string;
  employee_ids?: string;
  partner_ids?: string;
  partner_member_ids?: string;
};

function useFetchUsersPayload<R = any>(
  {
    key,
    user_ids,
    staff_ids,
    employee_ids,
    partner_ids,
    partner_member_ids,
  }: UseFetchUsersPayload,
  handlers: UseCosmosHandlers<R>,
) {
  return useCosmos(
    {
      endpoint: '/api/users/get-users-data/',
      enabled: !!(
        user_ids ||
        staff_ids ||
        employee_ids ||
        partner_ids ||
        partner_member_ids
      ),
      deps: [
        key,
        user_ids,
        staff_ids,
        employee_ids,
        partner_ids,
        partner_member_ids,
      ],
      params: {
        user_ids,
        staff_ids,
        employee_ids,
        partner_ids,
        partner_member_ids,
      },
    },
    handlers,
  );
}
