import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Box, Stack, Typography } from '@mui/material';
import { faker } from '@faker-js/faker';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

import UserTimelineModal from './UserTimeline/UserTimelineModal';

import { useModal } from '../../components/M3/M3Modal';

type UserSnapshotsProps = {};

type SnapShotProps = {
  columns: number;
  snapshot: any;
  onVideoClick: (snapshot: any) => void;
};
const SnapShot = ({ columns, snapshot, onVideoClick }: SnapShotProps) => {
  const width = 100 / (columns * 0.5);

  return (
    <Box
      sx={{
        mb: 4,
        padding: 1,
        paddingBottom: 4,
        width: `${width}%`,
        minHeight: `${width}%`,
      }}
    >
      {/* <Stack direction='row' justifyContent='space-between' px={1}>
        <Typography
          component='div'
          fontWeight={500}
          fontSize={14}
          sx={{ opacity: 0.8 }}
        >
          {snapshot.time}
        </Typography>
        <Stack
          gap={2}
          pb={0.5}
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <VideocamOutlinedIcon
            style={{
              fontSize: 22,
              cursor: 'pointer',
              opacity: 0.8,
            }}
          />
        </Stack>
      </Stack> */}
      <Box
        sx={{
          mb: 0.6,
          width: `100%`,
          height: '100%',
          borderRadius: 4,
          overflow: 'hidden',
          position: 'relative',
          background: 'rgba(0, 0, 0, 0.1)',
        }}
      >
        <img
          src={snapshot.photo}
          alt=''
          style={{
            width: '100%',
            height: '100%',
            display: 'block',
            objectFit: 'cover',
            pointerEvents: 'none',
          }}
        />
        {/* <Box
          sx={{
            p: 2,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            background: isDarkMode
              ? applyOpacityOnColor(
                  palette['md.sys.color.on.primary.dark'],
                  0.2,
                )
              : applyOpacityOnColor(
                  palette['md.sys.color.on.primary.light'],
                  0.2,
                ),
          }}
        >
          <Typography
            component='div'
            fontWeight={500}
            fontSize={14}
          >
            {snapshot.names.join(', ')}
          </Typography>
        </Box> */}
      </Box>
      <Stack direction='row' justifyContent='space-between' px={1}>
        <Typography
          component='div'
          fontWeight={500}
          fontSize={14}
          sx={{ opacity: 0.8 }}
        >
          {snapshot.time}
        </Typography>
        <Stack
          gap={2}
          pb={0.5}
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <VideocamOutlinedIcon
            style={{
              fontSize: 22,
              cursor: 'pointer',
              opacity: 0.8,
            }}
            onClick={() => onVideoClick(snapshot)}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const UserSnapshots = (props: UserSnapshotsProps) => {
  const multiDivider = 2;
  const row = 12;
  const columns = 6 * multiDivider;
  const total = row * columns;

  const timelineModal = useModal();

  const data = useMemo(() => {
    const interval = 10 / multiDivider;
    const startOfDay = moment().startOf('day');
    return new Array(total).fill(null).map((_, index) => ({
      id: index + 1,
      photo: faker.image.url(),
      time: startOfDay
        .add(index === 0 ? 0 : interval, 'minute')
        // .format('HH:mm'),
        .format('hh:mm a'),
      names: faker.helpers.arrayElements(['Screen 1', 'Screen 2'], {
        min: 1,
        max: 1,
      }),
    }));
  }, [total]);

  const onVideoClick = useCallback(
    (snapshot: any) => {
      timelineModal.open();
    },
    [timelineModal],
  );

  return (
    <>
      <Box
        display='flex'
        flexWrap='wrap'
        sx={{
          p: 2,
          pb: 0,
          px: 2.5,
        }}
      >
        {data.map((snapshot, index) => {
          return (
            <SnapShot
              key={index}
              columns={columns}
              snapshot={snapshot}
              onVideoClick={onVideoClick}
            />
          );
        })}
      </Box>
      <UserTimelineModal
        open={timelineModal.isOpen}
        onClose={timelineModal.close}
      />
    </>
  );
};

export default UserSnapshots;
