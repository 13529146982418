import React, { CSSProperties, memo, useMemo, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './TimelineGauge.css';

import { useWindowDimension } from '../../../../hooks/utils/window';

const formatHour = (hour: number, isLast: boolean) => {
  // if (hour === 0) {
  //   return '';
  // }
  let modHour = hour % 12;
  modHour = modHour ? modHour : 12; // If hour is 0 (i.e., 12 AM), make it 12
  const suffix = hour >= 12 ? (isLast ? 'AM' : 'PM') : 'AM';
  return `${modHour}${suffix}`.toLowerCase();
};

type TimelineGaugeProps = {
  data?: any[];
};
const TimelineGauge: React.FC<TimelineGaugeProps> = ({
  data: timeDetailsData = [],
}) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const hoursLength = 24;
  const hours = useMemo(
    () => Array.from({ length: hoursLength + 1 }, (_, i) => i),
    [hoursLength],
  );
  const lineStyle: CSSProperties = {
    width: 0,
    position: 'relative',
  };

  useWindowDimension();

  return (
    <Box
      id='timeline'
      ref={timelineRef}
      justifyContent='space-evenly'
      component='section'
      flexWrap='nowrap'
      style={{
        position: 'relative',
        // height: '100px',
      }}
      className='timeline-grid-container'
    >
      <Grid
        container
        direction='row'
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        }}
      >
        {hours.map((hour, index) => {
          let pctLength = 100 / hoursLength;
          const style: CSSProperties = {
            ...lineStyle,
            top: 0,
            left: `${pctLength * index}%`,
            position: 'absolute',
          };

          if (index % 2 === 0) {
            return (
              <Grid
                item
                key={index}
                className='timeline-gauge-line'
                style={style}
              >
                <Typography
                  component='span'
                  fontSize={10}
                  style={{
                    opacity: 0.5,
                  }}
                  className='timeline-gauge-label'
                >
                  {formatHour(hour, index === hours.length - 1)}
                </Typography>
              </Grid>
            );
          } else {
            return (
              <Grid
                item
                key={index}
                className='timeline-gauge-line timeline-gauge-small-line'
                style={style}
              />
            );
          }
        })}
      </Grid>
      <Grid
        container
        className='timeline-time-slots'
        style={{
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        {/* {timeDetailsData.map((detail, index) => {
          const { id, start_time_local, end_time_local, duration, task_name } =
            detail;
          const taskColor = getColorByTaskName(task_name);
          const isActive = isActiveWorklog(detail, index);

          return (
            <TimeBlock
              key={id}
              color={taskColor}
              taskName={task_name}
              timeStarted={start_time_local}
              timeEnded={isActive ? null : end_time_local}
              duration={duration}
              timelineWidth={
                timelineRef.current ? timelineRef.current.clientWidth : 0
              }
            />
          );
        })} */}
      </Grid>
    </Box>
  );
};

export default memo(TimelineGauge);
