import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';

import M3SurfaceContainer from '../../components/M3/M3SurfaceContainer';
import {
  // BasicItemNotFound,
  M3OptionItem,
} from '../../components/Popover/BasicPopoverWithSearch';
import FilterColumnPanel from '../../components/Filters/FilterColumnPanel';
import { AutocompleteFormState } from '../../components/Filters/AutocompleteSelectionPopover';
import { useForm } from '../../components/BasicForm';
import FormPanel from '../../components/Panels/FormPanel';
import { useAutocompleteUserSearch } from '../../components/Filters/AutocompleteUserSearch';

import { ReactRenderElement } from '../../types/types';
import { ProductivityAgentUser } from './types';
import AutocompleteSearch, {
  AutocompleteSearchOptionItem,
} from '../../components/Filters/AutocompleteSearch';
import {
  useAdminDivisions,
  UseAdminDivisionsProps,
} from '../../hooks/divisions';
import { useUserSearch } from '../../hooks/profile';
import BasicDatePicker from '../../components/Pickers/BasicDatePicker';

type FiltersBoardProps = {
  filterParams: ProductivityFilterParams;
  fieldLabelMap: { [K in keyof Partial<ProductivityAgentUser>]: string };
  allFields: (keyof ProductivityAgentUser)[];
  selectedFields: (keyof ProductivityAgentUser)[];
  onFieldSelect?: (
    field: keyof ProductivityAgentUser,
    remove?: boolean,
  ) => void;
  onFieldsChange?: (fields: (keyof ProductivityAgentUser)[]) => void;
  updateFilterParams: (params: Partial<ProductivityFilterParams>) => void;
};

export type DateFormState = {
  start_date: string | null;
  end_Date: string | null;
};

export type ProductivityFilterParams = {
  start_date: Moment;
  end_date: Moment;
  users: M3OptionItem[];
  divisions: M3OptionItem[];
};

const FiltersBoard = ({
  filterParams,
  fieldLabelMap,
  allFields,
  selectedFields,
  onFieldSelect,
  onFieldsChange,
  updateFilterParams,
}: FiltersBoardProps) => {
  // Users ---------------------------------------------------------------------
  const autocompleteUserSearch = useAutocompleteUserSearch();
  const [userSearchKeyCounter, setUserSearchKeyCounter] = useState(0);
  const handleOnUserSearchSelected = useCallback(
    (users: any) => {
      autocompleteUserSearch.handleOnUserSearchSelected(users);
      updateFilterParams({
        users: users as unknown as ProductivityFilterParams['users'],
      });
    },
    // eslint-disable-next-line
    [updateFilterParams, autocompleteUserSearch.handleOnUserSearchSelected],
  );

  // Divisions -----------------------------------------------------------------
  const [divisionsSelected, setDivisionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [divisionKeyCounter, setDivisionKeyCounter] = useState(0);
  const getDivisionsOptionFormatter = useCallback(
    (div: any) =>
      ({
        id: div.id,
        label: div.name,
        props: div,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnDivisionSearchSelected = useCallback(
    (divisions: any) => {
      setDivisionsSelected(divisions as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        divisions:
          divisions as unknown as ProductivityFilterParams['divisions'],
      });
    },
    [setDivisionsSelected, updateFilterParams],
  );

  // Fields --------------------------------------------------------------------
  const fieldsAutocompleteForm = useForm<
    AutocompleteFormState & { search: string }
  >({
    search: '',
    checked: {},
    optionById: {},
  });

  const renderSectionTitle = (title: ReactRenderElement) => (
    <Typography
      component='div'
      pt={1.5}
      fontWeight={500}
      fontSize={14}
      sx={{
        opacity: 0.5,
      }}
    >
      {title}
    </Typography>
  );

  const renderFilterBy = () => {
    return (
      <Box flex={1} mb={2}>
        {renderSectionTitle('Filter By:')}
        <Box
          gap={2}
          display='flex'
          flexWrap='wrap'
          sx={{
            pt: 1,
            zIndex: 1,
            position: 'relative',
          }}
        >
          <FormPanel label='Date'>
            <FilterColumnPanel
              alwaysOpen
              useTextField={false}
              name='date'
              displayValue={
                <BasicDatePicker
                  disableFuture
                  defaultValue={filterParams.start_date}
                  format='MMM D, YYYY'
                  label=''
                  textFieldProps={{
                    fullWidth: true,
                    sx: {
                      '.MuiInputBase-input': {
                        paddingLeft: 2.5,
                        fontSize: 16,
                      },
                    },
                  }}
                  onChange={(value: Moment | null) => {
                    if (value) {
                      updateFilterParams({
                        start_date: moment(value).startOf('day'),
                        end_date: moment(value).endOf('day'),
                      });
                    }
                  }}
                />
              }
              textFieldSx={{ width: 240 }}
              boxFieldStyle={{
                width: 200,
                border: 0,
              }}
            />
          </FormPanel>
          <FormPanel label='Name' sx={{ minWidth: 300, flex: 1 }}>
            <AutocompleteSearch
              key={userSearchKeyCounter}
              multiple
              withSearchIcon
              searchKey='s'
              placeholder='Search name...'
              onSelect={handleOnUserSearchSelected}
              defaultOptionsSelected={autocompleteUserSearch.usersSelected}
              optionFormatter={autocompleteUserSearch.getUserOptionFormatter}
              onSearchTextOptions={
                autocompleteUserSearch.onUserSearchTextOptions
              }
              renderOptionItem={autocompleteUserSearch.getRenderUserOptionItem}
              useInfiniteProps={{
                isFlatResult: true,
                useQuery: useUserSearch as any,
              }}
            />
          </FormPanel>
          <FormPanel label='Division' sx={{ minWidth: 300 }}>
            <AutocompleteSearch
              key={divisionKeyCounter}
              multiple
              searchKey='name'
              placeholder='Search division...'
              defaultOptionsSelected={divisionsSelected}
              onSelect={handleOnDivisionSearchSelected}
              optionFormatter={getDivisionsOptionFormatter}
              useInfiniteProps={{
                useQuery: useAdminDivisions,
                queryParams: {
                  status: 'active',
                } as UseAdminDivisionsProps,
              }}
            />
          </FormPanel>
          {/* <FormPanel
            label='Fields'
            sx={{ minWidth: 300, position: 'relative', flex: 1 }}
          >
            <span
              style={{
                top: 2,
                right: 0,
                opacity: 0.5,
                fontSize: 12,
                position: 'absolute',
              }}
            >
              (
              {!totalFieldsSelected || totalFieldsSelected === allFields.length
                ? `All selected`
                : `${totalFieldsSelected} selected`}
              )
            </span>
            <AutocompleteSelectionPopover
              width='100%'
              placeholder={`Select${
                totalFieldsSelected ? `ed (${totalFieldsSelected})` : ''
              } field${
                !totalFieldsSelected || totalFieldsSelected > 1 ? 's' : ''
              }...`}
              options={allFields.map((field) => ({
                id: field,
                value:
                  fieldLabelMap[field] ??
                  toTitleCase(stripToFormatterWord(field)),
              }))}
              form={
                fieldsAutocompleteForm as unknown as FormStateRet<AutocompleteFormState>
              }
            />
          </FormPanel> */}
        </Box>
      </Box>
    );
  };

  const renderTag = ({ id, tag, label, onDelete }: any) => {
    return (
      <Chip
        size='small'
        key={id}
        label={
          <span style={{ fontWeight: 500 }}>
            <span
              style={{
                opacity: 0.39,
                paddingRight: 4,
                fontWeight: 400,
              }}
            >
              {tag}:
            </span>
            {label}
          </span>
        }
        onDelete={onDelete}
      />
    );
  };

  const renderTags = () => {
    return (
      <Stack direction='row' gap={1} flexWrap='wrap' mb={1}>
        {autocompleteUserSearch.usersSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Name',
            label: opt.label,
            onDelete: () => {
              setUserSearchKeyCounter((c) => ++c);
              handleOnUserSearchSelected(
                autocompleteUserSearch.usersSelected.filter(
                  (u) => u.id !== opt.id,
                ),
              );
            },
          });
        })}
        {divisionsSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Division',
            label: opt.label,
            onDelete: () => {
              setDivisionKeyCounter((c) => ++c);
              handleOnDivisionSearchSelected(
                divisionsSelected.filter((div) => div.id !== opt.id),
              );
            },
          });
        })}
      </Stack>
    );
  };

  useEffect(() => {
    const fields = Object.keys(fieldsAutocompleteForm.formState.checked).filter(
      (key) => fieldsAutocompleteForm.formState.checked[key],
    );
    onFieldsChange?.(fields as (keyof ProductivityAgentUser)[]);
  }, [onFieldsChange, fieldsAutocompleteForm.formState]);

  return (
    <Box mt={-2}>
      <M3SurfaceContainer
        elevation={1}
        sx={{
          mb: 1,
          padding: '12px 30px 18px',
        }}
      >
        {/* {renderSearchBy()} */}
        {renderFilterBy()}
        {renderTags()}
        {/* <Box
          gap={2}
          display='flex'
          justifyContent='space-between'
          sx={{
            pb: 1,
            pt: 4,
          }}
        >
          <M3Button
            type='submit'
            color='primary'
            variant='contained'
            sx={{
              width: 100,
            }}
          >
            Submit
          </M3Button>
          <M3Button
            disabled
            color='secondary'
            variant='outlined'
            sx={{
              width: 110,
            }}
          >
            Save Filters
          </M3Button>
        </Box> */}
      </M3SurfaceContainer>
    </Box>
  );
};

export default FiltersBoard;
