import React, { CSSProperties, useCallback, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';

import DataTable from '../../components/DataTable/DataTable';

import { UserKeystroke } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { getBasename } from '../../utils/url';

type UserKeystrokesProps = {
  isLoading?: boolean;
  items: UserKeystroke[];
};

const keystrokesFieldLabelMap: {
  [K in keyof Partial<UserKeystroke>]: string;
} = {
  url: 'Host',
  exec: 'Application',
  task_name: 'Focus',
};

const keystrokesFieldStyleMap: {
  [K in keyof Partial<UserKeystroke>]: CSSProperties;
} = {
  timestamp: {
    width: 260,
    minWidth: 260,
  },
  exec: {
    width: 240,
    minWidth: 240,
  },
  url: {
    width: 280,
    minWidth: 280,
  },
  keystrokes: {
    flex: 1,
    width: 0,
  },
  go2_version: {
    width: 180,
    minWidth: 180,
  },
  task_name: {
    width: 300,
    minWidth: 300,
  },
};

const defaultAgentFields: (keyof UserKeystroke)[] = [
  'timestamp',
  'task_name',
  'go2_version',
  'exec',
  'url',
  'keystrokes',
];

const defaultActiveField: keyof UserKeystroke = 'timestamp';

const UserKeystrokes = ({ isLoading, items }: UserKeystrokesProps) => {
  const [filters] = useState<{
    active: keyof UserKeystroke;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  // const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey] = useState(0);

  // const filteredData = useMemo(() => {
  //   return sortBy<UserKeystroke>(data, filters.active, true, filters.order);
  // }, [data, filters]);
  // const totalOnline = useMemo(
  //   () => filteredData.filter((u) => u.status === 'online').length,
  //   [filteredData],
  // );

  const [selectedFields] = useState<(keyof UserKeystroke)[]>([]);

  const handleColumnItem = useCallback(
    (field: keyof UserKeystroke, item: UserKeystroke) => {
      if (field === 'timestamp') {
        return (
          <Stack direction='row' gap={1}>
            <KeyboardAltOutlinedIcon
              style={{
                fontSize: 20,
                opacity: 0.5,
                marginTop: 1,
              }}
            />
            {`${moment.utc(item[field]).local().format('MMM D, YYYY hh:mma')}`}
          </Stack>
        );
      }

      if (field === 'url' && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).host;
        } catch (e) {}
        return (
          <a
            href={item[field]}
            target='_blank'
            rel='noreferrer'
            className='m3-button-link'
          >
            {url}
          </a>
        );
      }

      if (field === 'exec') {
        let exec = item[field];
        return exec ? getBasename(exec) : '';
      }

      return item[field];
    },
    [],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof UserKeystroke) =>
      keystrokesFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  /*
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof UserKeystroke, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  */
  /*
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof UserKeystroke)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );
  */

  // const dataTablePagination = useDataTablePagination({
  //   defaultSize: 25,
  //   items: items,
  // });

  return (
    <>
      <Divider style={{ borderWidth: 1 }} />
      <Typography component='div'>
        <DataTable
          key={dataTableKey}
          orderBy={filters.order}
          // activeField={filters.active}
          fields={selectedFields.length ? selectedFields : defaultAgentFields}
          data={items}
          loadingCount={10}
          isLoading={isLoading}
          fieldColumnStyleMap={keystrokesFieldStyleMap}
          handleColumnItem={handleColumnItem}
          handleHeaderColumnItem={handleHeaderColumnItem}
          // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
        />
      </Typography>
    </>
  );
};

export default UserKeystrokes;
