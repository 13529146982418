export function preloadVideo(url: string) {
  const video = document.createElement('video');
  let isCancelled = false;
  let isResolved = false;

  const promise = new Promise<void>((resolve, reject) => {
    video.oncanplaythrough = () => {
      if (!isCancelled) {
        isResolved = true;
        video.src = '';
        video.load();
        resolve();
      }
    };
    video.onerror = (error) => {
      if (!isCancelled) {
        reject(error);
      }
    };
  });

  const cancel = () => {
    if (isResolved) return;

    isCancelled = true;
    video.src = '';
    video.load();
  };

  video.muted = true;
  video.preload = 'auto';
  video.src = url;

  return { promise, cancel };
}
