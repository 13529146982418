import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import FormPanel from '../../components/Panels/FormPanel';
import FilterColumnPanel from '../../components/Filters/FilterColumnPanel';
import BasicDatePicker from '../../components/Pickers/BasicDatePicker';

export type FilterParams = {
  start_date: Moment;
  end_date: Moment;
};
type UserFiltersProps = {
  isDownloading?: boolean;
  withDownload?: boolean;
  filterParams: FilterParams;
  selectedFilteredDate: Moment;
  updateFilterParams: (params: Partial<FilterParams>) => void;
  handleOnDownloadClick?: () => void;
};

const UserFilters = ({
  isDownloading,
  withDownload,
  filterParams,
  selectedFilteredDate,
  updateFilterParams,
  handleOnDownloadClick,
}: UserFiltersProps) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      gap={2}
      py={2}
      pt={3}
      px={3}
    >
      <Stack direction='row' justifyContent='space-between' flex={1}>
        <FormPanel>
          <FilterColumnPanel
            name='date'
            useTextField={false}
            displayValue={
              <Box style={{ position: 'relative', overflow: 'hidden' }}>
                <Stack gap={1} direction='row'>
                  <Typography
                    component='h3'
                    fontSize={20}
                    style={{
                      opacity: 0.8,
                    }}
                  >{`${selectedFilteredDate.format(
                    'MMMM D, YYYY',
                  )}`}</Typography>
                  <CalendarMonthOutlinedIcon
                    style={{
                      marginTop: 2,
                      opacity: 0.8,
                      marginLeft: 4,
                    }}
                  />
                </Stack>
                <Box
                  style={{
                    top: 0,
                    left: 0,
                    opacity: 0,
                    position: 'absolute',
                  }}
                >
                  <BasicDatePicker
                    disableFuture
                    defaultValue={selectedFilteredDate}
                    format='MMM D, YYYY'
                    label=''
                    textFieldProps={{
                      fullWidth: true,
                      sx: {
                        '.MuiInputBase-input': {
                          visibility: 'hidden',
                        },
                        '.MuiInputAdornment-root .MuiButtonBase-root': {
                          top: 0,
                          left: 0,
                          zIndex: 2,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                        },
                      },
                    }}
                    onChange={(value: Moment | null) => {
                      if (value) {
                        updateFilterParams({
                          start_date: moment(value).startOf('day'),
                          end_date: moment(value).endOf('day'),
                        });
                      }
                    }}
                  />
                </Box>
              </Box>
            }
            sx={{
              zIndex: 2,
            }}
            boxFieldStyle={{
              border: 0,
              background: 'transparent',
            }}
          />
        </FormPanel>
        <Stack
          direction='row'
          style={{
            marginTop: -6,
          }}
        >
          <Typography component='div' style={{ marginTop: -6 }}></Typography>
          <Box display='flex' justifyContent='flex-end' gap={1} flex={1}>
            {/* <Tooltip title='Refresh'>
              <Box>
                <M3IconButton onClick={() => {}}>
                  <RefreshOutlinedIcon
                  // className={infiniteActivities.isLoading ? 'spinner' : ''}
                  />
                </M3IconButton>
              </Box>
            </Tooltip> */}
            {/* {withDownload && (
              <Tooltip title='Download'>
                <Box>
                  <M3IconButton
                    disabled={isDownloading}
                    onClick={handleOnDownloadClick}
                  >
                    {isDownloading ? (
                      <CircularProgress size={18} />
                    ) : (
                      <DownloadOutlinedIcon />
                    )}
                  </M3IconButton>
                </Box>
              </Tooltip>
            )} */}
          </Box>
        </Stack>
      </Stack>
      {/* <UserTimeline /> */}
    </Box>
  );
};

export default UserFilters;
