import React, { useEffect, useRef } from 'react';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import M3Modal, {
  M3ModalBody,
  M3ModalHeader,
  M3ModalView,
  UseModalRes,
} from '../../M3/M3Modal';
import Go2AppSettings from '../WebAppSettings/Go2AppSettings';
import ApplicationsAndWebsitesSettings from './ApplicationsAndWebsitesSettings';

import {
  useGo2AgentAppConfigDefaults,
  useUserGo2AgentAppConfig,
} from '../../../hooks/cosmos/go2-agent/app-config';
import { useUserProvider } from '../../../providers/user/user';
import { getUserDisplayName, getUserPhotoUrl } from '../../../utils/user';
import { useAppWebsiteWhitelistForUser } from '../../../hooks/cosmos/go2-agent/app-website-whitelist';

type WebAppSettingsProps = {
  modal: UseModalRes;
  userId?: number | null;
};

const WebAppSettings = ({ modal, userId }: WebAppSettingsProps) => {
  const { getUser } = useUserProvider();
  const innerRef = useRef<HTMLDivElement>();
  const user = getUser('user_id', userId);

  // App Config Data -----------------------------------------------------------
  const {
    data: globalAgentAppConfigData,
    refetch: refetchGlobalAgentAppConfigData,
  } = useGo2AgentAppConfigDefaults({});
  const {
    data: userAgentAppConfigData,
    isFetching: isFetchingAppConfigData,
    refetch: go2AgentAppConfigRefetch,
  } = useUserGo2AgentAppConfig({ owner_id: userId! }, { enabled: !!userId });
  const appConfigData = userAgentAppConfigData?.results;

  // App Website Whitelist -----------------------------------------------------
  const {
    data: userGo2AgentAppWebsiteWhitelist,
    refetch: refetchUserGo2AgentAppWebsiteWhitelist,
  } = useAppWebsiteWhitelistForUser({
    id: userId!,
  });

  const renderActiveMenuContent = () => {
    return (
      <>
        <Go2AppSettings
          isGlobal={false}
          userId={userId}
          globalAppConfigData={globalAgentAppConfigData}
          isFetchingAppConfigData={isFetchingAppConfigData}
          appConfigData={appConfigData}
          appConfigRefetch={go2AgentAppConfigRefetch}
        />
        <br />
        <Divider style={{ borderWidth: 2, marginBottom: 22 }} />
        <ApplicationsAndWebsitesSettings
          isGlobal={false}
          userId={userId}
          userAppWebsiteWhitelist={userGo2AgentAppWebsiteWhitelist}
        />
      </>
    );
  };

  useEffect(() => {
    if (modal.isOpen) {
      refetchGlobalAgentAppConfigData();
      go2AgentAppConfigRefetch();
      refetchUserGo2AgentAppWebsiteWhitelist();
    }
  }, [
    modal.isOpen,
    refetchGlobalAgentAppConfigData,
    go2AgentAppConfigRefetch,
    refetchUserGo2AgentAppWebsiteWhitelist,
  ]);

  return (
    <>
      <M3Modal open={modal.isOpen} onClose={modal.close}>
        <M3ModalView
          onClose={modal.close}
          style={{
            maxWidth: 1020,
          }}
        >
          <M3ModalHeader>
            <Stack direction='row' alignItems='center' gap={1} pl={1} flex={1}>
              <ManageAccountsOutlinedIcon style={{ fontSize: 26 }} />
              <Typography component='div' fontSize={22} fontWeight={500}>
                User Configurations
              </Typography>
            </Stack>
            {!!user && (
              <Box gap={1} display='flex' alignItems='center' pr={2}>
                <Avatar
                  src={getUserPhotoUrl(user?.photo_url)}
                  style={{
                    width: 28,
                    height: 28,
                  }}
                >
                  {getUserDisplayName(user).initial}
                </Avatar>
                <Typography component='div' fontSize={14} fontWeight={500}>
                  {getUserDisplayName(user).fullName}
                </Typography>
              </Box>
            )}
          </M3ModalHeader>
          <M3ModalBody>
            <Box
              ref={innerRef}
              gap={1}
              style={{
                padding: '24px 32px 24px 32px',
              }}
            >
              {renderActiveMenuContent()}
            </Box>
          </M3ModalBody>
        </M3ModalView>
      </M3Modal>
    </>
  );
};

export default WebAppSettings;
