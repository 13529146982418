import axios, { AxiosRequestConfig } from 'axios';
import {
  useQuery,
  UseQueryResult,
  QueryFunctionContext,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query';
import { cleanURL } from '../../utils/url';
import { parseError } from '../../utils/response';
import { getFromSessionStorage } from '../../utils/sessionstorage';
import { useAuthProvider } from '../../providers/auth/auth';
import { MutationParams, QueryParams } from './_types';

/**
 * Instantiating axios with base url from the server
 */
const ax = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL!,
  headers: {
    'Content-Type': 'application/json',
    'X-TZOFFSET': (-1 * new Date().getTimezoneOffset()) / 60,
  },
});

ax.interceptors.request.use((config) => {
  config.headers = config.headers ?? {};
  config.headers['X-TZOFFSET'] = (-1 * new Date().getTimezoneOffset()) / 60;
  return config;
});

/**
 * Uses hook to access token and attaching it to axios instance
 * mainly use to fetch on server
 * @param name
 * @param endpoint
 * @param params
 * @param axiosConfig
 * @param queryParams
 * @returns
 */
export function useQueryApi<P = any, R = any>(
  name: string[],
  endpoint: string,
  params: P = {} as P,
  queryParams?: QueryParams | undefined,
  axiosConfig: AxiosRequestConfig = {},
): UseQueryResult<R> {
  const { getTokenSilently } = useAuthProvider();
  const impersonation = getFromSessionStorage('impersonation', true, true);

  async function api({ signal }: QueryFunctionContext): Promise<R> {
    return new Promise(async (resolve, reject) => {
      let token: string | null | undefined = null;
      try {
        token = await getTokenSilently();
      } catch (e) {
        reject(parseError(e as any));
        return;
      }
      try {
        let { headers, ...restAxiosConfig } = axiosConfig;
        let result = await ax({
          url:
            endpoint.indexOf('http') > -1
              ? endpoint
              : cleanURL('/api/' + endpoint),
          headers: {
            Authorization: impersonation
              ? `Token ${impersonation.token}`
              : `Bearer ${token}`,
            ...headers,
          },
          params,
          signal,
          ...restAxiosConfig,
        });
        resolve(result?.data as R);
      } catch (e) {
        reject(parseError(e));
      }
    });
  }

  if (process.env.REACT_APP_REFETCH_INTERVAL_DISABLED) {
    queryParams = {
      ...queryParams,
      refetchInterval: false,
    };
  }

  const queryResult = useQuery(name, api, queryParams);
  return {
    ...queryResult,
    /**
     * NOTE: There's a bug on react-query@v4 that 'isLoading' seems to be
     * true always even when 'enabled' is false
     * https://github.com/TanStack/query/issues/3584
     */
    isLoading: queryResult.isLoading && queryResult.fetchStatus !== 'idle',
  } as UseQueryResult<R>;
}

/**
 * Uses hook to access token and attaching it to axios instance
 * mainly use to post/patch/update/delete on server
 * @param endpoint
 * @param axiosConfig
 * @param mutationParams
 * @returns
 */
export function useMutationApi<D = any, R = any, E = Error, C = unknown>(
  endpoint: string,
  mutationParams?: MutationParams | undefined,
  axiosConfig: AxiosRequestConfig = {},
): UseMutationResult<R, E, D, C> {
  const { getTokenSilently } = useAuthProvider();
  const impersonation = getFromSessionStorage('impersonation', true, true);

  async function api(data: D) {
    return new Promise(async (resolve, reject) => {
      let token: string | null | undefined = null;
      try {
        token = await getTokenSilently();
      } catch (e) {
        return reject(parseError(e as any));
      }
      try {
        let { headers, ...restAxiosConfig } = axiosConfig;
        let result = await ax({
          url:
            endpoint.indexOf('http') > -1
              ? endpoint
              : cleanURL('/api/' + endpoint),
          headers: {
            Authorization: impersonation
              ? `Token ${impersonation.token}`
              : `Bearer ${token}`,
            ...headers,
          },
          data,
          ...restAxiosConfig,
          method: restAxiosConfig.method || 'POST',
        });
        resolve(result?.data as R);
      } catch (e) {
        reject(parseError(e));
      }
    });
  }

  return useMutation(api, mutationParams) as UseMutationResult<R, E, D, C>;
}
