import { createContext, useContext, useState } from 'react';

import { ReactRenderElement } from '../../types/types';

type MetadataProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type MetadataInitialState = {
  metabase: {
    charts: {
      keystrokes: {
        top_apps_website: number;
      };
      activities: {
        top_members: number;
        top_apps_website: number;
      };
      login_sessions: {
        timeline: number;
        hourly: number;
      };
      idle_time: {
        top_members: number;
      };
      activity_interval: {
        top_active: number;
        least_active: number;
      };
    };
  };
};
/**
 * Reducers that mutate the state
 */
type MetadataReducers = {};
/**
 * Single store
 */
type MetadataStore = MetadataInitialState & MetadataReducers;

/**
 * Initial state / store
 */
const initialStore: MetadataStore = {
  metabase: {
    charts:
      process.env.REACT_APP_GO2_AGENT_ENV === 'production'
        ? {
            keystrokes: {
              top_apps_website: 339,
            },
            activities: {
              top_members: 335,
              top_apps_website: 334,
            },
            login_sessions: {
              timeline: 340,
              hourly: 399,
            },
            idle_time: {
              top_members: 465,
            },
            activity_interval: {
              top_active: 468,
              least_active: 469,
            },
          }
        : {
            keystrokes: {
              top_apps_website: 372,
            },
            activities: {
              top_members: 367,
              top_apps_website: 369,
            },
            login_sessions: {
              timeline: 340,
              hourly: 399,
            },
            idle_time: {
              top_members: 466,
            },
            activity_interval: {
              top_active: 470,
              least_active: 471,
            },
          },
  },
};

/**
 * Context Instance
 */
const MetadataContext = createContext<MetadataStore>(initialStore);

export function useMetadataProvider(): MetadataStore {
  return useContext(MetadataContext);
}

export function MetadataProvider({ children }: MetadataProviderProps) {
  const [state] = useState<MetadataStore>(initialStore);

  return (
    <MetadataContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
}
