import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Box, ClickAwayListener, Slider } from '@mui/material';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';

import AbsoluteCenterBox from '../AbsoluteCenterBox';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';

type VolumeControlProps = {
  initialVolume?: number;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  onVolumeChange?: (volume: number) => void;
};
const VolumeControl = ({
  initialVolume,
  style,
  iconStyle: propIconStyle,
  onVolumeChange,
}: VolumeControlProps) => {
  const [volume, setVolume] = useState(initialVolume ?? 1);
  const [off, setOff] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      setVolume(newValue as number);
      setOff(false);
    },
    [setVolume, setOff],
  );

  const handleOnVolumeClick = useCallback(() => {
    if (visible) {
      setOff((off) => !off);
    } else {
      setVisible(true);
    }
  }, [visible, setVisible, setOff]);

  const handleOnClickAway = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const renderIcon = () => {
    const iconStyle: CSSProperties = {
      zIndex: 2,
      opacity: 0.8,
      fontSize: 20,
      cursor: 'pointer',
      position: 'relative',
      ...propIconStyle,
    };
    if (off)
      return (
        <VolumeOffIcon
          style={{
            ...iconStyle,
          }}
          onClick={handleOnVolumeClick}
        />
      );
    if (volume <= 0)
      return (
        <VolumeMuteIcon
          style={{
            ...iconStyle,
            left: -4,
          }}
          onClick={handleOnVolumeClick}
        />
      );
    if (volume >= 1)
      return <VolumeUpIcon style={iconStyle} onClick={handleOnVolumeClick} />;
    return (
      <VolumeDownIcon
        style={{
          ...iconStyle,
          left: -2,
        }}
        onClick={handleOnVolumeClick}
      />
    );
  };

  useEffect(() => {
    onVolumeChange?.(off ? 0 : volume);
  }, [volume, off, onVolumeChange]);

  return (
    <ClickAwayListener onClickAway={handleOnClickAway}>
      <Box
        style={{
          width: 40,
          height: 40,
          position: 'relative',
          ...style,
        }}
      >
        <AbsoluteCenterBox>
          {renderIcon()}
          {visible && (
            <M3SurfaceContainer
              elevation={1}
              style={{
                top: 3,
                left: -2,
                height: 35,
                width: 140,
                zIndex: 1,
                borderRadius: 40,
                position: 'absolute',
                padding: '0 18px 0 40px',
              }}
            >
              <Box position='relative'>
                <Slider
                  size='small'
                  min={0}
                  max={1}
                  step={0.01}
                  value={off ? 0 : volume}
                  onChange={handleSliderChange}
                  style={{
                    top: 3,
                    left: 0,
                    right: 0,
                    width: '100%',
                    margin: 'auto',
                    position: 'absolute',
                  }}
                />
              </Box>
            </M3SurfaceContainer>
          )}
        </AbsoluteCenterBox>
      </Box>
    </ClickAwayListener>
  );
};

export default VolumeControl;
