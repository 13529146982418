import { useEffect } from 'react';

type Props = {
  title: string;
  trailingTitle?: string;
  withAppTitle?: boolean;
};

const DocumentTitle = ({
  title,
  trailingTitle,
  withAppTitle = true,
}: Props) => {
  useEffect(() => {
    document.title = [
      title,
      trailingTitle,
      withAppTitle ? process.env.REACT_APP_TITLE : '',
    ]
      .filter((s) => !!s)
      .join(' | ');
  }, [title, trailingTitle, withAppTitle]);

  return null;
};

export default DocumentTitle;
