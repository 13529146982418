import React, { useMemo, useRef } from 'react';
import moment, { Moment } from 'moment';
import { Box } from '@mui/material';

import TimelineBlock from './TimelineBlock';
import { useTimezone } from '../../../components/Util/Timezone';

import { UserDetailFilterParams } from './_types';
import { useTimeTrackerWorklogs } from '../../../hooks/time-tracker/worklogs';
import {
  useUserActivityPercentage,
  useUserIdleActivity,
} from '../../../hooks/go2-agent/activities';
import { useOnScreen } from '../../../hooks/global/useOnScreen';

type Props = {
  userId?: number;
  filterParams: UserDetailFilterParams;
  selectedFilteredDate: Moment;
  prevNextSelectedFilteredDate: (action: 'next' | 'previous') => void;
  withNavigationButtons?: boolean;
  fetchOnVisibleOnly?: boolean;
};

const UserTimeline = ({
  userId,
  filterParams,
  selectedFilteredDate,
  prevNextSelectedFilteredDate,
  withNavigationButtons,
  fetchOnVisibleOnly = false,
}: Props) => {
  const timezone = useTimezone();
  const activityItems = useMemo(() => [], []);
  const keystrokeItems = useMemo(() => [], []);

  /**
   * Need to only fetch activity/worklogs when it's near on the visible viewport
   * to maximize performance and preventing calls not needed
   */
  const wrapperRef = useRef(null);
  const isNearVisibleViewport = useOnScreen(wrapperRef);

  // User Worklogs -------------------------------------------------------------
  const startEndDateParams = {
    start_date: selectedFilteredDate
      .clone()
      .subtract(1, 'day')
      .startOf('day')
      .utc()
      .format(),
    end_date: selectedFilteredDate
      .clone()
      .add(1, 'day')
      .endOf('day')
      .utc()
      .format(),
  };
  const { data: timeTrackerWorklogsDataSource } = useTimeTrackerWorklogs(
    {
      key: `user_worklogs_${userId}`,
      ...startEndDateParams,
      timezone,
      limit: 2000,
      users: userId?.toString(),
    },
    {
      enabled: fetchOnVisibleOnly ? isNearVisibleViewport : true,
    },
  );
  /**
   * Filtering the percentage data to only allow on memo
   */
  const timeTrackerWorklogsData = useMemo(() => {
    let items = timeTrackerWorklogsDataSource?.results ?? [];
    let ymdSelected = selectedFilteredDate.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let startLocal = moment.utc(item.start).local();
      let endLocal = moment.utc(item.end).local();
      // Check if the interval start/end local is same as selected date
      if (
        startLocal.format('YYYY-MM-DD') === ymdSelected ||
        endLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [timeTrackerWorklogsDataSource, selectedFilteredDate]);

  // User Activity Percentage --------------------------------------------------
  const { data: userActivityPercentageDataSource } = useUserActivityPercentage(
    {
      key: `user_activity_pct_${userId}`,
      ...startEndDateParams,
      user_ids: userId?.toString(),
      limit: 2000,
    },
    {
      enabled: fetchOnVisibleOnly ? isNearVisibleViewport : true,
    },
  );
  /**
   * Filtering the percentage data to only allow on memo
   */
  const userActivityPercentageData = useMemo(() => {
    let items = userActivityPercentageDataSource ?? [];
    let ymdSelected = selectedFilteredDate.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let intervalStartLocal = moment.utc(item.interval_start).local();
      let intervalEndLocal = moment.utc(item.interval_end).local();
      // Check if the interval start/end local is same as selected date
      if (
        intervalStartLocal.format('YYYY-MM-DD') === ymdSelected ||
        intervalEndLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [userActivityPercentageDataSource, selectedFilteredDate]);

  // User Idle Activity --------------------------------------------------------
  const { data: userIdleActivityDataSource } = useUserIdleActivity(
    {
      key: `user_idle_activity_${userId}`,
      ...startEndDateParams,
      user_ids: userId?.toString(),
      limit: 2000,
    },
    {
      enabled: fetchOnVisibleOnly ? isNearVisibleViewport : true,
    },
  );
  const userIdleActivityItems = useMemo(() => {
    let items = userIdleActivityDataSource ?? [];
    let ymdSelected = selectedFilteredDate.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let startLocal = moment.utc(item.start).local();
      let endLocal = moment.utc(item.end).local();
      // Check if the interval start/end local is same as selected date
      if (
        startLocal.format('YYYY-MM-DD') === ymdSelected ||
        endLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [userIdleActivityDataSource, selectedFilteredDate]);

  const worklogItemsForUser = useMemo(
    () => timeTrackerWorklogsData.filter((w) => w.user === userId),
    [userId, timeTrackerWorklogsData],
  );
  const activityPercentageItemsForUser = useMemo(
    () => userActivityPercentageData.filter((a) => a.user_id === userId),
    [userId, userActivityPercentageData],
  );

  return (
    <>
      <Box ref={wrapperRef}>
        <TimelineBlock
          isNearVisibleViewport={
            fetchOnVisibleOnly ? isNearVisibleViewport : true
          }
          withNavigationButtons={withNavigationButtons}
          idleActivityItems={userIdleActivityItems}
          activityItems={activityItems}
          keystrokeItems={keystrokeItems}
          worklogItems={worklogItemsForUser}
          activityPercentageItems={activityPercentageItemsForUser}
          filterParams={filterParams}
          selectedFilteredDate={selectedFilteredDate}
          prevNextSelectedFilteredDate={prevNextSelectedFilteredDate}
        />
      </Box>
    </>
  );
};

export default UserTimeline;
