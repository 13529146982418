import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, SxProps, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useAppProvider } from '../providers/app/app';

export type BreadcrumbItem = {
  id: string;
  path?: string;
  label?: string | null;
  navigate?: boolean | null;
  onClick?: () => void;
};
type Props = {
  items: BreadcrumbItem[];
  sx?: SxProps;
};

const AppBreadcrumbs = ({ items, sx }: Props) => {
  const { isDarkMode } = useAppProvider();

  return (
    <Breadcrumbs
      sx={{
        pt: 1,
        pb: 1,
        '.MuiBreadcrumbs-ol': {
          alignItems: 'center',
          li: {
            height: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        ...sx,
      }}
      separator={
        <NavigateNextIcon
          fontSize='medium'
          sx={{
            ml: -0.5,
            mr: -0.5,
            position: 'relative',
          }}
          style={{
            color: isDarkMode
              ? 'var(--md-ref-palette-neutral40)'
              : 'var(--md-ref-palette-neutral80)',
          }}
        />
      }
    >
      {items.map((item, index) => {
        if (index === items.length - 1) {
          return (
            <Typography
              key={item.id}
              fontSize={14}
              fontWeight={500}
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-neutral50)'
                  : 'var(--md-ref-palette-neutral70)',
              }}
            >
              {item.label}
            </Typography>
          );
        }

        let style: CSSProperties = {
          fontSize: 14,
          fontWeight: 500,
          color: isDarkMode
            ? 'var(--md-ref-palette-secondary80)'
            : 'var(--md-ref-palette-secondary40)',
          textDecoration: 'none',
          cursor: 'pointer',
        };

        if (item.path) {
          return (
            <Link
              key={item.id}
              to={item.path}
              style={style}
              onClick={item.onClick}
            >
              {item.label}
            </Link>
          );
        }

        return (
          <span key={item.id} style={style} onClick={item.onClick}>
            {item.label}
          </span>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
